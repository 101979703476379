// libraries
import React, { useState, useRef, useLayoutEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// routes
import pathnames from "routes/pathnames";
import employeePathnames from "routes/employee-pathnames";
// assets
import logoAtf from "assets/images/logo-atf.svg";
import logoAtfNoText from "assets/images/logo-atf-no-text.svg";
import iconDashboard from "assets/images/components/app-side-nav/icon-dashboard.svg";
import iconUserManagement from "assets/images/components/app-side-nav/icon-user-management.svg";
import iconCompanyBenefits from "assets/images/components/app-side-nav/icon-company-benefits.svg";
import iconLeaveManagement from "assets/images/components/app-side-nav/icon-leave-management.svg";
import iconClaimManagement from "assets/images/components/app-side-nav/icon-claim-management.svg";
import iconTimesheetManagement from "assets/images/components/app-side-nav/icon-timesheet-management.svg";
import iconPayslip from "assets/images/components/app-side-nav/icon-payslip.svg";
import iconEmployeeConfirmation from "assets/images/components/app-side-nav/icon-employee-confirmation.svg";
import iconCoachingSession from "assets/images/components/app-side-nav/icon-coaching-session.svg";
import iconLeft from "assets/images/icon-arrow-left.svg";
import iconRight from "assets/images/icon-arrow-right.svg";

export const adminNavList = [
    {
        label: "Dashboard",
        icon: iconDashboard,
        to: [pathnames.pageDashboard],
    },
    {
        label: "User Management",
        icon: iconUserManagement,
        to: [pathnames.pageUserManagement, pathnames.pageNewEmployee, pathnames.pageEditEmployee],
    },
    {
        label: "Company Benefits",
        icon: iconCompanyBenefits,
        to: [pathnames.pageCompanyBenefitsPosition, pathnames.pageCompanyBenefitsBenefit, pathnames.pageCreateBenefitPackage, pathnames.pageEditBenefitPackage],
    },
    {
        label: "Leave Management",
        icon: iconLeaveManagement,
        to: [pathnames.pageLeaveManagementCalendar, pathnames.pageLeaveDirectory, pathnames.pageLeavePendingApproval, pathnames.pageLeaveSummary, pathnames.pageLeaveHistory, pathnames.pagePendingLeave],
    },
    {
        label: "Claim Management",
        icon: iconClaimManagement,
        to: [pathnames.pageClaimDirectory, pathnames.pageClaimPendingApproval, pathnames.pageClaimSummary, pathnames.pageClaimHistory, pathnames.pagePendingClaim, pathnames.pageClaimPendingPayment],
    },
    {
        label: "Timesheet Management",
        icon: iconTimesheetManagement,
        to: [pathnames.pageTimesheetManagement],
    },
    {
        label: "Payslip",
        icon: iconPayslip,
        to: [pathnames.pagePayslip, pathnames.pageEmployeePayslip],
    },
    {
        label: "Employee Confirmation",
        icon: iconEmployeeConfirmation,
        to: [pathnames.pageEmployeeConfirmation, pathnames.pageEmployeeConfirmationSendLetter],
    },
    {
        label: "Coaching Session",
        icon: iconCoachingSession,
        to: [pathnames.pageCoachingUpcomingSession, pathnames.pageCoachingHistory],
    },
];

export const employeeNavList = [
    {
        label: "Dashboard",
        icon: iconDashboard,
        to: [employeePathnames.pageEmployeeDashboard, employeePathnames.pageEmployeeDashboardProfileAndSettings],
    },
    // {
    //     label: "User Management",
    //     icon: iconUserManagement,
    //     to: [employeePathnames.pageEmployeeUserManagement, employeePathnames.pageEmployeeDetails],
    // },
    {
        label: "Leave Management",
        icon: iconLeaveManagement,
        to: [employeePathnames.pageEmployeeLeaveCalendar, employeePathnames.pageEmployeeLeaveSummary, employeePathnames.pageEmployeeLeaveHistory, employeePathnames.pageEmployeePendingLeave],
    },
    {
        label: "Claim Management",
        icon: iconClaimManagement,
        to: [employeePathnames.pageEmployeeClaimSummary, employeePathnames.pageEmployeeClaimHistory, employeePathnames.pageEmployeePendingClaim],
    },
    {
        label: "Payslip",
        icon: iconPayslip,
        to: [employeePathnames.pageEmployeePayslip],
    },
    {
        label: "Coaching Session",
        icon: iconCoachingSession,
        to: [employeePathnames.pageEmployeeUpcomingSchedule, employeePathnames.pageEmployeeCoachingHistory],
    },
];

const getAppSlideNavClassName = (active, open) => {
    const classNames = ["app-side-nav__item"];
    if (open) {
        if (active) {
            classNames.push("app-side-nav__item--expand--active");
        }
        classNames.push("app-side-nav__item--expand--hover");
    } else {
        if (active) {
            classNames.push("app-side-nav__item--shrink--active");
        }
        classNames.push("app-side-nav__item--shrink--hover");
    }
    return classNames.join(" ");
};

const AppSideNav = () => {
    const [open, setOpen] = useState(true);
    const [navList, setNavList] = useState([]);
    const location = useLocation();
    const sideNavRef = useRef();
    const sideNavItem = useRef();

    useLayoutEffect(() => {
        const listSelected = location.pathname.split("/")[1];
        if (listSelected === "admin") {
            setNavList(adminNavList);
        } else if (listSelected === "employee") {
            setNavList(employeeNavList);
        }
    }, [location]);

    const onHandleToggle = () => {
        setOpen(!open);
        if (!open) {
            sideNavRef.current.classList.add("app-side-nav--expand");
            sideNavRef.current.classList.remove("add-side-nav--shrink");
            sideNavItem.current.classList.add("app-side-nav--show");
            sideNavItem.current.classList.remove("app-side-nav--hide");
        } else {
            sideNavRef.current.classList.add("app-side-nav--shrink");
            sideNavRef.current.classList.remove("app-side-nav--expand");
            sideNavItem.current.classList.add("app-side-nav--hide");
            sideNavItem.current.classList.remove("app-side-nav--show");
        }
    };

    return (
        <div className="app-side-nav" ref={sideNavRef}>
            <div>
                <div className="app-side-nav__logo-wrapper">
                    <img src={open ? logoAtf : logoAtfNoText} alt="" className="app-side-nav__logo-open" />
                </div>
                <ul className="app-side-nav__list" ref={sideNavItem}>
                    {navList.map((o, i) => {
                        const key = `${i}-item`;
                        let pathWithoutValue = o.to.map((e) => {
                            return e.split(":")[0];
                        });
                        const active = pathWithoutValue.includes(location.pathname.split("value_")[0]);
                        return (
                            <Link to={o.to[0]} key={key} className="app-side-nav__link">
                                <li className={getAppSlideNavClassName(active, open)}>
                                    <img src={o.icon} alt="" className="app-side-nav__item-icon" />
                                    <p className="app-side-nav__item-label">{o.label}</p>
                                </li>
                            </Link>
                        );
                    })}
                </ul>
            </div>
            <div className="app-side-nav__action" onClick={onHandleToggle}>
                {!open ? <img src={iconRight} alt="" className="app-side-nav__action-icon" /> : <img src={iconLeft} alt="" className="app-side-nav__action-icon" />}
            </div>
        </div>
    );
};

export default AppSideNav;
