import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
// assets
import iconUp from "assets/images/icon-arrow-up-gray.svg";
import iconDown from "assets/images/icon-arrow-down-gray.svg";

const getInputWrapperClassNames = (timeIsOpenPlaceholder, error, disabled) => {
    const classNames = ["app-time-picker__input-wrapper"];
    if (timeIsOpenPlaceholder) classNames.push("app-time-picker__input-wrapper--focused");
    if (error) classNames.push("app-time-picker__input-wrapper--error");
    if (disabled) classNames.push("app-time-picker__input-wrapper--disabled");
    return classNames.join(" ");
};

const getInputClassNames = (disabled) => {
    const classNames = ["app-time-picker__input"];
    if (disabled) classNames.push("app-time-picker__input--disabled");
    return classNames.join(" ");
};

const getAmPmClassNames = (type, ampm) => {
    const classNames = ["app-time-picker__ampm"];
    if (ampm && type === ampm.toLowerCase()) classNames.push("app-time-picker__ampm--active");
    return classNames.join(" ");
};

const AppTimePicker2 = forwardRef(({ placeholder, onFocus, timeIsOpenPlaceholder, valueFunc, initialValues, fallbackValues, error, onBlur, disabled }, ref) => {
    const [inputTimeValue, setInputTimeValue] = useState(initialValues ? initialValues : "");

    useEffect(() => {
        if (valueFunc) {
            valueFunc(inputTimeValue);
        }
    }, [inputTimeValue, valueFunc]);

    const timeArrayValue = inputTimeValue.split(".").join(" ").split(" ");

    useImperativeHandle(ref, () => ({
        resetValue() {
            setInputTimeValue("");
        },
    }));

    return (
        <div className="app-time-picker">
            <div className={getInputWrapperClassNames(timeIsOpenPlaceholder === placeholder, error, disabled)}>
                <input
                    className={getInputClassNames(disabled)}
                    value={inputTimeValue}
                    onFocus={(e) => (!disabled ? onFocus(e, placeholder) : null)}
                    placeholder={placeholder}
                    onChange={(e) => setInputTimeValue(e.target.value)}
                    onBlur={onBlur}
                    onKeyDown={(e) => {
                        if (e.key.toLowerCase() === "backspace") {
                            setInputTimeValue("");
                        } else {
                            e.preventDefault();
                        }
                    }}
                />
                {inputTimeValue && timeIsOpenPlaceholder !== placeholder ? <div className="app-time-picker__placeholder-label">{placeholder}</div> : null}
            </div>
            {error ? <span className="app-time-picker__error-message">{error}</span> : null}
            {timeIsOpenPlaceholder === placeholder ? (
                <div className="app-time-picker__wrapper" onClick={(e) => onFocus(e, placeholder)}>
                    <div className="app-time-picker__wrapper-column">
                        <button
                            className="app-time-picker__arrow-button"
                            onClick={() => {
                                if (parseInt(inputTimeValue.split(".")[0]) < 12) {
                                    let updateValue = ("00" + JSON.stringify(parseInt(inputTimeValue.split(".")[0]) + 1)).slice(-2) + "." + inputTimeValue.split(".")[1];
                                    setInputTimeValue(updateValue);
                                } else if (parseInt(inputTimeValue.split(".")[0]) === 12) {
                                    let updateValue = "01." + inputTimeValue.split(".")[1];
                                    setInputTimeValue(updateValue);
                                } else {
                                    setInputTimeValue(fallbackValues);
                                }
                            }}>
                            <img alt="" src={iconUp} />
                        </button>
                        <input
                            className="app-time-picker__value-input"
                            onChange={(e) => {
                                if (timeArrayValue.length === 3) {
                                    if (parseInt(e.target.value) <= 12) {
                                        let updateValue = ("00" + e.target.value).slice(-2) + "." + inputTimeValue.split(".")[1];
                                        setInputTimeValue(updateValue);
                                    }
                                } else {
                                    if (parseInt(e.target.value) <= 12) {
                                        let fallbackValueArray = fallbackValues.split(".").join(" ").split(" ");
                                        let updateValue = ("00" + e.target.value).slice(-2) + "." + fallbackValueArray[1] + " " + fallbackValueArray[2];
                                        setInputTimeValue(updateValue);
                                    }
                                }
                            }}
                            value={inputTimeValue.split(".")[0] === "" ? "-" : inputTimeValue.split(".")[0]}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        />
                        <button
                            className="app-time-picker__arrow-button"
                            onClick={() => {
                                if (parseInt(inputTimeValue.split(".")[0]) > 1) {
                                    let updateValue = ("00" + JSON.stringify(parseInt(inputTimeValue.split(".")[0]) - 1)).slice(-2) + "." + inputTimeValue.split(".")[1];
                                    setInputTimeValue(updateValue);
                                } else if (parseInt(inputTimeValue.split(".")[0]) === 1) {
                                    let updateValue = "12." + inputTimeValue.split(".")[1];
                                    setInputTimeValue(updateValue);
                                } else {
                                    setInputTimeValue(fallbackValues);
                                }
                            }}>
                            <img alt="" src={iconDown} />
                        </button>
                    </div>
                    <div className="app-time-picker__wrapper-column">
                        <div className="app-time-picker__semicolon">:</div>
                    </div>
                    <div className="app-time-picker__wrapper-column">
                        <button
                            className="app-time-picker__arrow-button"
                            onClick={() => {
                                if (parseInt(timeArrayValue[1]) < 59) {
                                    let updateValue = timeArrayValue[0] + "." + ("00" + JSON.stringify(parseInt(timeArrayValue[1]) + 1)).slice(-2) + " " + timeArrayValue[2];
                                    setInputTimeValue(updateValue);
                                } else if (parseInt(timeArrayValue[1]) === 59) {
                                    let updateValue = timeArrayValue[0] + ".00 " + timeArrayValue[2];
                                    setInputTimeValue(updateValue);
                                } else {
                                    setInputTimeValue(fallbackValues);
                                }
                            }}>
                            <img alt="" src={iconUp} />
                        </button>
                        <input
                            className="app-time-picker__value-input"
                            onChange={(e) => {
                                if (timeArrayValue.length === 3) {
                                    if (parseInt(e.target.value) <= 59) {
                                        let updateValue = timeArrayValue[0] + "." + ("00" + e.target.value).slice(-2) + " " + timeArrayValue[2];
                                        setInputTimeValue(updateValue);
                                    }
                                } else {
                                    if (parseInt(e.target.value) <= 12) {
                                        let fallbackValueArray = fallbackValues.split(".").join(" ").split(" ");
                                        let updateValue = fallbackValueArray[0] + "." + ("00" + e.target.value).slice(-2) + " " + fallbackValueArray[2];
                                        setInputTimeValue(updateValue);
                                    }
                                }
                            }}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            value={timeArrayValue[1] ? timeArrayValue[1] : "-"}
                        />
                        <button
                            className="app-time-picker__arrow-button"
                            onClick={() => {
                                if (parseInt(timeArrayValue[1]) > 0) {
                                    let updateValue = timeArrayValue[0] + "." + ("00" + JSON.stringify(parseInt(timeArrayValue[1]) - 1)).slice(-2) + " " + timeArrayValue[2];
                                    setInputTimeValue(updateValue);
                                } else if (parseInt(timeArrayValue[1]) === 0) {
                                    let updateValue = timeArrayValue[0] + ".59 " + timeArrayValue[2];
                                    setInputTimeValue(updateValue);
                                } else {
                                    setInputTimeValue(fallbackValues);
                                }
                            }}>
                            <img alt="" src={iconDown} />
                        </button>
                    </div>
                    <div className="app-time-picker__wrapper-column" />
                    <div className="app-time-picker__wrapper-column">
                        <button
                            className={getAmPmClassNames("am", timeArrayValue[2])}
                            onClick={() => {
                                if (timeArrayValue[2]) {
                                    let updateValue = timeArrayValue[0] + "." + timeArrayValue[1] + " AM";
                                    setInputTimeValue(updateValue);
                                } else {
                                    let fallbackValueArray = fallbackValues.split(".").join(" ").split(" ");
                                    let updateValue = fallbackValueArray[0] + "." + fallbackValueArray[1] + " AM";
                                    setInputTimeValue(updateValue);
                                }
                            }}>
                            <span>AM</span>
                        </button>
                        <div className="app-time-picker__ampm-divider" />
                        <button
                            className={getAmPmClassNames("pm", timeArrayValue[2])}
                            onClick={() => {
                                if (timeArrayValue[2]) {
                                    let updateValue = timeArrayValue[0] + "." + timeArrayValue[1] + " PM";
                                    setInputTimeValue(updateValue);
                                } else {
                                    let fallbackValueArray = fallbackValues.split(".").join(" ").split(" ");
                                    let updateValue = fallbackValueArray[0] + "." + fallbackValueArray[1] + " PM";
                                    setInputTimeValue(updateValue);
                                }
                            }}>
                            <span>PM</span>
                        </button>
                    </div>
                </div>
            ) : null}
        </div>
    );
});

export default AppTimePicker2;
