import React from "react";

const getCheckMarkClassNames = ({ type, size, error }) => {
    const classNames = [];
    if (type === "selectAll") {
        classNames.push("app-checkbox__select-all");
    } else {
        classNames.push("app-checkbox__checkmark");
        if (size === "s") classNames.push("app-checkbox__checkmark--small");
    }
    if (error) classNames.push("app-checkbox__checkmark--error") 
    return classNames.join(" ");
};

const AppCheckbox = ({ error, checked, onChange, type, size }) => {
    return (
        <div className="app-checkbox">
            <label className="app-checkbox__label">
                <input className="app-checkbox__input" type="checkbox" onChange={onChange} checked={checked} hidden />
                <span className={getCheckMarkClassNames({ type, size, error })}></span>
            </label>
        </div>
    );
};

export default AppCheckbox;
