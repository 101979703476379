import React from "react";

const AppAuthLayout = ({ children }) => {
    return (
        <div className="app-auth-layout">
            <div className="app-auth-layout__image">
                <div className="app-auth-layout__content">
                    <div className="app-auth-layout__item">{children}</div>
                </div>
            </div>
        </div>
    );
};

export default AppAuthLayout;
