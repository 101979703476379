// libraries
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { BiFilterAlt } from "react-icons/bi";
import moment from "moment";
import _ from "lodash";
// components
import AppInput from "components/app-input";
import appToast from "components/app-toast";
import AppButton from "components/app-button";
import AppTabLink from "components/app-tab-link";
import AppCheckbox from "components/app-checkbox";
import AppDropdown from "components/app-dropdown";
import AppPaginate from "components/app-paginate";
import AppTextArea from "components/app-text-area";
import AppInputDate from "components/app-input-date";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTimePicker from "components/app-time-picker";
import AppReactTable from "components/app-react-table";
import AppPagesLayout from "components/app-pages-layout";
import AppTimePicker2 from "components/app-time-picker-2";
import AppInputWithLabel from "components/app-input-with-label";
import AppDropdownCheckbox from "components/app-dropdown-checkbox";
import AppDualColumnsModal from "components/app-dual-columns-modal";
import AppModalConfirmation from "components/app-modal-confirmation";
// pages
import PageCreateCoachingSession from "./page-create-coaching-session";
// service
import api from "services/api";
// common
import { sanitizeError, statusColor } from "common/utilities";
// hooks
import usePrevious from "hooks/use-previous";
// route
import pathnames from "routes/pathnames";
// hooks
import useIsMount from "hooks/use-is-mount";
// assets
import iconEdit from "assets/images/icon-edit.svg";
import iconSetting from "assets/images/icon-setting.svg";
import iconTailedArrowUp from "assets/images/icon-tailed-arrow-up.svg";
import iconSearch from "assets/images/components/app-input/icon-search.svg";

const breadcrumb = [
    {
        label: "Upcoming Schedule",
    },
    {
        label: "Coaching Sessions",
    },
];

const appTabLink = [
    {
        label: "Upcoming Schedule",
        pathname: pathnames.pageCoachingUpcomingSession,
    },
    {
        label: "Coaching History",
        pathname: pathnames.pageCoachingHistory,
    },
];

const statusOptions = [
    {
        label: "Overdue",
        value: "overdue",
    },
    {
        label: "Upcoming",
        value: "upcoming",
    },
];

const advanceSearch = [
    {
        name: "mentorUsername",
        placeholder: "Mentor",
        type: "input",
    },
    {
        name: "title",
        placeholder: "Title",
        type: "input",
    },
    {
        name: "startDate",
        placeholder: "Date",
        type: "date",
    },
    {
        name: "startTime",
        placeholder: "Start time",
        type: "time",
    },
    {
        name: "endTime",
        placeholder: "End time",
        type: "time",
    },
    {
        name: "status",
        placeholder: "Status",
        type: "dropdown",
        dropdownOptions: statusOptions,
    },
];

const tableStatusFilter = [
    { label: "Overdue", name: "overdue", value: "overdue" },
    { label: "Upcoming", name: "upcoming", value: "upcoming" },
];

let statusToFilter = [];

const getTableStatusClassNames = (status) => {
    const classNames = ["react-table__status-data"];
    if (status.toLowerCase() === "upcoming") classNames.push("react-table__status--orange");
    if (status.toLowerCase() === "overdue") classNames.push("react-table__status--red");

    return classNames.join(" ");
};

const initialValues = {
    mentorName: {
        placeholder: "Mentor Username",
        value: "",
        error: "",
    },
    mentorId: {
        placeholder: "Mentor ID",
        value: "",
        error: "",
    },
    remark: {
        placeholder: "Remark",
        value: "",
        error: "",
        field: "optional",
    },
    scheduleDate: {
        placeholder: "Schedule date",
        value: "",
        error: "",
    },
    startTime: {
        placeholder: "Start time",
        value: "",
        error: "",
    },
    endTime: {
        placeholder: "End time",
        value: "",
        error: "",
    },
    id: {
        placeholder: "",
        value: "",
        error: "",
    },
    status: {
        placeholder: "",
        value: "",
        error: "",
    },
    staffId: {
        value: "",
    },
    title: {
        placeholder: "Title",
        value: "",
        error: "",
    },
};

const PageCoachingUpcomingSession = () => {
    const location = useLocation();
    let coachingId = location.state?.coachingId;

    const [tableSearchFilter, setTableSearchFilter] = useState({
        pageNo: 0,
        pageSize: 5,
        searchValue: "",
        sortBy: "",
        sortDir: "asc",
        status: "",
    });
    const [detailSearch, setDetailSearch] = useState({
        mentorUsername: "",
        title: "",
        startDate: "",
        startTime: "",
        endTime: "",
        pageNo: 0,
        pageSize: 5,
        sortBy: "",
        status: "",
    });
    const [advanceSearchValue, setAdvanceSearchValue] = useState({
        mentorUsername: "",
        title: "",
        startDate: "",
        startTime: "",
        endTime: "",
        status: "",
    });
    const [tableData, setTableData] = useState({
        content: [],
        last: null,
        pageNo: 0,
        pageSize: 5,
        totalElements: null,
        totalPages: 1,
    });
    const prevValueSortHeader = usePrevious({
        sortBy: tableSearchFilter.sortBy,
        sortDir: tableSearchFilter.sortDir,
    });
    const prevAdvanceValueSortHeader = usePrevious({
        sortBy: detailSearch.sortBy,
        sortDir: detailSearch.sortDir,
    });
    const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
    const searchKeywordInputRef = useRef(null);
    const startTimeKeywordInputRef = useRef(null);
    const endTimeKeywordInputRef = useRef(null);
    const [statusFilterOpen, setStatusFilterOpen] = useState(false);
    const [statusFilter, setStatusFilter] = useState({
        upcoming: false,
        overdue: false,
    });
    const [editLargeModalIsOpen, setEditLargeModalIsOpen] = useState(false);
    const [timeIsOpen, setTimeIsOpen] = useState("");
    const [createSessionIsOpen, setCreateSessionIsOpen] = useState(false);
    const [confirmModal, setConfirmModal] = useState({
        isOpen: false,
        confirmDisable: false,
    });
    const [mentorNameList, setMentorNameList] = useState([]);
    const [selectedSchedule, setSelectedSchedule] = useState(initialValues);
    const [selectedScheduleExtraInfo, setSelectedScheduleExtraInfo] = useState({
        status: "",
        staffName: "",
    });
    const isMount = useIsMount();

    const getTableData = async (payload) => {
        try {
            const response = await api.get.coachingSessionUpcomingSchedule(payload);
            setTableData(response.data.result);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    useEffect(() => {
        getTableData(tableSearchFilter);
    }, [tableSearchFilter]);

    const getTableAdvanceSearchData = async (payload) => {
        try {
            const response = await api.get.coachingSessionUpcomingScheduleAdvanceSearch(payload);
            setTableData(response.data.result);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    useEffect(() => {
        if (!isMount) {
            getTableAdvanceSearchData(detailSearch);
            if (coachingId) onHandleRowSelect({ id: Number(coachingId) });
        }
    }, [detailSearch, isMount, coachingId]);

    useEffect(() => {
        const getStaffNamePosition = async () => {
            try {
                const response = await api.get.getStaffNamePosition();
                const mentorNameListUpdate = response.data.result.staffNameList.map((o) => {
                    return {
                        label: o.userName,
                        value: o.value2,
                    };
                });
                setMentorNameList(mentorNameListUpdate);
            } catch (error) {
                const sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        };
        getStaffNamePosition();
    }, []);

    const onHandleHeaderArrow = (active) => {
        const classNames = ["react-table__arrow-icon"];
        if (active && tableSearchFilter.sortDir === "desc") classNames.push("react-table__arrow-icon-down");
        if (active && detailSearch.sortDir === "desc") classNames.push("react-table__arrow-icon-down");
        if (active) classNames.push("react-table__arrow-icon--active");

        return classNames.join(" ");
    };

    const onHandleHeaderFilter = (header) => {
        let column = header.column;
        if (showAdvanceSearch) {
            if (prevAdvanceValueSortHeader && prevAdvanceValueSortHeader.sortBy === column.searchFilterValue) {
                if (prevAdvanceValueSortHeader.sortDir === "asc") {
                    setDetailSearch({
                        ...detailSearch,
                        sortBy: column.searchFilterValue,
                        sortDir: "desc",
                        pageNo: 0,
                    });
                } else {
                    setDetailSearch({
                        ...detailSearch,
                        sortBy: column.searchFilterValue,
                        sortDir: "asc",
                        pageNo: 0,
                    });
                }
            } else {
                setDetailSearch({
                    ...detailSearch,
                    sortBy: column.searchFilterValue,
                    sortDir: "asc",
                    pageNo: 0,
                });
            }
        } else {
            if (prevValueSortHeader && prevValueSortHeader.sortBy === column.searchFilterValue) {
                if (prevValueSortHeader.sortDir === "asc") {
                    setTableSearchFilter({
                        ...tableSearchFilter,
                        sortBy: column.searchFilterValue,
                        sortDir: "desc",
                        pageNo: 0,
                    });
                } else {
                    setTableSearchFilter({
                        ...tableSearchFilter,
                        sortBy: column.searchFilterValue,
                        sortDir: "asc",
                        pageNo: 0,
                    });
                }
            } else {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "asc",
                    pageNo: 0,
                });
            }
        }
    };

    const onHandleStatusFilter = (item, value) => {
        setStatusFilter({
            ...statusFilter,
            [item.name]: value,
        });
        if (value) {
            statusToFilter.push(item.value);
        } else {
            statusToFilter = statusToFilter.filter((e) => e !== item.value);
        }
        if (showAdvanceSearch) {
            if (statusToFilter.length === 1) {
                setDetailSearch({
                    ...detailSearch,
                    status: statusToFilter[0],
                    pageNo: 0,
                });
            } else {
                setDetailSearch({
                    ...detailSearch,
                    status: "",
                    pageNo: 0,
                });
            }
        } else {
            if (statusToFilter.length === 1) {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    status: statusToFilter[0],
                    pageNo: 0,
                });
            } else {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    status: "",
                    pageNo: 0,
                });
            }
        }
    };

    const tableColumn = [
        {
            id: "staffUserName",
            Header: (header) => {
                return (
                    <span onClick={() => onHandleHeaderFilter(header)} className="react-table__attached-benefit-header react-table__header">
                        Employee Username
                        <img
                            className={showAdvanceSearch ? onHandleHeaderArrow(detailSearch.sortBy === header.column.searchFilterValue) : onHandleHeaderArrow(tableSearchFilter.sortBy === header.column.searchFilterValue)}
                            src={iconTailedArrowUp}
                            alt=""
                        />
                    </span>
                );
            },
            Cell: (row) => {
                return <span className="react-table__attached-benefit-data">{row.row.original.staffUserName}</span>;
            },
            searchFilterValue: "staff_username",
        },
        {
            id: "coachUserName",
            Header: (header) => {
                return (
                    <span onClick={() => onHandleHeaderFilter(header)} className="react-table__header">
                        Mentor Username
                        <img
                            className={showAdvanceSearch ? onHandleHeaderArrow(detailSearch.sortBy === header.column.searchFilterValue) : onHandleHeaderArrow(tableSearchFilter.sortBy === header.column.searchFilterValue)}
                            src={iconTailedArrowUp}
                            alt=""
                        />
                    </span>
                );
            },
            accessor: "coachUserName",
            searchFilterValue: "coach_username",
        },
        {
            id: "title",
            Header: (header) => {
                return (
                    <span onClick={() => onHandleHeaderFilter(header)} className="react-table__header">
                        Title
                        <img
                            className={showAdvanceSearch ? onHandleHeaderArrow(detailSearch.sortBy === header.column.searchFilterValue) : onHandleHeaderArrow(tableSearchFilter.sortBy === header.column.searchFilterValue)}
                            src={iconTailedArrowUp}
                            alt=""
                        />
                    </span>
                );
            },
            accessor: "title",
            searchFilterValue: "title",
        },
        {
            id: "coachingDate",
            Header: (header) => {
                return (
                    <span onClick={() => onHandleHeaderFilter(header)} className="react-table__header">
                        Date
                        <img
                            className={showAdvanceSearch ? onHandleHeaderArrow(detailSearch.sortBy === header.column.searchFilterValue) : onHandleHeaderArrow(tableSearchFilter.sortBy === header.column.searchFilterValue)}
                            src={iconTailedArrowUp}
                            alt=""
                        />
                    </span>
                );
            },
            accessor: "coachingDate",
            searchFilterValue: "coaching_date",
        },
        {
            id: "time",
            Header: (header) => {
                return (
                    <span onClick={() => onHandleHeaderFilter(header)} className="react-table__header">
                        Time
                        <img
                            className={showAdvanceSearch ? onHandleHeaderArrow(detailSearch.sortBy === header.column.searchFilterValue) : onHandleHeaderArrow(tableSearchFilter.sortBy === header.column.searchFilterValue)}
                            src={iconTailedArrowUp}
                            alt=""
                        />
                    </span>
                );
            },
            accessor: "time",
            searchFilterValue: "start_time",
        },
        {
            id: "status",
            Header: () => {
                const getStatusClassNames = (statusFilterOpen) => {
                    const classNames = ["react-table__icon-filter"];
                    if (statusFilterOpen) classNames.push("react-table__icon-filter--active");
                    return classNames.join(" ");
                };
                return (
                    <div onClick={(e) => e.stopPropagation()}>
                        <span className="react-table__status-header" onClick={() => setStatusFilterOpen(!statusFilterOpen)}>
                            Status
                            <BiFilterAlt className={getStatusClassNames(statusFilterOpen)} />
                        </span>
                        {statusFilterOpen ? (
                            <div className="react-table__dropdown">
                                <AppDropdownCheckbox dropdownList={tableStatusFilter} onChange={(item, value) => onHandleStatusFilter(item, value)} state={statusFilter} />
                            </div>
                        ) : null}
                    </div>
                );
            },
            Cell: (row) => {
                return (
                    <div className={getTableStatusClassNames(row.row.original.status)}>
                        <span>{row.row.original.status}</span>
                    </div>
                );
            },
            searchFilterValue: "status",
        },
        {
            id: "button",
            Cell: () => {
                return <img className="react-table__edit-icon" src={iconEdit} alt="" />;
            },
            maxWidth: 50,
        },
    ];

    const onHandleSearchKeywordTyped = (value) => {
        setTableSearchFilter({
            pageNo: 0,
            pageSize: 5,
            searchValue: value,
            sortBy: "",
            sortDir: "asc",
            status: "",
        });
    };

    const searchBarOnChange = (e) => {
        onHandleSearchKeywordTyped(e.target.value);
    };

    const handleKeypress = (e) => {
        if (e.code === "Enter") {
            onHandleSearchKeywordTyped(e.target.value);
        }
    };

    const onHandlePageChange = (e) => {
        if (showAdvanceSearch) {
            setDetailSearch({
                ...detailSearch,
                pageNo: e.selected,
            });
        } else {
            setTableSearchFilter({
                ...tableSearchFilter,
                pageNo: e.selected,
            });
        }
    };

    const applySearchKeywordSearch = async () => {
        let payload = {
            searchValue: "",
            ...tableSearchFilter,
        };
        getTableData(payload);
    };

    const onHandleApplyAdvanceSearch = async () => {
        setDetailSearch({
            mentorUsername: advanceSearchValue.mentorUsername,
            title: advanceSearchValue.title,
            startDate: advanceSearchValue.startDate,
            startTime: advanceSearchValue.startTime,
            endTime: advanceSearchValue.endTime,
            pageNo: 0,
            pageSize: 5,
            sortBy: "",
            status: advanceSearchValue.status,
        });
    };

    const onHandleRowSelect = async (row) => {
        try {
            const response = await api.get.coachingSessionSessionDetail(row.id);
            let result = response.data.result;
            result = { ...result, id: row.id };
            let initialValuesCloned = { ...initialValues };
            for (const [k, v] of Object.entries(result)) {
                if (Object.keys(initialValuesCloned).includes(k)) {
                    initialValuesCloned = {
                        ...initialValuesCloned,
                        [k]: {
                            ...initialValuesCloned[k],
                            value: v,
                        },
                    };
                }
            }
            setSelectedSchedule(initialValuesCloned);
            setSelectedScheduleExtraInfo({
                status: result.status,
                staffName: result.staffName,
            });
            setEditLargeModalIsOpen(true);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    const upComingSessionEditSave = () => {
        let selectedScheduleCloned = { ...selectedSchedule };
        for (const key in selectedScheduleCloned) {
            if (!selectedScheduleCloned[key].value && selectedScheduleCloned[key].field !== "optional") {
                selectedScheduleCloned = {
                    ...selectedScheduleCloned,
                    [key]: {
                        ...selectedScheduleCloned[key],
                        error: `${selectedScheduleCloned[key].placeholder} is required.`,
                    },
                };
            } else if (selectedScheduleCloned[key].value === "Invalid date") {
                selectedScheduleCloned = {
                    ...selectedScheduleCloned,
                    [key]: {
                        ...selectedScheduleCloned[key],
                        error: `${selectedScheduleCloned[key].placeholder} is invalid.`,
                    },
                };
            } else {
                selectedScheduleCloned = {
                    ...selectedScheduleCloned,
                    [key]: {
                        ...selectedScheduleCloned[key],
                        error: "",
                    },
                };
            }
        }
        let endTime = moment(selectedScheduleCloned.endTime.value, "hh:mm A");
        let startTime = moment(selectedScheduleCloned.startTime.value, "hh:mm A");
        if (endTime.isBefore(startTime) || endTime._i === startTime._i) {
            selectedScheduleCloned = {
                ...selectedScheduleCloned,
                endTime: {
                    ...selectedScheduleCloned.endTime,
                    error: "End time is required to be after start time.",
                },
                startTime: {
                    ...selectedScheduleCloned.startTime,
                    error: "Start time is required to be before end time.",
                },
            };
        }
        setSelectedSchedule(selectedScheduleCloned);

        if (Object.values(selectedScheduleCloned).every((e) => e.error === "")) {
            setEditLargeModalIsOpen(false);
            setConfirmModal({
                isOpen: true,
                confirmDisable: false,
            });
        }
    };

    const editSaveConfirmed = async () => {
        let selectedScheduleCloned = { ...selectedSchedule };
        let payload = {};
        for (const [k, v] of Object.entries(selectedScheduleCloned)) {
            payload = {
                ...payload,
                [k]: v.value,
            };
        }
        setConfirmModal({
            ...confirmModal,
            confirmDisable: true,
        });
        try {
            await api.post.coachingSessionEditSession(payload);
            setConfirmModal({
                isOpen: false,
                confirmDisable: false,
            });
            appToast("Coaching session has been updated successfully", true);
            getTableData(tableSearchFilter);
        } catch (error) {
            appToast(sanitizeError(error), false);
            setConfirmModal({
                ...confirmModal,
                confirmDisable: false,
            });
        }
    };

    const onHandleStatusCheckbox = () => {
        if (selectedSchedule.status.value && selectedSchedule.status.value.toLowerCase() === "completed") {
            setSelectedSchedule({
                ...selectedSchedule,
                status: {
                    ...selectedSchedule.status,
                    value: "",
                },
            });
        } else {
            setSelectedSchedule({
                ...selectedSchedule,
                status: {
                    ...selectedSchedule.status,
                    value: "Completed",
                },
            });
        }
    };

    const onHandleMentorName = (selected) => {
        setSelectedSchedule({
            ...selectedSchedule,
            mentorName: {
                ...selectedSchedule.mentorName,
                value: selected.label,
                error: "",
            },
            mentorId: {
                ...selectedSchedule.mentorId,
                value: selected.value,
                error: "",
            },
        });
    };

    const onHandleRemark = (e) => {
        setSelectedSchedule({
            ...selectedSchedule,
            remark: {
                ...selectedSchedule.remark,
                value: e.target.value,
                error: "",
            },
        });
    };

    const onHandleCancelSchedule = () => {
        if (selectedScheduleExtraInfo.status && selectedScheduleExtraInfo.status.toLowerCase() === "cancelled") {
            setSelectedSchedule({
                ...selectedSchedule,
                status: {
                    ...selectedSchedule.status,
                    value: selectedScheduleExtraInfo.status,
                },
            });
        } else {
            setSelectedSchedule({
                ...selectedSchedule,
                status: {
                    ...selectedSchedule.status,
                    value: "Cancelled",
                },
            });
        }
    };

    const onHandleScheduleDate = (value) => {
        setSelectedSchedule({
            ...selectedSchedule,
            scheduleDate: {
                ...selectedSchedule.scheduleDate,
                value: value,
                error: "",
            },
        });
    };

    const onHandleTitle = (v) => {
        setSelectedSchedule({
            ...selectedSchedule,
            title: {
                ...selectedSchedule.title,
                value: v.target.value,
                error: "",
            },
        });
    };

    const onHandleStartTimeValueFunc = (value) => {
        let timeFormatted = value.hours && value.minutes && value.ampm ? `${value.hours}:${value.minutes} ${value.ampm}` : "";
        if (selectedSchedule.startTime.value !== timeFormatted) {
            setSelectedSchedule({
                ...selectedSchedule,
                startTime: {
                    ...selectedSchedule.startTime,
                    value: timeFormatted,
                    error: "",
                },
                endTime: {
                    ...selectedSchedule.endTime,
                    error: "",
                },
            });
        }
    };

    const onHandleEndTimeValueFunc = (value) => {
        let timeFormatted = `${value.hours}:${value.minutes} ${value.ampm}`;
        if (selectedSchedule.endTime.value !== timeFormatted) {
            setSelectedSchedule({
                ...selectedSchedule,
                endTime: {
                    ...selectedSchedule.endTime,
                    value: timeFormatted,
                    error: "",
                },
                startTime: {
                    ...selectedSchedule.startTime,
                    error: "",
                },
            });
        }
    };

    const onHandleResetSearch = () => {
        setDetailSearch({
            mentorUsername: "",
            title: "",
            startDate: "",
            startTime: "",
            endTime: "",
            pageNo: 0,
            pageSize: 5,
            sortBy: "",
            status: "",
        });
        setAdvanceSearchValue({
            mentorUsername: "",
            title: "",
            startDate: "",
            startTime: "",
            endTime: "",
            status: "",
        });
        startTimeKeywordInputRef.current.resetValue();
        endTimeKeywordInputRef.current.resetValue();
    };

    const onHandleSearchKeyword = () => {
        if (showAdvanceSearch) {
            setShowAdvanceSearch(false);
            searchKeywordInputRef.current.value = "";
            onHandleSearchKeywordTyped("");
            applySearchKeywordSearch();
            setStatusFilter({
                upcoming: false,
                overdue: false,
            });
            statusToFilter = [];
        }
    };

    const onHandleResetFilter = () => {
        setStatusFilter({
            upcoming: false,
            overdue: false,
        });
        statusToFilter = [];
        setShowAdvanceSearch(!showAdvanceSearch);
        searchKeywordInputRef.current.value = "";
        onHandleSearchKeywordTyped("");
    };

    return (
        <AppPagesLayout>
            <div
                className="page-coaching-upcoming"
                onClick={() => {
                    setStatusFilterOpen(false);
                    setTimeIsOpen("");
                }}>
                <AppModalConfirmation
                    isOpenModal={confirmModal.isOpen}
                    onRequestClose={() => {
                        setConfirmModal({
                            isOpen: false,
                            confirmDisable: false,
                        });
                        setEditLargeModalIsOpen(true);
                    }}
                    details="Confirm to save"
                    onClick={editSaveConfirmed}
                    buttonDisabled={confirmModal.confirmDisable}
                    buttonLabel="Confirm"
                />

                <PageCreateCoachingSession largeModalIsOpen={createSessionIsOpen} onRequestClose={() => setCreateSessionIsOpen(false)} onRequestOpen={() => setCreateSessionIsOpen(true)} postSuccess={() => getTableData(tableSearchFilter)} />

                <AppDualColumnsModal
                    title="Schedule a Session"
                    isOpenModal={editLargeModalIsOpen}
                    onRequestClose={() => setEditLargeModalIsOpen(false)}
                    status={selectedSchedule.status.value}
                    statusColor={statusColor(selectedSchedule.status.value)}
                    onClick={() => {
                        setTimeIsOpen("");
                    }}
                    childrenLeft={
                        <div className="page-coaching-upcoming__column-modal-left">
                            <div className="column-modal-left__checkbox-wrapper">
                                <AppCheckbox onChange={onHandleStatusCheckbox} checked={selectedSchedule.status.value && selectedSchedule.status.value.toLowerCase() === "completed" ? true : false} />
                                <span className="column-modal-left__checkbox-label">Mark as Completed</span>
                            </div>

                            <div className="column-modal-left__input-wrapper">
                                <AppInputWithLabel placeholder="Employee Username" value={selectedScheduleExtraInfo.staffName} disabled={true} />
                            </div>

                            <div className="column-modal-left__input-wrapper">
                                <AppDropdown
                                    placeholder="Mentor Username"
                                    onChange={(selected) => onHandleMentorName(selected)}
                                    value={selectedSchedule.mentorName.value}
                                    dropdownOptions={mentorNameList}
                                    currentInputValue={selectedSchedule.mentorName.value ? mentorNameList.filter((ele) => ele.label === selectedSchedule.mentorName.value)[0] : null}
                                    error={selectedSchedule.mentorName.error}
                                    isDisabled={selectedScheduleExtraInfo.status.toLowerCase() === "overdue" ? true : false}
                                />
                            </div>

                            <div className="column-modal-left__input-wrapper">
                                <AppTextArea
                                    placeholder="Remark"
                                    onChange={(e) => onHandleRemark(e)}
                                    value={selectedSchedule.remark.value}
                                    error={selectedSchedule.remark.error}
                                    disabled={selectedScheduleExtraInfo.status.toLowerCase() === "overdue" ? true : false}
                                />
                            </div>
                        </div>
                    }
                    childrenRight={
                        <div className="page-coaching-upcoming__column-modal-right">
                            <div className="column-modal-right__cancel-wrapper">
                                <button className="column-modal-right__cancel" onClick={onHandleCancelSchedule}>
                                    Cancel Session
                                </button>
                            </div>
                            <div className="column-modal-right__input-wrapper">
                                <AppInputDate
                                    placeholder="Date"
                                    onChange={(value) => onHandleScheduleDate(value)}
                                    value={selectedSchedule.scheduleDate.value}
                                    error={selectedSchedule.scheduleDate.error}
                                    disabled={selectedScheduleExtraInfo.status.toLowerCase() === "overdue" ? true : false}
                                />
                            </div>
                            <div className="column-modal-right__input-wrapper">
                                <AppInputWithLabel
                                    placeholder="Title"
                                    onChange={(v) => onHandleTitle(v)}
                                    value={selectedSchedule.title.value}
                                    error={selectedSchedule.title.error}
                                    disabled={selectedScheduleExtraInfo.status.toLowerCase() === "overdue" ? true : false}
                                />
                            </div>
                            <div onClick={(e) => e.stopPropagation()} className="column-modal-right__time-wrapper">
                                <AppTimePicker
                                    placeholder="Start Time"
                                    onFocus={(e, placeholder) => {
                                        setTimeIsOpen(placeholder);
                                    }}
                                    timeIsOpenPlaceholder={timeIsOpen}
                                    touched={true}
                                    startTime={{
                                        hours: selectedSchedule.startTime.value ? selectedSchedule.startTime.value.split(":")[0] : "",
                                        minutes: selectedSchedule.startTime.value ? selectedSchedule.startTime.value.split(":").join(" ").split(" ")[1] : "",
                                        ampm: selectedSchedule.startTime.value ? selectedSchedule.startTime.value.split(":").join(" ").split(" ")[2] : "",
                                    }}
                                    valueFunc={(value) => onHandleStartTimeValueFunc(value)}
                                    error={selectedSchedule.startTime.error}
                                    disabled={selectedScheduleExtraInfo.status.toLowerCase() === "overdue" ? true : false}
                                />
                            </div>

                            <div onClick={(e) => e.stopPropagation()} className="column-modal-right__time-wrapper">
                                <AppTimePicker
                                    placeholder="End Time"
                                    onFocus={(e, placeholder) => {
                                        setTimeIsOpen(placeholder);
                                    }}
                                    timeIsOpenPlaceholder={timeIsOpen}
                                    startTime={{
                                        hours: selectedSchedule.endTime.value ? selectedSchedule.endTime.value.split(":")[0] : "",
                                        minutes: selectedSchedule.endTime.value ? selectedSchedule.endTime.value.split(":").join(" ").split(" ")[1] : "",
                                        ampm: selectedSchedule.endTime.value ? selectedSchedule.endTime.value.split(":").join(" ").split(" ")[2] : "",
                                    }}
                                    touched={true}
                                    valueFunc={(value) => onHandleEndTimeValueFunc(value)}
                                    error={selectedSchedule.endTime.error}
                                    disabled={selectedScheduleExtraInfo.status.toLowerCase() === "overdue" ? true : false}
                                />
                            </div>
                        </div>
                    }
                    childrenBottom={
                        <div className="page-coaching-upcoming__column-modal-bottom">
                            <div className="column-modal-bottom__modal-input-button">
                                <AppButton label="Cancel" size="l" buttonType="outline" onClick={() => setEditLargeModalIsOpen(false)} />
                            </div>
                            <div className="column-modal-bottom__modal-input-button">
                                <AppButton label="Save" size="l" onClick={upComingSessionEditSave} />
                            </div>
                        </div>
                    }
                />
                <div className="page-coaching-upcoming__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>
                <div className="page-coaching-upcoming__tab-wrapper">
                    <AppTabLink tabList={appTabLink} />
                </div>

                <div className="page-coaching-upcoming__row">
                    <div className="page-coaching-upcoming__searchBar-wrapper">
                        <AppInput
                            leftSrc={iconSearch}
                            placeholder="Search keyword"
                            onChange={_.debounce((e) => searchBarOnChange(e), 1000)}
                            onKeyPress={handleKeypress}
                            rightSrc={iconSetting}
                            rightSrcOnClick={onHandleResetFilter}
                            onFocus={onHandleSearchKeyword}
                            ref={searchKeywordInputRef}
                        />
                    </div>

                    <div className="page-coaching-upcoming__button-wrapper">
                        <AppButton size="s" buttonIcon={<AiOutlinePlus size={12} />} label="Schedule Coaching Session" onClick={() => setCreateSessionIsOpen(true)} />
                    </div>
                </div>

                {showAdvanceSearch ? (
                    <div className="page-coaching-upcoming__search-content">
                        <div className="page-coaching-upcoming__input">
                            {advanceSearch.map((item, index) => {
                                const placeholder = item.placeholder;
                                const name = item.name;
                                const dropdownOptions = item.dropdownOptions;
                                switch (item.type) {
                                    case "input":
                                        return (
                                            <div key={index} className="page-coaching-upcoming__input-wrapper">
                                                <AppInputWithLabel
                                                    placeholder={placeholder}
                                                    onChange={(e) =>
                                                        setAdvanceSearchValue({
                                                            ...advanceSearchValue,
                                                            [name]: e.target.value,
                                                        })
                                                    }
                                                    value={advanceSearchValue[name]}
                                                />
                                            </div>
                                        );
                                    case "time":
                                        return (
                                            <div key={index} className="page-coaching-upcoming__input-wrapper" onClick={(e) => e.stopPropagation()}>
                                                <AppTimePicker2
                                                    placeholder={placeholder}
                                                    onFocus={(e, placeholder) => {
                                                        setTimeIsOpen(placeholder);
                                                    }}
                                                    timeIsOpenPlaceholder={timeIsOpen}
                                                    fallbackValues={name === "startTime" ? "10.00 AM" : name === "endTime" ? "11.00 AM" : ""}
                                                    valueFunc={(value) => {
                                                        if (advanceSearchValue[name] !== value) {
                                                            setAdvanceSearchValue({
                                                                ...advanceSearchValue,
                                                                [name]: value,
                                                            });
                                                        }
                                                    }}
                                                    ref={name === "startTime" ? startTimeKeywordInputRef : name === "endTime" ? endTimeKeywordInputRef : null}
                                                />
                                            </div>
                                        );
                                    case "date":
                                        return (
                                            <div key={index} className="page-coaching-upcoming__input-wrapper">
                                                <AppInputDate
                                                    placeholder={placeholder}
                                                    onChange={(value) => {
                                                        if (value === "") {
                                                            setAdvanceSearchValue({
                                                                ...advanceSearchValue,
                                                                [name]: "",
                                                            });
                                                        } else {
                                                            setAdvanceSearchValue({
                                                                ...advanceSearchValue,
                                                                [name]: moment(value, "DD/MM/YYYY").format("YYYY-MM-DD"),
                                                            });
                                                        }
                                                    }}
                                                    value={moment(advanceSearchValue[name], "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                />
                                            </div>
                                        );
                                    case "dropdown":
                                        return (
                                            <div key={index} className="page-coaching-upcoming__input-wrapper">
                                                <AppDropdown
                                                    placeholder={placeholder}
                                                    dropdownOptions={dropdownOptions}
                                                    onChange={(e) => {
                                                        setAdvanceSearchValue({
                                                            ...advanceSearchValue,
                                                            [name]: e.value,
                                                        });
                                                    }}
                                                    value={advanceSearchValue[name]}
                                                    currentInputValue={advanceSearchValue[name] ? item.dropdownOptions.filter((ele) => ele.label === advanceSearchValue[name])[0] : null}
                                                />
                                            </div>
                                        );
                                    default:
                                        return null;
                                }
                            })}
                        </div>
                        <div className="page-coaching-upcoming__advance-button-row">
                            <div className="page-coaching-upcoming__advance-button-wrapper">
                                <AppButton label="Reset" buttonType="outline" size="s" onClick={onHandleResetSearch} />
                            </div>
                            <div className="page-coaching-upcoming__advance-button-wrapper">
                                <AppButton label="Apply" size="s" onClick={onHandleApplyAdvanceSearch} />
                            </div>
                        </div>
                    </div>
                ) : null}

                <div className="page-coaching-upcoming__table-wrapper">
                    <AppReactTable onClickRow={onHandleRowSelect} columns={tableColumn} data={tableData.content} searchKeyword={tableSearchFilter.searchValue || !Object.values(advanceSearchValue).every((e) => e === "")} />
                </div>
                <div className="page-coaching-upcoming__pagination">
                    <AppPaginate onPageChange={onHandlePageChange} pageCount={tableData.totalPages} forcePage={tableSearchFilter.pageNo} />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageCoachingUpcomingSession;
