import React from "react";
import classNames from "common/class-names";
import CONSTANT from "common/constant";

const AppStatus = ({ label, column }) => {
    const intern = CONSTANT.EMPLOYEE_STATUS.INTERN;
    const probation = CONSTANT.EMPLOYEE_STATUS.PROBATION;
    const contract = CONSTANT.EMPLOYEE_STATUS.CONTRACT;
    const partTime = CONSTANT.EMPLOYEE_STATUS.PART_TIME;
    const resigned = CONSTANT.EMPLOYEE_STATUS.RESIGNED;
    const dismissal = CONSTANT.EMPLOYEE_STATUS.DISMISSAL;
    const termination = CONSTANT.EMPLOYEE_STATUS.TERMINATION;
    const permanent = CONSTANT.EMPLOYEE_STATUS.PERMANEMT;

    const appStatusClassName = classNames({
        "app-status": true,
        "app-status--column-status": column,
        "app-status--resigned": resigned === label || dismissal === label || termination === label,
        "app-status--permenant": permanent === label,
        "app-status--contract": intern === label || probation === label || contract === label || partTime === label,
    });

    return <div className={appStatusClassName}>{label}</div>;
};

export default AppStatus;
