import letterHeaderBase64 from "assets/images/letter-header-base64";
import letterFooterSignatureBase64 from "assets/images/letter-footer-signature";

const letterExtendProbationPdf = (letterDetails) => {
    return {
        content: [
            {
                nodeName: "DIV",
                stack: [
                    {
                        nodeName: "IMG",
                        image: letterHeaderBase64,
                        width: 364,
                        style: ["html-img", "html-div", "image"],
                    },
                    {
                        nodeName: "DIV",
                        stack: [
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "PRIVATE & CONFIDENTIAL",
                                nodeName: "STRONG",
                                fontSize: 11,
                                alignment: "justify",
                                bold: true,
                                style: ["html-strong", "html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: letterDetails.date,
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: letterDetails.name,
                                nodeName: "STRONG",
                                fontSize: 11,
                                alignment: "justify",
                                bold: true,
                                style: ["html-strong", "html-div"],
                            },
                            {
                                text: letterDetails.address1,
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: letterDetails.address2,
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: letterDetails.address3,
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: letterDetails.address4,
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "Dear " + letterDetails.dearName + ",",
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "EXTENSION OF PROBATIONARY PERIOD",
                                nodeName: "STRONG",
                                fontSize: 11,
                                alignment: "justify",
                                bold: true,
                                style: ["html-strong", "html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "As you are aware, your probationary period is due to end on " + letterDetails.probationEnd + ". Unfortunately, we regret to inform you that your work performance during your probationary period has not yet reached a satisfactory standard and we are unable to confirm your employment at this point.",
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "The management has decided to extend your probationary period for a further " + letterDetails.probationMonthExtend + " months starting from " + letterDetails.startDate + " to " + letterDetails.probationExtendEnd + ".",
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "Within this period, you are advised to seek your superior for guidance and feedback and improve your skills and performace.",
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "All other terms and conditions of your employment as specified in your Letter of Offer dated " + letterDetails.lastOfferLetter + " remain unchanged.",
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "Wish you the best for your performance.",
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "Yours sincerely,",
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                nodeName: "IMG",
                                image: letterFooterSignatureBase64,
                                width: 104,
                                style: ["html-img", "html-div", "image"],
                            },
                        ],
                    },
                ],
            },
        ],
    };

}

export default letterExtendProbationPdf;