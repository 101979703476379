// libraries
import React from "react";
import Scrollbars from "rc-scrollbars";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { appToastDismiss } from "components/app-toast";
// components
import AppSideNav from "components/app-side-nav";
import AppHeaderBar from "./app-header-bar";
// routes
import routes from "routes/routes";

const getMainClassNames = (authRequired) => {
    const classNames = ["root-main"];
    if (authRequired) classNames.push("root-main--height-deduct");
    return classNames.join(" ");
};

const getPagesClassNames = (authRequired) => {
    const classNames = ["app-layout__pages-wrapper"];
    if (authRequired) classNames.push("app-layout--min-width");
    if (!authRequired) classNames.push("app-layout__auth-page")
    return classNames.join(" ");
};

const AppLayout = ({ children }) => {
    const location = useLocation();
    const currentRoutes = routes.find((ele) => {
        if (ele.path.includes(":")) {
            const splitedColonPath = ele.path.split(":")[0];
            return location.pathname.includes(splitedColonPath);
        } else {
            return ele.path === location.pathname;
        }
    });
    const authRequired = currentRoutes ? currentRoutes.authentication : false;
    console.log("authRequired =>", authRequired);
    appToastDismiss();

    return (
        <div className="root-page app-layout">
            <ToastContainer limit={1} />
            {authRequired ? <AppSideNav /> : null}
            <main className={getMainClassNames(authRequired)}>
                {authRequired ? <AppHeaderBar /> : null}
                <Scrollbars id="rc-scroll" className="app-layout__scrollbar">
                    <div className={getPagesClassNames(authRequired)}>{children}</div>
                </Scrollbars>
            </main>
        </div>
    );
};

export default AppLayout;
