const pathnames = {
    page404: "/404",
    pageLogin: "/login",
    pageResetPassword: "/reset-password",
    pageResetPasswordCheckEmail: "/reset-password/check-email",
    pageNewPassword: "/reset-password/new-password/:setResetPasswordToken",

    pageDashboard: "/admin/dashboard",
    pageUserManagement: "/admin/user-management",
    pageNewEmployee: "/admin/user-management/new-employee",
    pageEditEmployee: "/admin/user-management/edit-employee/:username",

    pageCompanyBenefitsPosition: "/admin/company-benefits/position",
    pageCompanyBenefitsBenefit: "/admin/company-benefits/benefit",
    pageCreateBenefitPackage: "/admin/company-benefits/benefit/create-benefit-package",
    pageEditBenefitPackage: "/admin/company-benefits/benefit/edit-benefit-package/:pathPackageName",

    pageLeaveManagementCalendar: "/admin/leave-management/leave-calendar",
    pageLeaveDirectory: "/admin/leave-management/leave-directory",
    pageLeaveSummary: "/admin/leave-management/leave-directory/leave-summary/:pathStaffId",
    pageLeaveHistory: "/admin/leave-management/leave-directory/leave-history/:pathStaffId",
    pagePendingLeave: "/admin/leave-management/leave-directory/pending-leave/:pathStaffId",
    pageLeavePendingApproval: "/admin/leave-management/leave-pending-approval",

    pageClaimDirectory: "/admin/claim-management/claim-directory",
    pageClaimPendingApproval: "/admin/claim-management/claim-pending-approval",
    pageClaimPendingPayment: "/admin/claim-management/claim-pending-payment",
    pageClaimSummary: "/admin/claim-management/claim-directory/claim-summary/:pathStaffId",
    pageClaimHistory: "/admin/claim-management/claim-directory/claim-history/:pathStaffId",
    pagePendingClaim: "/admin/claim-management/claim-directory/pending-claim/:pathStaffId",

    pageTimesheetManagement: "/admin/timesheet-management/timesheet-management",

    pagePayslip: "/admin/payslip",
    pageEmployeePayslip: "/admin/payslip/:pathStaffId",

    pageEmployeeConfirmation: "/admin/employee-confirmation",
    pageEmployeeConfirmationSendLetter: "/admin/employee-confirmation/send-letter",

    pageCoachingUpcomingSession: "/admin/coaching-session/upcoming-schedule",
    pageCoachingHistory: "/admin/coaching-session/coaching-history",
};

export default pathnames;
