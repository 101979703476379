// libraries
import React, { useState, useEffect } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import moment from "moment";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTabLink from "components/app-tab-link";
import AppReactTable from "components/app-react-table";
import AppPaginate from "components/app-paginate";
import appToast from "components/app-toast";
// common
import { sanitizeError } from "common/utilities";
// hooks
import usePrevious from "hooks/use-previous";
// services
import api from "services/api";
// routes
import pathnames from "routes/pathnames";
// assets
import iconTailedArrowUp from "assets/images/icon-tailed-arrow-up.svg";

const breadcrumb = [
    {
        label: "Leave Management",
    },
    {
        label: "Leave Directory",
        pathname: pathnames.pageLeaveDirectory,
    },
    {
        label: "Leave Summary",
    },
];

const getUserStatusClassNames = (status) => {
    const classNames = ["page-leave-summary__user-status"];
    if (status === "working") classNames.push("page-leave-summary__user-status--green");
    if (status === "on leave") classNames.push("page-leave-summary__user-status--red");

    return classNames.join(" ");
};

const PageLeaveSummary = () => {
    const [leaveSummaryTable, setLeaveSummaryTable] = useState([]);
    const [tableUpcomingLeaveSearchFilter, setUpcomingLeaveSearchFilter] = useState({
        pageNo: 0,
        pageSize: 5,
        sortBy: "",
        sortDir: "asc",
    });
    const [upcomingLeaveTableData, setUpcomingLeaveTableData] = useState({
        leaveDirList: [],
        last: null,
        pageNo: 0,
        pageSize: 5,
        totalElements: null,
        totalPages: 1,
    });
    const prevValueSortHeader = usePrevious({
        sortBy: tableUpcomingLeaveSearchFilter.sortBy,
        sortDir: tableUpcomingLeaveSearchFilter.sortDir,
    });
    const [workingStatus, setWorkingStatus] = useState({
        staffId: "",
        userName: "",
        workingStatus: "",
    });
    const history = useHistory();
    const location = useLocation();
    const { pathStaffId } = useParams();
    const staffId = pathStaffId.replace("value_", "");

    const appTabLink = [
        {
            label: "Leave Summary",
            pathname: pathnames.pageLeaveSummary.replace(":pathStaffId", "value_" + staffId),
        },
        {
            label: "Leave History",
            pathname: pathnames.pageLeaveHistory.replace(":pathStaffId", "value_" + staffId),
        },
        {
            label: "Pending Leave",
            pathname: pathnames.pagePendingLeave.replace(":pathStaffId", "value_" + staffId),
        },
    ];

    useEffect(() => {
        const getWorkingStatus = async () => {
            try {
                let payload = ("000" + staffId).slice(-3);
                const response = await api.get.leaveManagementWorkingStatus(payload);
                setWorkingStatus(response.data.result);
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        };
        getWorkingStatus();
        const getStaffLeaveSummary = async () => {
            try {
                const response = await api.post.leaveManagementGetLeaveSummary(staffId);
                setLeaveSummaryTable(response.data.result.leaveRemaining);
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        };
        getStaffLeaveSummary();
    }, [pathStaffId, history, staffId]);

    useEffect(() => {
        const getTableData = async () => {
            try {
                let payload = {
                    ...tableUpcomingLeaveSearchFilter,
                    staffId: staffId,
                };
                const response = await api.get.leaveManagementUpcomingLeave(payload);
                setUpcomingLeaveTableData(response.data.result);
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        };
        getTableData();
    }, [tableUpcomingLeaveSearchFilter, location, staffId, history]);

    const headerArrowRotate = (active) => {
        const classNames = ["react-table__arrow-icon"];
        if (active && tableUpcomingLeaveSearchFilter.sortDir === "desc") classNames.push("react-table__arrow-icon-down");
        if (active) classNames.push("react-table__arrow-icon--active");

        return classNames.join(" ");
    };

    const headerFilterClick = (header) => {
        let column = header.column;
        if (prevValueSortHeader.sortBy === column.searchFilterValue) {
            if (prevValueSortHeader.sortDir === "asc") {
                setUpcomingLeaveSearchFilter({
                    ...tableUpcomingLeaveSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "desc",
                    pageNo: 0,
                });
            } else {
                setUpcomingLeaveSearchFilter({
                    ...tableUpcomingLeaveSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "asc",
                    pageNo: 0,
                });
            }
        } else {
            setUpcomingLeaveSearchFilter({
                ...tableUpcomingLeaveSearchFilter,
                sortBy: column.searchFilterValue,
                sortDir: "asc",
                pageNo: 0,
            });
        }
    };

    const tableColumn = [
        {
            id: "leaveType",
            Header: () => {
                return <span className="react-table__leave-type-header">Leave Type</span>;
            },
            accessor: "leaveType",
            Cell: (row) => {
                return <span className="react-table__leave-type-row">{row.row.original.leaveType}</span>;
            },
        },
        {
            id: "entitledDays",
            Header: "Entitled Days",
            accessor: "entitledDays",
            Cell: (row) => {
                return <span className="react-table__table-data">{row.row.original.entitledDays}</span>;
            },
        },
        {
            id: "balanceDays",
            Header: "Balance Days",
            accessor: "balanceDays",
            Cell: (row) => {
                return <span className="react-table__table-data">{row.row.original.balanceDays}</span>;
            },
        },
        {
            id: "daysTaken",
            Header: "Days Taken",
            accessor: "daysTaken",
            Cell: (row) => {
                return <span className="react-table__table-data">{row.row.original.daysTaken}</span>;
            },
        },
    ];

    const tableColumnUpcomingLeave = [
        {
            id: "leaveType",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__leave-type-header  react-table__header">
                        Leave Type
                        <img className={headerArrowRotate(tableUpcomingLeaveSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            searchFilterValue: "leave_type",
            Cell: (row) => {
                return <span className="react-table__leave-type-row">{row.row.original.leaveType}</span>;
            },
        },
        {
            id: "leaveDays",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Days
                        <img className={headerArrowRotate(tableUpcomingLeaveSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <span className="react-table__days-row">{row.row.original.leaveDays}</span>;
            },
            searchFilterValue: "leave_days",
        },
        {
            id: "startDate",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Start Date
                        <img className={headerArrowRotate(tableUpcomingLeaveSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <span>{moment(row.row.original.startDate).format("DD/MM/YYYY")}</span>;
            },
            searchFilterValue: "start_date",
        },
        {
            id: "endDate",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        End Date
                        <img className={headerArrowRotate(tableUpcomingLeaveSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <span>{moment(row.row.original.endDate).format("DD/MM/YYYY")}</span>;
            },
            searchFilterValue: "end_date",
        },
        {
            id: "timeOff",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Time Off
                        <img className={headerArrowRotate(tableUpcomingLeaveSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            searchFilterValue: "time_off",
            accessor: "timeOff",
        },
    ];

    const onPageChange = (e) => {
        setUpcomingLeaveSearchFilter({
            ...tableUpcomingLeaveSearchFilter,
            pageNo: e.selected,
        });
    };

    return (
        <AppPagesLayout>
            <div className="page-leave-summary">
                <div className="page-leave-summary__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>
                <div className="page-leave-summary__user-info">
                    <div className="page-leave-summary__user-info--left">
                        <span>{workingStatus.userName}</span>
                        <span className="page-leave-summary__user-id">{workingStatus.staffId}</span>
                    </div>
                    <span className={getUserStatusClassNames("working")}>
                        <span>{workingStatus.workingStatus}</span>
                    </span>
                </div>
                <div className="page-leave-summary__tab-wrapper">
                    <AppTabLink tabList={appTabLink} />
                </div>

                <div className="page-leave-summary__table-wrapper">
                    <AppReactTable columns={tableColumn} data={leaveSummaryTable} customEmptyListMessage="Looks like you don't have any benefits yet , please contact the HR team for further clarification"/>
                </div>
                <div className="page-leave-summary__table-title">Upcoming Leave</div>

                <div className="page-leave-summary__table-wrapper">
                    <AppReactTable columns={tableColumnUpcomingLeave} data={upcomingLeaveTableData.leaveDirList} />
                </div>

                <div className="page-leave-summary__pagination">
                    <AppPaginate onPageChange={onPageChange} pageCount={upcomingLeaveTableData.totalPages} forcePage={upcomingLeaveTableData.pageNo}/>
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageLeaveSummary;
