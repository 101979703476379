// libraries
import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
// components
import AppModal from "components/app-modal";
import AppInput from "components/app-input";
import appToast from "components/app-toast";
import AppButton from "components/app-button";
import AppDropdown from "components/app-dropdown";
import AppCheckbox from "components/app-checkbox";
import AppTextArea from "components/app-text-area";
import AppInputDate from "components/app-input-date";
import AppTimePicker from "components/app-time-picker";
import AppModalConfirmation from "components/app-modal-confirmation";
// common
import { sanitizeError } from "common/utilities";
// api
import api from "services/api";

const initialValues = {
    endTime: {
        placeholder: "End time",
        value: "",
        error: "",
    },
    mentorId: {
        placeholder: "Mentor ID",
        value: "",
        error: "",
    },
    mentorName: {
        placeholder: "Mentor name",
        value: "",
        error: "",
    },
    remark: {
        placeholder: "Remark",
        value: "",
        error: "",
        optional: true,
    },
    scheduleDate: {
        placeholder: "Schedule date",
        value: "",
        error: "",
    },
    staffId: {
        placeholder: "Staff ID",
        value: "",
        error: "",
    },
    staffName: {
        placeholder: "Staff name",
        value: "",
        error: "",
    },
    startTime: {
        placeholder: "Start time",
        value: "",
        error: "",
    },
    status: {
        placeholder: "",
        value: "",
        error: "",
        optional: true,
    },
    title: {
        placeholder: "Title",
        value: "",
        error: "",
    },
};

const PageCreateCoachingSession = ({ largeModalIsOpen, onRequestClose, onRequestOpen, postSuccess }) => {
    const [timeIsOpen, setTimeIsOpen] = useState("");
    const [value, setValue] = useState(initialValues);
    const [confirmModal, setConfirmModal] = useState({
        isOpen: false,
        confirmDisable: false,
    });
    const [staffNameList, setStaffNameList] = useState([]);
    const [timePickerTouched, setTimePickerTouched] = useState({
        startTime: false,
        endTime: false,
    });

    useEffect(() => {
        const getStaffNamePosition = async () => {
            try {
                const response = await api.get.getStaffNamePosition();
                const staffList = response.data.result.staffNameList.map((o) => {
                    return { value: o.userName, id: o.value2 };
                });
                setStaffNameList(staffList);
            } catch (error) {
                const sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        };
        getStaffNamePosition();
    }, []);

    const confirmationModalConfirmed = async () => {
        const { mentorName, staffName, ...others } = value;
        let payload = {};
        for (const [k, v] of Object.entries(others)) {
            payload = {
                ...payload,
                [k]: v.value,
            };
        }
        try {
            await api.post.coachingSessionScheduleSession(payload);
            setConfirmModal({
                isOpen: false,
                confirmDisable: false,
            });
            setValue(initialValues);
            appToast("Coaching session has been scheduled successfully", true);
            postSuccess();
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    const createSession = () => {
        let clonedValue = { ...value };
        // check time
        let endTime = moment(clonedValue.endTime.value, "hh:mm A");
        let startTime = moment(clonedValue.startTime.value, "hh:mm A");

        if (endTime.isBefore(startTime) || endTime._i === startTime._i) {
            clonedValue = {
                ...clonedValue,
                endTime: {
                    ...clonedValue.endTime,
                    error: "End time is required to be after start time.",
                },
                startTime: {
                    ...clonedValue.startTime,
                    error: "Start time is required to be before end time.",
                },
            };
        }
        // check required
        for (const key in clonedValue) {
            if (!clonedValue[key].value && !clonedValue[key].optional) {
                clonedValue = {
                    ...clonedValue,
                    [key]: {
                        ...clonedValue[key],
                        error: `${clonedValue[key].placeholder} is required.`,
                    },
                };
            } else if (clonedValue[key].value === "Invalid date") {
                clonedValue = {
                    ...clonedValue,
                    [key]: {
                        ...clonedValue[key],
                        error: `${clonedValue[key].placeholder} is invalid.`,
                    },
                };
            } else {
                clonedValue = {
                    ...clonedValue,
                    [key]: {
                        ...clonedValue[key],
                        error: "",
                    },
                };
            }
        }

        if (clonedValue.mentorId.value === clonedValue.staffId.value) {
            clonedValue = {
                ...clonedValue,
                mentorId: {
                    ...clonedValue.mentorId,
                    error: "Mentor and mentee need to be different.",
                },
                mentorName: {
                    ...clonedValue.mentorName,
                    error: "Mentor and mentee need to be different.",
                },
                staffId: {
                    ...clonedValue.staffId,
                    error: "Mentor and mentee need to be different.",
                },
                staffName: {
                    ...clonedValue.staffName,
                    error: "Mentor and mentee need to be different.",
                },
            };
        }

        setValue(clonedValue);

        if (Object.values(clonedValue).every((e) => e.error === "")) {
            onRequestClose();
            setConfirmModal({
                isOpen: true,
                confirmDisable: false,
            });
        }
    };

    const leftModalFields = [
        {
            name: "status",
            type: "checkbox",
            placeholder: "",
        },
        {
            name: "staffName",
            type: "dropdown",
            placeholder: "Employee Username",
        },
        {
            name: "mentorName",
            type: "dropdown",
            placeholder: "Mentor Username",
        },
        {
            name: "remark",
            type: "textarea",
            placeholder: "Remark (Optional)",
        },
    ];

    const rightModalFields = [
        {
            name: "scheduleDate",
            type: "date",
            placeholder: "Date",
        },
        {
            name: "title",
            type: "input",
            placeholder: "Title",
        },
        {
            name: "startTime",
            type: "time",
            placeholder: "Start Time",
        },
        {
            name: "endTime",
            type: "time",
            placeholder: "End Time",
        },
    ];

    const onHandleStatus = () => {
        if (value.status.value.toLowerCase() === "completed") {
            setValue({
                ...value,
                scheduleDate: {
                    ...value.scheduleDate,
                    value: "",
                },
                status: {
                    ...value.status,
                    value: "",
                },
            });
        } else {
            setValue({
                ...value,
                scheduleDate: {
                    ...value.scheduleDate,
                    value: "",
                },
                status: {
                    ...value.status,
                    value: "Completed",
                },
            });
        }
    };

    const onHandleStaffName = (selected) => {
        setValue({
            ...value,
            staffName: {
                ...value.staffName,
                value: selected.label,
                error: "",
            },
            staffId: {
                ...value.staffId,
                value: selected.value,
                error: "",
            },
            mentorName: {
                ...value.mentorName,
                error: "",
            },
            mentorId: {
                ...value.mentorId,
                error: "",
            },
        });
    };

    const onHandleMentorName = (selected) => {
        setValue({
            ...value,
            mentorName: {
                ...value.mentorName,
                value: selected.label,
                error: "",
            },
            mentorId: {
                ...value.mentorId,
                value: selected.value,
                error: "",
            },
            staffName: {
                ...value.staffName,
                error: "",
            },
            staffId: {
                ...value.staffId,
                error: "",
            },
        });
    };

    const onHandleRemark = (e) => {
        setValue({
            ...value,
            remark: {
                ...value.remark,
                value: e.target.value,
                error: "",
            },
        });
    };

    const onHandleScheduleDate = (v) => {
        setValue({
            ...value,
            scheduleDate: {
                ...value.scheduleDate,
                value: v,
                error: "",
            },
        });
    };

    const onHandleTitle = (v) => {
        setValue({
            ...value,
            title: {
                ...value.title,
                value: v.target.value,
                error: "",
            },
        });
    };

    return (
        <Fragment>
            <AppModalConfirmation
                isOpenModal={confirmModal.isOpen}
                onRequestClose={() => {
                    setConfirmModal({
                        isOpen: false,
                        confirmDisable: false,
                    });
                    onRequestOpen();
                }}
                details="Confirm to create"
                onClick={confirmationModalConfirmed}
                buttonDisabled={confirmModal.confirmDisable}
                buttonLabel="Confirm"
            />
            <AppModal
                title="Schedule a Session"
                isOpenModal={largeModalIsOpen}
                onRequestClose={() => {
                    onRequestClose();
                    setValue(initialValues);
                }}
                onClick={() => {
                    setTimeIsOpen("");
                }}>
                <div className="page-create-coaching-session">
                    <div className="page-create-coaching-session__contents">
                        <div className="page-create-coaching-session__checkbox-message">Please tick the checkbox in order to select backdate.</div>
                        <div className="page-create-coaching-session__column-modal-left">
                            <div className="column-modal-left__checkbox-gap" />

                            {leftModalFields.map((item, index) => {
                                const name = item.name;
                                const placeholder = item.placeholder;
                                const remarkValue = value.remark.value;
                                const remarkError = value.remark.error;
                                const staffNameValue = value.staffName.value;
                                const staffNameError = value.staffName.error;
                                const mentorNameValue = value.mentorName.value;
                                const mentorNameError = value.mentorName.error;
                                const staffNameCurrentInput = staffNameValue ? staffNameList.filter((ele) => ele.label === staffNameValue)[0] : null;
                                const mentorNameCurrentInput = mentorNameValue ? staffNameList.filter((ele) => ele.label === mentorNameValue)[0] : null;

                                switch (item.type) {
                                    case "checkbox":
                                        return (
                                            <div key={index} className="column-modal-left__checkbox-wrapper">
                                                <AppCheckbox checked={value.status.value.toLowerCase() === "completed"} onChange={onHandleStatus} />
                                                <span className="column-modal-left__checkbox-label">Mark as Completed</span>
                                            </div>
                                        );
                                    case "dropdown":
                                        return (
                                            <div key={index} className="column-modal-left__input-wrapper">
                                                <AppDropdown
                                                    placeholder={placeholder}
                                                    dropdownOptions={staffNameList}
                                                    onChange={(selected) => {
                                                        switch (name) {
                                                            case "staffName":
                                                                onHandleStaffName(selected);
                                                                break;
                                                            case "mentorName":
                                                                onHandleMentorName(selected);
                                                                break;
                                                            default:
                                                                return null;
                                                        }
                                                    }}
                                                    value={name === "staffName" ? staffNameValue : mentorNameValue}
                                                    currentInputValue={name === "staffName" ? staffNameCurrentInput : mentorNameCurrentInput}
                                                    error={name === "staffName" ? staffNameError : mentorNameError}
                                                />
                                            </div>
                                        );
                                    case "textarea":
                                        return (
                                            <div key={index} className="column-modal-left__input-wrapper">
                                                <AppTextArea placeholder={placeholder} onChange={(e) => onHandleRemark(e)} value={remarkValue} error={remarkError} />
                                            </div>
                                        );
                                    default:
                                        return null;
                                }
                            })}
                        </div>

                        <div className="page-create-coaching-session__column-modal-right">
                            {rightModalFields.map((item, index) => {
                                const name = item.name;
                                const placeholder = item.placeholder;
                                const titleValue = value.title.value;
                                const titleError = value.title.error;
                                const statusValue = value.status.value;
                                const endTimeValue = value.endTime.value;
                                const endTimeError = value.endTime.error;
                                const startTimeValue = value.startTime.value;
                                const startTimeError = value.startTime.error;
                                const scheduleDateValue = value.scheduleDate.value;
                                const scheduleDateError = value.scheduleDate.error;

                                switch (item.type) {
                                    case "date":
                                        return (
                                            <div className="column-modal-right__input-date-wrapper">
                                                <AppInputDate
                                                    placeholder={placeholder}
                                                    onChange={(v) => onHandleScheduleDate(v)}
                                                    maxDate={statusValue.toLowerCase() === "completed" ? "yesterday" : null}
                                                    minDate={statusValue.toLowerCase() !== "completed" ? "today" : null}
                                                    value={scheduleDateValue}
                                                    error={scheduleDateError}
                                                />
                                            </div>
                                        );
                                    case "input":
                                        return (
                                            <div key={index} className="column-modal-right__input-wrapper">
                                                <AppInput placeholder={placeholder} onChange={(v) => onHandleTitle(v)} value={titleValue} error={titleError} />
                                            </div>
                                        );
                                    case "time":
                                        return (
                                            <div key={index} onClick={(e) => e.stopPropagation()} className="column-modal-right__time-wrapper">
                                                <AppTimePicker
                                                    placeholder={placeholder}
                                                    onFocus={(e, placeholder) => {
                                                        setTimeIsOpen(placeholder);
                                                        setTimePickerTouched({
                                                            ...timePickerTouched,
                                                            startTime: true,
                                                        });
                                                        switch (name) {
                                                            case "startTime":
                                                                setTimePickerTouched({
                                                                    ...timePickerTouched,
                                                                    startTime: true,
                                                                });
                                                                break;
                                                            case "endTime":
                                                                setTimePickerTouched({
                                                                    ...timePickerTouched,
                                                                    endTime: true,
                                                                });
                                                                break;
                                                            default:
                                                                break;
                                                        }
                                                    }}
                                                    timeIsOpenPlaceholder={timeIsOpen}
                                                    startTime={{
                                                        hours: "10",
                                                        minutes: "00",
                                                        ampm: "am",
                                                    }}
                                                    touched={name === "startTime" ? timePickerTouched.startTime : timePickerTouched.startTime}
                                                    valueFunc={(v) => {
                                                        let time = `${v.hours}:${v.minutes} ${v.ampm}`;
                                                        switch (name) {
                                                            case "startTime":
                                                                if (startTimeValue !== time) {
                                                                    setValue({
                                                                        ...value,
                                                                        startTime: {
                                                                            ...value.startTime,
                                                                            value: time,
                                                                            error: "",
                                                                        },
                                                                    });
                                                                }
                                                                break;
                                                            case "endTime":
                                                                if (endTimeValue !== time) {
                                                                    setValue({
                                                                        ...value,
                                                                        endTime: {
                                                                            ...value.endTime,
                                                                            value: time,
                                                                            error: "",
                                                                        },
                                                                    });
                                                                }
                                                                break;
                                                            default:
                                                                break;
                                                        }
                                                    }}
                                                    error={name === "startTime" ? startTimeError : endTimeError}
                                                />
                                            </div>
                                        );
                                    default:
                                        return null;
                                }
                            })}
                        </div>
                    </div>
                    <div className="page-create-coaching-session__column-modal-bottom">
                        <div className="column-modal-bottom__modal-input-button">
                            <AppButton
                                label="Cancel"
                                size="l"
                                buttonType="outline"
                                onClick={() => {
                                    onRequestClose();
                                    setValue(initialValues);
                                }}
                            />
                        </div>
                        <div className="column-modal-bottom__modal-input-button">
                            <AppButton label="Create" size="l" onClick={createSession} />
                        </div>
                    </div>
                </div>
            </AppModal>
        </Fragment>
    );
};

export default PageCreateCoachingSession;
