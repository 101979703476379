// libraries
import React, { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { updateProfile } from "redux/slices/auth-slice";
// api
import api from "services/api";
// common
import ERRORS from "common/errors";
import { sanitizeError } from "common/utilities";
// components
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppAuthLayout from "components/app-auth-layout";
// assets
import logoAtf from "assets/images/logo-atf.svg";
// routes
import pathnames from "routes/pathnames";
import employeePathnames from "routes/employee-pathnames";

const initialValues = {
    employeeId: "",
    password: "",
};

const validationSchema = yup.object().shape({
    employeeId: yup.string().required(ERRORS.REQUIRED),
    password: yup.string().required(ERRORS.REQUIRED),
});

const PageLogin = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const searchparam = window.location.search;
    const [errorMessage, setErrorMessage] = useState({
        id: "",
        password: "",
    });
    const [button, setButton] = useState({
        admin: {
            label: "Administrator",
            disabled: false,
        },
        employee: {
            label: "Employee",
            disabled: false,
        },
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            onHandleLogin(values);
        },
    });

    const onHandleChange = (name, value) => {
        formik.setFieldTouched(name);
        formik.setFieldValue(name, value);
    };

    const onHandleLogin = async (values) => {
        switch (values.type) {
            case "administrator":
                setButton({
                    admin: {
                        label: "Logging in",
                        disabled: true,
                    },
                    employee: {
                        ...button.employee,
                        disabled: true,
                    },
                });

                try {
                    const payload = {
                        userIDOrEmail: values.employeeId.toLowerCase(),
                        password: values.password,
                    };

                    const response = await api.post.login(payload);
                    dispatch(updateProfile(response.data.result));
                    localStorage.setItem("@storage_token", response.data.result.jwtToken);

                    if (searchparam.includes("?coaching")) {
                        let coachingId = searchparam.replace("?coaching=", "");
                        history.push(pathnames.pageCoachingUpcomingSession, { coachingId });
                    } else {
                        history.push(pathnames.pageDashboard);
                    }
                } catch (error) {
                    const sanitizeErrorMessage = sanitizeError(error);

                    setErrorMessage({
                        id: sanitizeErrorMessage,
                        password: sanitizeErrorMessage,
                    });

                    setButton({
                        admin: {
                            label: "Administrator",
                            disabled: false,
                        },
                        employee: {
                            ...button.employee,
                            disabled: false,
                        },
                    });
                }
                break;

            case "employee":
                setButton({
                    admin: {
                        ...button.admin,
                        disabled: true,
                    },
                    employee: {
                        label: "Logging in",
                        disabled: true,
                    },
                });

                try {
                    const payload = {
                        userIDOrEmail: values.employeeId.toLowerCase(),
                        password: values.password,
                    };

                    const response = await api.post.staffLogin(payload);
                    dispatch(updateProfile(response.data.result));
                    localStorage.setItem("@storage_token", response.data.result.jwtToken);

                    if (searchparam.includes("?coaching")) {
                        let coachingId = searchparam.replace("?coaching=", "");
                        history.push(employeePathnames.pageEmployeeUpcomingSchedule, { coachingId });
                    } else {
                        history.push(employeePathnames.pageEmployeeDashboard);
                    }
                } catch (error) {
                    const sanitizeErrorMessage = sanitizeError(error);

                    setErrorMessage({
                        id: sanitizeErrorMessage,
                        password: sanitizeErrorMessage,
                    });

                    setButton({
                        admin: {
                            ...button.admin,
                            disabled: false,
                        },
                        employee: {
                            label: "Employee",
                            disabled: false,
                        },
                    });
                }
                break;

            default:
                break;
        }
    };

    const onFocusId = () => {
        setErrorMessage({
            ...errorMessage,
            id: "",
        });
    };

    const onFocusPassword = () => {
        setErrorMessage({
            ...errorMessage,
            password: "",
        });
    };

    return (
        <AppAuthLayout>
            <div className="page-login">
                <img className="page-login__logo" src={logoAtf} alt="" />
                <div className="page-login__title">Welcome Back!</div>
                <div className="page-login__subtitle">Human Resource Platform</div>
                <div className="page-login__input-wrapper">
                    <AppInput
                        placeholder="Employee ID / Email"
                        error={errorMessage.id ? errorMessage.id : formik.touched.employeeId && formik.errors.employeeId ? formik.errors.employeeId : ""}
                        onFocus={onFocusId}
                        onChange={(e) => onHandleChange("employeeId", e.target.value)}
                        value={formik.values.employeeId}
                        onBlur={() => formik.setFieldTouched("employeeId")}
                    />
                </div>
                <div className="page-login__input-wrapper">
                    <AppInput
                        placeholder="Password"
                        error={errorMessage.password ? errorMessage.password : formik.touched.password && formik.errors.password ? formik.errors.password : ""}
                        onFocus={onFocusPassword}
                        type="password"
                        onChange={(e) => onHandleChange("password", e.target.value)}
                        value={formik.values.password}
                        onBlur={() => formik.setFieldTouched("password")}
                    />
                </div>
                <div className="page-login__forgot-password-wrapper">
                    <Link to="/reset-password" className="page-login__forgot-password">
                        Forgot Password?
                    </Link>
                </div>
                <div className="page-login__buttons-wrapper">
                    <div className="page-login__button">
                        <AppButton
                            label={button.admin.label}
                            buttonType="outline"
                            size="l"
                            disabled={button.admin.disabled}
                            onClick={() => {
                                formik.setFieldValue("type", "administrator");
                                formik.handleSubmit();
                            }}
                        />
                    </div>
                    <div className="page-login__button">
                        <AppButton
                            label={button.employee.label}
                            buttonType="solid"
                            size="l"
                            disabled={button.employee.disabled}
                            onClick={() => {
                                formik.setFieldValue("type", "employee");
                                formik.handleSubmit();
                            }}
                        />
                    </div>
                </div>
            </div>
        </AppAuthLayout>
    );
};

export default PageLogin;
