import React, { useContext } from "react";
import AppInputResponsive from "components/app-input-responsive";
import { AppLetterInputContext } from "./page-send-letter";

const AppLetterExtendProbationTemplate = () => {
    const { letterInput, setLetterInput } = useContext(AppLetterInputContext);
    return (
        <div>
            <div style={{ fontWeight: 700 }}>EXTENSION OF PROBATIONARY PERIOD</div>
            <br />
            <div>
                As you are aware, your probationary period is due to end on [
                <AppInputResponsive
                    className="page-send-letter__input"
                    onChange={(e) => {
                        setLetterInput({
                            ...letterInput,
                            probationEnd: e.target.value,
                        });
                    }}
                    value={letterInput.probationEnd}
                />
                ]. Unfortunately, we regret to inform you that your work performance during your probationary period has not yet reached a satisfactory standard and we are unable to confirm your employment at this point.
            </div>
            <br />
            <div>
                The management has decided to extend your probationary period for a further [
                <AppInputResponsive
                    className="page-send-letter__input"
                    onChange={(e) =>
                        setLetterInput({
                            ...letterInput,
                            probationMonthExtend: e.target.value,
                        })
                    }
                    value={letterInput.probationMonthExtend}
                />
                ] months starting from [
                <AppInputResponsive
                    className="page-send-letter__input"
                    onChange={(e) =>
                        setLetterInput({
                            ...letterInput,
                            startDate: e.target.value,
                        })
                    }
                    value={letterInput.startDate}
                />
                ] to [
                <AppInputResponsive
                    className="page-send-letter__input"
                    onChange={(e) =>
                        setLetterInput({
                            ...letterInput,
                            probationExtendEnd: e.target.value,
                        })
                    }
                    value={letterInput.probationExtendEnd}
                />
                ].
            </div>
            <br />
            <div>Within this period, you are advised to seek your superior for guidance and feedback and improve your skills and performace.</div>
            <br />
            <div>
                All other terms and conditions of your employment as specified in your Letter of Offer dated [
                <AppInputResponsive
                    className="page-send-letter__input"
                    onChange={(e) =>
                        setLetterInput({
                            ...letterInput,
                            lastOfferLetter: e.target.value,
                        })
                    }
                    value={letterInput.lastOfferLetter}
                />
                ] remain unchanged.
            </div>
            <br />
            <div>Wish you the best for your performance.</div>
        </div>
    );
};

export default AppLetterExtendProbationTemplate;
