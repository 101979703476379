import React from "react";

const AppToggle = ({ onChange, checked, readOnly, text }) => {
    return (
        <div className="app-toggle">
            <div className="app-toggle__text-toggle">{text}</div>
            <label className="app-toggle__switch">
                <input readOnly={readOnly} onChange={onChange} checked={checked} className="app-toggle__input" type="checkbox" />
                <span className="app-toggle__slider"></span>
            </label>
        </div>
    );
};

export default AppToggle;
