import React, { Fragment } from "react";
import AppModal from "./app-modal";
import iconClose from "assets/images/icon-close-black.svg";

const AppPdfImgViewer = ({ isOpenModal, onRequestClose, attachmentSrc }) => {
    return (
        <AppModal className="app-pdf-img-viewer__modal" isOpenModal={isOpenModal} onRequestClose={onRequestClose} emptyModal={true} onClick={() => console.log("fuck")}>
            <div className="app-pdf-img-viewer__container" onClick={onRequestClose}>
                {attachmentSrc.type === "pdf" ? (
                    <Fragment>
                        <img className="app-pdf-img-viewer__modal-close" src={iconClose} alt="" />
                        <iframe src={attachmentSrc.file} title={attachmentSrc.file} />
                    </Fragment>
                ) : (
                    <Fragment>
                        <img className="app-pdf-img-viewer__modal-close" src={iconClose} alt="" />
                        <img className="app-pdf-img-viewer__modal-image" alt="" src={attachmentSrc.file} />
                    </Fragment>
                )}
            </div>
        </AppModal>
    );
};

export default AppPdfImgViewer;
