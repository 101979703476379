// libraries
import { useRef, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
// hooks
import useIsMount from "hooks/use-is-mount";
// common
import { sanitizeError, isDevelopmentMode } from "common/utilities";
// services
import api from "services/api";
// components
import appToast from "components/app-toast";
// redux
import { resetProfile } from "redux/slices/auth-slice";

const sharedStyles = "margin: 5px 0; padding: 8px; border-radius: 20px; color: #FFFFFF;";
const initStyles = `${sharedStyles} background: #673AB7;`;
const reqSuccessStyles = `${sharedStyles} background: #F8EA8C;`;
const respSuccessStyles = `${sharedStyles} background: #8BC34A;`;
const errorStyles = `${sharedStyles} background: #F79489;`;
const debug = isDevelopmentMode();

const interceptorDebug = (title, styles, data = "") => {
    if (!debug) return;

    console.log(`%c ${title}`, styles);
    console.log(data);
    console.log("");
};

const InterceptorProvider = ({ children }) => {
    const isMount = useIsMount();
    const requestInterceptor = useRef();
    const responseInterceptor = useRef();
    const trialNumberRef = useRef(0);
    const dispatch = useDispatch();

    const logout = (dispatch) => {
        localStorage.removeItem("@storage_token");
        dispatch({ type: "logout" });
        dispatch(resetProfile());
    };

    useEffect(() => {
        if (isMount) {
            interceptorDebug("Init Axios Interceptor! 🎉", initStyles);

            requestInterceptor.current = axios.interceptors.request.use(
                (config) => {
                    interceptorDebug("REQUESTING 🚀", reqSuccessStyles, config.url);
                    return config;
                },
                (error) => {
                    interceptorDebug("REQUESTING ERROR 👎", errorStyles, sanitizeError(error));
                    return Promise.reject(error);
                }
            );

            responseInterceptor.current = axios.interceptors.response.use(
                (response) => {
                    interceptorDebug("RESPONSE SUCCESS: 🌟", respSuccessStyles, response.data);
                    return response;
                },
                async (error) => {
                    interceptorDebug("RESPONSE ERROR 🥲", errorStyles, sanitizeError(error));
                    const statusCode = error?.response?.status;

                    switch (statusCode) {
                        case 401:
                            if (trialNumberRef.current <= 2) {
                                trialNumberRef.current = trialNumberRef.current + 1;
                                try {
                                    let header = {
                                        isRefreshToken: true,
                                    };
                                    const response = await api.get.refreshToken(header);
                                    const refreshedJWTToken = response.data.result;
                                    localStorage.setItem("@storage_token", refreshedJWTToken);
                                    error.config.headers.Authorization = "Bearer " + refreshedJWTToken;
                                    return axios.request(error.config);
                                } catch (error) {
                                    appToast(sanitizeError(error), false);
                                    logout(dispatch);
                                    return Promise.reject(error);
                                }
                            } else {
                                appToast(sanitizeError(error), false);
                                logout(dispatch);
                                return Promise.reject(error);
                            }
                        default:
                            return Promise.reject(error);
                    }
                }
            );
        }
    }, [isMount, dispatch]);

    useEffect(() => {
        return () => {
            interceptorDebug("Ejected Axios Interceptor! 👋", initStyles);
            axios.interceptors.request.eject(requestInterceptor.current);
            axios.interceptors.response.eject(responseInterceptor.current);
        };
    }, []);

    return children;
};

export default InterceptorProvider;
