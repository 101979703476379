// libraries
import React, { Fragment } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

const getAppDropdownMenuClassNames = (tableDottedButton, mobileFullPageDropdown) => {
    const classNames = ["app-dropdown-mini__dropdown-menu"];
    if (tableDottedButton) classNames.push("app-dropdown-mini__dropdown-menu-positioning");
    if (mobileFullPageDropdown) classNames.push("app-dropdown-mini__dropdown-menu-mobile");
    return classNames.join(" ");
};

const getDropdownItemClassNames = (mobileVersion, active) => {
    const classNames = ["app-dropdown-mini__dropdown-item"];
    if (mobileVersion) classNames.push("app-dropdown-mini__dropdown-item-mobile");
    if (active) classNames.push("app-dropdown-mini__dropdown-item--active") 
    return classNames.join(" ");
};

const AppDropdownMini = ({ label, toggle, isOpen, dropdownItem, tableDottedButton, outContentsSelected, mobileFullPageDropdown }) => {
    return (
        <Dropdown className="app-dropdown-mini__dropdown" toggle={toggle} isOpen={isOpen}>
            <DropdownToggle className="app-dropdown-mini__dropdown-toggle">{label}</DropdownToggle>
            <DropdownMenu container="body" className={getAppDropdownMenuClassNames(tableDottedButton, mobileFullPageDropdown)}>
                {dropdownItem.map((item, index) => {
                    if (item.item) {
                        return (
                            <DropdownItem key={index} className={getDropdownItemClassNames(item.mobile, item.active)} onClick={item.onClick ? () => item.onClick(outContentsSelected ? outContentsSelected : null) : null}>
                                {item.item}
                            </DropdownItem>
                        );
                    } else if (item.element) {
                        return <Fragment key={index}>{item.element}</Fragment>;
                    } else {
                        return null;
                    }
                })}
            </DropdownMenu>
        </Dropdown>
    );
};

export default AppDropdownMini;
