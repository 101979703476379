// libraries
import React from "react";
import { useHistory } from "react-router-dom";
// assets
import iconRight from "assets/images/icon-arrow-right.svg";

const AppBreadcrumb = ({ list }) => {
    const history = useHistory();

    return (
        <div className="app-breadcrumb">
            {list.length &&
                list.map((item, index) => (
                    <div key={`${item.label}_${index}`} className="app-breadcrumb__item">
                        <span className="app-breadcrumb__label" onClick={index < list.length - 1 ? () => history.push(item.pathname) : () => history.go(0)}>
                            {item.label}
                        </span>
                        {index <= list.length - 2 ? <img className="app-breadcrumb__arrow" src={iconRight} alt="" /> : null}
                    </div>
                ))}
        </div>
    );
};

export default AppBreadcrumb;
