import letterHeaderBase64 from "assets/images/letter-header-base64";
import letterFooterSignatureBase64 from "assets/images/letter-footer-signature";

const letterDefaultTemplate = (letterDetails) => {
    return {
        content: [
            {
                nodeName: "DIV",
                stack: [
                    {
                        nodeName: "IMG",
                        image: letterHeaderBase64,
                        width: 364,
                        style: ["html-img", "html-div", "image"],
                    },
                    {
                        nodeName: "DIV",
                        stack: [
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "PRIVATE & CONFIDENTIAL",
                                nodeName: "STRONG",
                                fontSize: 11,
                                alignment: "justify",
                                bold: true,
                                style: ["html-strong", "html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: letterDetails.date,
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: letterDetails.name,
                                nodeName: "STRONG",
                                fontSize: 11,
                                alignment: "justify",
                                bold: true,
                                style: ["html-strong", "html-div"],
                            },
                            {
                                text: letterDetails.address1,
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: letterDetails.address2,
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: letterDetails.address3,
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: letterDetails.address4,
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "Dear " + letterDetails.dearName + ",",
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "CONFIRMATION OF APPOINTMENT",
                                nodeName: "STRONG",
                                fontSize: 11,
                                alignment: "justify",
                                bold: true,
                                style: ["html-strong", "html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: [
                                    {
                                        text: "We are pleased to inform you that your work performance during your probationary period has been satisfactory and that you will be confirmed in your appointment as employment as below with effect from",
                                        fontSize: 11,
                                        alignment: "justify",
                                        style: ["html-div"],
                                    },
                                    {
                                        text: "with effect from " + letterDetails.startDate + ".",
                                        fontSize: 11,
                                        alignment: "justify",
                                        style: ["html-div"],
                                    },
                                ],
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                nodeName: "DIV",
                                stack: [
                                    {
                                        nodeName: "TABLE",
                                        table: {
                                            headerRows: 1,
                                            widths: [170, 170],
                                            body: [
                                                [
                                                    { text: "LEVEL", style: "header" },
                                                    { text: "POSITION", style: "header" },
                                                ],
                                                [
                                                    { text: letterDetails.level, style: "body" },
                                                    { text: letterDetails.position, style: "body" },
                                                ],
                                            ],
                                        },
                                    },
                                ],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "All other terms and conditions of your employment as specified in your Letter of Offer dated " + letterDetails.lastOfferLetter + " remain unchanged.",
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "We take this opportunity to acknowledge and to thank you for your dedication, effort and contributions, and we wish you continuous success with Altecflex Sdn. Bhd.",
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "Yours sincerely,",
                                nodeName: "DIV",
                                fontSize: 11,
                                alignment: "justify",
                                style: ["html-div"],
                            },
                            {
                                nodeName: "IMG",
                                image: letterFooterSignatureBase64,
                                width: 104,
                                style: ["html-img", "html-div", "image"],
                                pageBreak: "after",
                            },
                            {
                                nodeName: "IMG",
                                image: letterHeaderBase64,
                                width: 364,
                                style: ["html-img", "html-div", "image"],
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                text: "\n",
                                nodeName: "BR",
                            },
                            {
                                nodeName: "DIV",
                                stack: [
                                    {
                                        nodeName: "TABLE",
                                        table: {
                                            headerRows: 1,
                                            widths: [30, 80, 260],
                                            body: [
                                                [
                                                    { text: "REF", style: "header" },
                                                    { text: "LEVEL", style: "header" },
                                                    { text: "POSITION", style: "header" },
                                                ],
                                                [
                                                    { text: "1", style: "body" },
                                                    { text: "SVP1", style: "body" },
                                                    { text: "DIRECTOR", style: "body" },
                                                ],
                                                [
                                                    { text: "2", style: "body" },
                                                    { text: "SVP2", style: "body" },
                                                    { text: "SENIOR MANAGEMENT", style: "body" },
                                                ],
                                                [
                                                    { text: "3", style: "body" },
                                                    { text: "VP1", style: "body" },
                                                    { text: "MID MANAGEMENT, SPECIALIST, SENIOR MANAGER", style: "body" },
                                                ],
                                                [
                                                    { text: "4", style: "body" },
                                                    { text: "VP2", style: "body" },
                                                    { text: "LEAD, MANAGER, KEY CONTRIBUTOR", style: "body" },
                                                ],
                                                [
                                                    { text: "5", style: "body" },
                                                    { text: "AVP", style: "body" },
                                                    { text: "SENIOR, TRAINER", style: "body" },
                                                ],
                                                [
                                                    { text: "6", style: "body" },
                                                    { text: "EXECUTIVE", style: "body" },
                                                    { text: "INDEPENDENT, EXECUTER", style: "body" },
                                                ],
                                                [
                                                    { text: "7", style: "body" },
                                                    { text: "TRAINEE", style: "body" },
                                                    { text: "ENTRY, JUNIOR", style: "body" },
                                                ],
                                                [
                                                    { text: "8", style: "body" },
                                                    { text: "INTERN", style: "body" },
                                                    { text: "ACADEMIC, ENTRY", style: "body" },
                                                ],
                                            ],
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
        styles: {
            header: {
                color: "#ffffff",
                fillColor: "#000000",
                bold: true,
                fontSize: 11,
            },
            body: {
                fontSize: 11,
            },
        },
    };
};

export default letterDefaultTemplate;
