import React, { useState, forwardRef } from "react";
// assets
import iconEyeShow from "assets/images/components/app-input/icon-eye-show.svg";
import iconEyeHide from "assets/images/components/app-input/icon-eye-hide.svg";

const getInputClassNames = (error, leftSrc, rightSrc, type, placeholder) => {
    const classNames = ["app-input__input"];
    if (error) {
        classNames.push("app-input__error");
    }
    if (leftSrc) {
        classNames.push("app-input--padding-left");
    }
    if (rightSrc) {
        classNames.push("app-input--padding-right");
    }
    if (type === "password") {
        classNames.push("app-input__input-password-placeholder");
    }
    if (placeholder.toLowerCase() === "search keyword") {
        classNames.push("app-input--font-weight-normal");
    }
    return classNames.join(" ");
};

const getRightSrcClassNames = (onClick) => {
    const classNames = ["app-input__right-icon"];
    if (onClick) {
        classNames.push("app-input--cursor-pointer");
    }
    return classNames.join(" ");
};

const AppInput = forwardRef(({ onChange, value, placeholder, error, type, onFocus, leftSrc, rightSrc, rightSrcOnClick, inputProps, onKeyPress, onBlur, disabled }, ref) => {
    const [passwordShow, setPasswordShow] = useState(false);
    const [focus, setFocus] = useState(false);

    const inputType = (type) => {
        if (type === "password" && !passwordShow) {
            return "password";
        }
        return "text";
    };

    const onFocusHandler = () => {
        setFocus(true);
        if (onFocus) {
            onFocus();
        }
    };

    const onBlurHandler = () => {
        setFocus(false);
        if (onBlur) {
            onBlur();
        }
    };

    return (
        <div className="app-input">
            <div className="app-input__wrapper">
                {leftSrc ? <img className="app-input__left-icon" alt="" src={leftSrc} /> : null}
                <input
                    onKeyPress={onKeyPress}
                    ref={ref}
                    onBlur={onBlurHandler}
                    onFocus={onFocusHandler}
                    className={getInputClassNames(focus ? null : error, leftSrc, rightSrc, type, placeholder)}
                    onChange={onChange}
                    value={value}
                    placeholder={placeholder}
                    disabled={disabled}
                    type={inputType(type)}
                    {...inputProps}
                />
                {type === "password" ? (
                    <button className="app-input__eye-button" onClick={() => setPasswordShow(!passwordShow)}>
                        <img className="app-input__eye-icon" alt="" src={passwordShow ? iconEyeShow : iconEyeHide} />
                    </button>
                ) : null}
                {rightSrc ? <img onClick={rightSrcOnClick ? rightSrcOnClick : null} className={getRightSrcClassNames(rightSrcOnClick)} src={rightSrc} alt="" /> : null}
            </div>
            {!focus && error ? <span className="app-input__error-message">{error}</span> : null}
        </div>
    );
});

export default AppInput;
