import React from "react";

const getContainerClassNames = (type) => {
    const classNames = ["app-input-display__container"];
    if (type === "current") classNames.push("app-input-display--gray");
    if (type === "new") classNames.push("app-input-display--blue");
    return classNames.join(" ");
};

const AppInputDisplay = ({ type, contents }) => {
    return (
        <div className="app-input-display">
            <div className={getContainerClassNames(type)}>
                <span className="app-input-display__benefit">{contents}</span>
                <span className="app-input-display__type">{type === "current" ? " (Current)" : " (New)"}</span>
            </div>
        </div>
    );
};

export default AppInputDisplay;
