// libraries
import React from "react";
// components
import AppModal from "components/app-modal";
import AppButton from "./app-button";

const AppModalConfirmation = ({ isOpenModal, onRequestClose, onClick, details, buttonDisabled, buttonLabel, cancelDisabled, isResponsiveFilledPage = false }) => {
    return (
        <AppModal isOpenModal={isOpenModal} isResponsiveFilledPage={isResponsiveFilledPage} onRequestClose={onRequestClose} title="Confirmation">
            <div className="app-modal-confirmation__body">
                <span className="app-modal-confirmation__body-content">{details}</span>
                <div className="app-modal-confirmation__button-row">
                    <div className="app-modal-confirmation__button-wrapper">
                        <AppButton size="s" buttonType="outline" label="Cancel" onClick={onRequestClose}  disabled={cancelDisabled}/>
                    </div>
                    <div className="app-modal-confirmation__button-wrapper">
                        <AppButton size="s" label={buttonLabel} onClick={onClick} disabled={buttonDisabled} />
                    </div>
                </div>
            </div>
        </AppModal>
    );
};

export default AppModalConfirmation;
