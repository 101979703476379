// libraries
import React, { useEffect, useState } from "react";
import { HiCheck, HiX } from "react-icons/hi";
import { Formik } from "formik";
import * as yup from "yup";
import { useHistory, useParams } from "react-router-dom";
// components
import AppAuthLayout from "components/app-auth-layout";
import AppInput from "components/app-input";
import appToast from "components/app-toast";
// constant
import ERRORS from "common/errors";
import CONSTANT from "common/constant";
// utilities
import { sanitizeError } from "common/utilities";
// hooks
import useIsMount from "hooks/use-is-mount";
// api
import api from "services/api";
// assets
import logoAtf from "assets/images/logo-atf.svg";
import AppButton from "components/app-button";
// pathnames
import pathnames from "routes/pathnames";

const initialValues = {
    newPassword: "",
    confirmPassword: "",
};

const validationSchema = yup.object().shape({
    newPassword: yup.string().min(8, "ERROR_1").matches(CONSTANT.REGEX.atLeastOneAlphabet, "ERROR_2").matches(CONSTANT.REGEX.atLeastOneNumber, "ERROR_3").required(ERRORS.REQUIRED),
    confirmPassword: yup
        .string()
        .required(ERRORS.REQUIRED)
        .test("", ERRORS.PASSWORD_N_MATCH, function (value) {
            return this.parent.newPassword === value;
        }),
});

const showAllErrorInArray = (schema) => (values) =>
    schema
        .validate(values, { abortEarly: false, strict: false })
        .then(() => ({}))
        .catch(({ inner }) => inner.reduce((memo, { path, message }) => ({ ...memo, [path]: (memo[path] || []).concat(message) }), {}));

const PageNewPassword = () => {
    const history = useHistory();
    const { setResetPasswordToken } = useParams();
    const isMount = useIsMount();

    const [button, setButton] = useState({
        submit: {
            label: "Submit",
            disabled: false,
        },
        login: {
            label: "Back to login",
            disabled: false,
        },
    });

    let checkingStart = false;

    useEffect(() => {
        const validateNewPasswordToken = async () => {
            try {
                let params = {
                    token: setResetPasswordToken,
                };
                const response = await api.get.validateResetPasswordToken(params);
                appToast(response.data.message, true);
            } catch (error) {
                appToast(sanitizeError(error), false, 300);
                history.push(pathnames.pageResetPassword);
            }
        };
        if (setResetPasswordToken.length === 36 && isMount) {
            localStorage.removeItem("@storage_token");
            validateNewPasswordToken();
        } else if (setResetPasswordToken.length !== 36 && isMount) {
            appToast("Invalid token", false, 300);
            history.push(pathnames.pageResetPassword);
        } else {
            history.push(pathnames.pageResetPassword);
        }
    }, [setResetPasswordToken, history, isMount]);

    const getIconClassNames = (errorType, currentError, values, touched) => {
        const classNames = [];
        if (touched && values.length) {
            checkingStart = true;
        }
        if (checkingStart) {
            if (currentError && currentError.includes(errorType)) {
                classNames.push("page-new-password__red");
            } else {
                classNames.push("page-new-password__green");
            }
        } else {
            classNames.push("page-new-password__gray");
        }

        return classNames.join(" ");
    };

    const onHandleNewPassword = async (values) => {
        setButton({
            submit: {
                label: "Submitting",
                disabled: true,
            },
            login: {
                ...button.login,
                disabled: true,
            },
        });
        try {
            const payload = {
                token: setResetPasswordToken,
                newPassword: values.newPassword,
            };
            await api.post.resetPassword(payload);
            appToast("Your password has been changed successfully", true, 300);
            history.push(pathnames.pageLogin);
        } catch (error) {
            let errorMessage = sanitizeError(error);
            appToast(errorMessage, false);
            setButton({
                submit: {
                    label: "Submit",
                    disabled: false,
                },
                login: {
                    ...button.login,
                    disabled: false,
                },
            });
        }
    };

    return (
        <AppAuthLayout>
            <Formik initialValues={initialValues} onSubmit={onHandleNewPassword} validate={showAllErrorInArray(validationSchema)}>
                {({ errors, values, handleSubmit, handleChange, touched, setFieldTouched }) => (
                    <div className="page-new-password">
                        <img className="page-new-password__logo" src={logoAtf} alt="" />
                        <div className="page-new-password__title">Reset Password</div>
                        <div className="page-new-password__subtitle">Input your new password</div>
                        <div className="page-new-password__input-new-password">
                            <AppInput placeholder="New Password" type="password" onChange={(e) => handleChange("newPassword")(e.target.value)} value={values.newPassword} onFocus={() => setFieldTouched("newPassword")} />
                        </div>
                        <div className="page-new-password__requirement-title">Password must consist of:</div>
                        <div className="page-new-password__contents">
                            <span className={getIconClassNames("ERROR_1", errors.newPassword, values.newPassword, touched.newPassword)}>{errors.newPassword && errors.newPassword.includes("ERROR_1") ? <HiX size={20} /> : <HiCheck size={20} />}</span>
                            <span className="page-new-password__requirement-details">Minimum 8 characters</span>
                        </div>
                        <div className="page-new-password__contents">
                            <span className={getIconClassNames("ERROR_2", errors.newPassword, values.newPassword, touched.newPassword)}>{errors.newPassword && errors.newPassword.includes("ERROR_2") ? <HiX size={20} /> : <HiCheck size={20} />}</span>
                            <span className="page-new-password__requirement-details">At least one letter</span>
                        </div>
                        <div className="page-new-password__contents">
                            <span className={getIconClassNames("ERROR_3", errors.newPassword, values.newPassword, touched.newPassword)}>{errors.newPassword && errors.newPassword.includes("ERROR_3") ? <HiX size={20} /> : <HiCheck size={20} />}</span>
                            <span className="page-new-password__requirement-details">At least one number</span>
                        </div>
                        <div className="page-new-password__input-confirm-password">
                            <AppInput
                                placeholder="Confirm Password"
                                type="password"
                                onChange={(e) => handleChange("confirmPassword")(e.target.value)}
                                value={values.confirmPassword}
                                error={touched.confirmPassword && errors.confirmPassword ? errors.confirmPassword[0] : null}
                                onFocus={() => setFieldTouched("confirmPassword")}
                            />
                        </div>
                        <div className="page-new-password__button-wrapper">
                            <div className="page-new-password__button">
                                <AppButton label={button.login.label} disabled={button.login.disabled} buttonType="outline" size="l" onClick={() => history.push(pathnames.pageLogin)} />
                            </div>
                            <div className="page-new-password__button">
                                <AppButton label={button.submit.label} disabled={button.submit.disabled} size="l" onClick={handleSubmit} />
                            </div>
                        </div>
                    </div>
                )}
            </Formik>
        </AppAuthLayout>
    );
};

export default PageNewPassword;
