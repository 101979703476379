// libraries
import React, { useState } from "react";
// components
import AppToggle from "components/app-toggle";
// assets
import iconDown from "assets/images/icon-arrow-down.svg";

const getAppMegaDropdownArrowClassNames = (arrowRotate) => {
    const classNames = ["app-mega-dropdown__arrow"];
    if (arrowRotate) classNames.push("app-mega-dropdown__arrow--rotate");

    return classNames.join(" ");
};

const getContentsClassNames = (show) => {
    const classNames = ["app-mega-dropdown__contents"];
    if (show) classNames.push("app-mega-dropdown__contents--show");
    if (!show) classNames.push("app-mega-dropdown__contents--hide");
    return classNames.join(" ");
};

const AppMegaDropdown = ({ title, toggleProRatedOnChange, toggleProRatedChecked, toggleOnChange, toggleChecked, children }) => {
    const [megaDropdownIsOpen, setMegaDropdownIsOpen] = useState(true);

    return (
        <div className="app-mega-dropdown">
            <div className="app-mega-dropdown__header" onClick={() => setMegaDropdownIsOpen(!megaDropdownIsOpen)}>
                <div className="app-mega-dropdown__header-left">{title}</div>
                <div className="app-mega-dropdown__header-right">
                    {/* Pro-Rated */}
                    <div className="app-mega-dropdown__toggle-wrapper" onClick={(e) => e.stopPropagation()}>
                        <AppToggle onChange={toggleProRatedOnChange} checked={toggleProRatedChecked} />
                    </div>
                    <div className="app-mega-dropdown__approve-all">Pro-Rated for All</div>
                    {/* Approval */}
                    <div className="app-mega-dropdown__toggle-wrapper" onClick={(e) => e.stopPropagation()}>
                        <AppToggle onChange={toggleOnChange} checked={toggleChecked} />
                    </div>
                    <div className="app-mega-dropdown__approve-all">Approval for All</div>
                    <img src={iconDown} alt="" className={getAppMegaDropdownArrowClassNames(megaDropdownIsOpen)} />
                </div>
            </div>
            <div className={getContentsClassNames(megaDropdownIsOpen)}>{children}</div>
        </div>
    );
};

export default AppMegaDropdown;
