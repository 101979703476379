// libraries
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTabLink from "components/app-tab-link";
import AppReactTable from "components/app-react-table";
import AppDropdownMini from "components/app-dropdown-mini";
import AppButton from "components/app-button";
import AppModal from "components/app-modal";
import AppDropdown from "components/app-dropdown";
import AppTextArea from "components/app-text-area";
import appToast from "components/app-toast";
import AppDualColumnsModal from "components/app-dual-columns-modal";
import AppInputWithLabel from "components/app-input-with-label";
import AppModalConfirmation from "components/app-modal-confirmation";
import AppAttachmentImage from "components/app-attachment-image";
import AppPdfImgViewer from "components/app-pdf-img-viewer";
// service
import api from "services/api";
// hooks
// common
import { sanitizeError } from "common/utilities";
// routes
import pathnames from "routes/pathnames";
// assets
import iconDots from "assets/images/icon-vertical-dots.svg";

const breadcrumb = [
    {
        label: "Claim Management",
    },
    {
        label: "Claim Directory",
        pathname: pathnames.pageClaimDirectory,
    },
    {
        label: "Pending Claim",
    },
];

const rejectDropdownOptions = [
    { label: "Duplicated claim", id: 1 },
    { label: "Not covered by HR Policy", id: 2 },
    { label: "Late submission", id: 3 },
    { label: "Others", id: 4 },
];

const rejectValueInitialState = {
    reason: {
        value: "",
        error: "",
    },
    remark: {
        value: "",
    },
};

const pendingClaimDetailsInitialValues = {
    amount: "",
    approveAmount: "",
    attachment: "",
    claimType: "",
    currentBalance: "",
    desc: "",
    id: "",
    rejectReason: "",
    remark: "",
    staffId: "",
    status: "",
    submitDate: "",
    userName: "",
    fileType: "",
};

const attachmentSrcInitialState = {
    file: "",
    type: "",
};

const PagePendingClaim = () => {
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [rejectValue, setRejectValue] = useState(rejectValueInitialState);
    const { pathStaffId } = useParams();
    const staffId = pathStaffId.replace("value_", "");
    const [tableData, setTableData] = useState([]);
    const [claimDetailsApprovedAmount, setClaimDetailsApprovedAmount] = useState({
        value: null,
        error: "",
    });
    const [confirmModal, setConfirmModal] = useState({
        details: "",
        confirmDisable: false,
    });
    const [workingStatus, setWorkingStatus] = useState({
        staffId: "",
        userName: "",
        workingStatus: "",
    });
    const [pendingClaimDetails, setPendingClaimDetails] = useState(pendingClaimDetailsInitialValues);
    const [singleIdSelected, setSingleIdSelected] = useState();
    const [modalIsOpenSequence, setModalIsOpenSequence] = useState([]);
    const profile = useSelector((state) => state.auth);
    const [attachmentSrc, setAttachmentSrc] = useState(attachmentSrcInitialState);
    const [keyInApprovedAmount, setKeyInApproveAmount] = useState("");

    const appTabLink = [
        {
            label: "Claim Summary",
            pathname: pathnames.pageClaimSummary.replace(":pathStaffId", "value_" + staffId),
        },
        {
            label: "Claim History",
            pathname: pathnames.pageClaimHistory.replace(":pathStaffId", "value_" + staffId),
        },
        {
            label: "Pending Claim",
            pathname: pathnames.pagePendingClaim.replace(":pathStaffId", "value_" + staffId),
        },
    ];

    useEffect(() => {
        const getWorkingStatus = async () => {
            try {
                let payload = ("000" + staffId).slice(-3);
                const response = await api.get.leaveManagementWorkingStatus(payload);
                setWorkingStatus(response.data.result);
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        };
        getWorkingStatus();
    }, [staffId]);

    const getTableData = async (staffId) => {
        try {
            let payload = ("000" + staffId).slice(-3);
            const response = await api.get.claimManagementPendingClaim(payload);
            setTableData(response.data.result.content);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    useEffect(() => {
        getTableData(staffId);
    }, [staffId]);

    const tableDataDropdownOptions = [
        {
            item: "Approve",
            onClick: (item) => {
                setSingleIdSelected(item);
                setModalIsOpenSequence([...modalIsOpenSequence, "MODAL_CONFIRMATION"]);
                setConfirmModal({
                    ...confirmModal,
                    path: "FROM_TABLE",
                    details: "Confirm to approve?",
                });
            },
        },
        {
            item: "Reject",
            onClick: (item) => {
                setSingleIdSelected(item);
                setModalIsOpenSequence([...modalIsOpenSequence, "MODAL_REJECT"]);
            },
        },
    ];

    const tableColumn = [
        {
            id: "claimType",
            Header: (header) => {
                return <span className="react-table__claim-type-header">Claim Type</span>;
            },
            Cell: (row) => {
                return <span className="react-table__claim-type-data">{row.row.original.claimType}</span>;
            },
        },
        {
            id: "submitDate",
            Header: () => {
                return <span>Submit Date</span>;
            },
            Cell: (row) => {
                return <span>{moment(row.row.original.submitDate).format("DD/MM/YYYY")}</span>;
            },
        },
        {
            id: "amount",
            Header: () => {
                return <span>Amount (RM)</span>;
            },
            Cell: (row) => {
                return (
                    <div className="react-table__amount-width">
                        <div className="react-table__amount">
                            <span>{row.row.original.amount.toFixed(2)}</span>
                        </div>
                    </div>
                );
            },
        },
        {
            id: "attachment",
            Header: () => {
                return <span className="react-table__header">Attachment</span>;
            },
            Cell: (row) => {
                const fileSplitted = row.row.original.attachment.split(".");
                const fileType = fileSplitted[fileSplitted.length - 1];
                return (
                    <div className="react-table__attachment" onClick={(e) => e.stopPropagation()}>
                        <AppAttachmentImage
                            fileType={fileType}
                            onClick={() => {
                                setAttachmentSrc({
                                    file: row.row.original.attachment,
                                    type: fileType,
                                });
                                setModalIsOpenSequence(["MODAL_ATTACHMENT"]);
                            }}
                        />
                    </div>
                );
            },
            accessor: "attachment",
            searchFilterValue: "attachment",
        },
        {
            id: "button",
            Cell: (row) => {
                return (
                    <div onClick={(e) => e.stopPropagation()}>
                        <AppDropdownMini
                            page="page-leave-pending-approval"
                            label={<img className="react-table__edit-icon" src={iconDots} alt="" />}
                            toggle={() => {
                                if (dropdownOpen) {
                                    setDropdownOpen(null);
                                } else {
                                    setDropdownOpen(row.row.original.id);
                                }
                            }}
                            isOpen={row.row.original.id === dropdownOpen}
                            dropdownItem={tableDataDropdownOptions}
                            outContentsSelected={dropdownOpen}
                        />
                    </div>
                );
            },
            maxWidth: 50,
        },
    ];

    const rejectReasonConfirmed = () => {
        if (!rejectValue.reason.value) {
            setRejectValue({
                ...rejectValue,
                reason: {
                    ...rejectValue.reason,
                    error: "Reason is required",
                },
            });
        } else {
            setConfirmModal({
                ...confirmModal,
                details: "Confirm to reject?",
            });
            setModalIsOpenSequence([...modalIsOpenSequence, "MODAL_CONFIRMATION"]);
        }
    };

    const tableRowClicked = async (row) => {
        try {
            const response = await api.get.claimManagementPendingClaimDetails(row.id);
            let result = response.data.result;
            const fileSplitted = result.attachment.split(".");
            const fileType = fileSplitted[fileSplitted.length - 1];
            setPendingClaimDetails({ ...result, fileType: fileType });
            setSingleIdSelected(row.id);
            setClaimDetailsApprovedAmount({
                ...claimDetailsApprovedAmount,
                value: Math.min(result.amount, result.currentBalance),
            });
            setKeyInApproveAmount(Math.min(result.amount, result.currentBalance).toString());
            setModalIsOpenSequence(["MODAL_CLAIM_DETAILS"]);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    const claimDetailModalApprove = () => {
        setModalIsOpenSequence([...modalIsOpenSequence, "MODAL_CONFIRMATION"]);
        setConfirmModal({
            ...confirmModal,
            details: "Confirm to approve?",
        });
    };

    const confirmationModalConfirmed = async () => {
        setConfirmModal({
            ...confirmModal,
            confirmDisable: true,
        });
        if (confirmModal.details === "Confirm to approve?") {
            if (modalIsOpenSequence.includes("MODAL_CLAIM_DETAILS")) {
                if (!claimDetailsApprovedAmount.error) {
                    try {
                        let payload = {
                            adminStaffId: profile.staffID,
                            approvedAmount: claimDetailsApprovedAmount.value,
                            claimId: pendingClaimDetails.id,
                        };
                        await api.get.claimManagementApproveClaim(payload);
                        setModalIsOpenSequence([]);
                        setConfirmModal({
                            details: "",
                            confirmDisable: false,
                        });
                        setPendingClaimDetails(pendingClaimDetailsInitialValues);
                        appToast("Claim has been approved successfully", true);
                        getTableData(staffId);
                    } catch (error) {
                        appToast(sanitizeError(error), false);
                        setConfirmModal({
                            ...confirmModal,
                            confirmDisable: false,
                        });
                    }
                }
            } else {
                try {
                    const response = await api.get.claimManagementPendingClaimDetails(singleIdSelected);
                    let billAmount = response.data.result.amount;
                    let balanceAmount = response.data.result.currentBalance;
                    try {
                        let payload = {
                            adminStaffId: profile.staffID,
                            approvedAmount: Math.min(billAmount, balanceAmount),
                            claimId: singleIdSelected,
                        };
                        await api.get.claimManagementApproveClaim(payload);
                        setModalIsOpenSequence([]);
                        setConfirmModal({
                            details: "",
                            confirmDisable: false,
                        });
                        appToast("Claim has been approved successfully", true);
                        getTableData(staffId);
                        setSingleIdSelected(null);
                    } catch (error) {
                        setConfirmModal({
                            ...confirmModal,
                            confirmDisable: false,
                        });
                        appToast(sanitizeError(error), false);
                    }
                } catch (error) {
                    setConfirmModal({
                        ...confirmModal,
                        confirmDisable: false,
                    });
                    appToast(sanitizeError(error), false);
                }
            }
        } else if (confirmModal.details === "Confirm to reject?") {
            if (modalIsOpenSequence.includes("MODAL_REJECT")) {
                try {
                    let payload = {
                        adminStaffId: profile.staffID,
                        claimId: singleIdSelected,
                        rejectReason: rejectValue.reason.value,
                        remarks: rejectValue.remark.value,
                    };
                    await api.get.claimManagementRejectClaim(payload);
                    setModalIsOpenSequence([]);
                    setConfirmModal({
                        details: "",
                        confirmDisable: false,
                    });
                    setRejectValue(rejectValueInitialState);
                    appToast("Claim has been rejected successfully", true);
                    getTableData(staffId);
                    setSingleIdSelected(null);
                } catch (error) {
                    setConfirmModal({
                        ...confirmModal,
                        confirmDisable: false,
                    });
                    appToast(sanitizeError(error), false);
                }
            }
        }
    };

    return (
        <AppPagesLayout>
            <div className="page-pending-claim">
                <AppModal
                    title="Reject Reason"
                    isOpenModal={modalIsOpenSequence.slice(-1)[0] === "MODAL_REJECT"}
                    onRequestClose={() => {
                        setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
                        setRejectValue(rejectValueInitialState);
                    }}>
                    <div className="page-pending-claim__modal">
                        <div className="page-pending-claim__modal-subtitle">The reject reason will apply to all selected leave.</div>
                        <div className="page-pending-claim__modal-input">
                            <AppDropdown
                                placeholder="Reject Reason"
                                dropdownOptions={rejectDropdownOptions}
                                onChange={(selected) => {
                                    setRejectValue({
                                        ...rejectValue,
                                        reason: {
                                            value: selected.label,
                                            error: "",
                                        },
                                    });
                                }}
                                value={rejectValue.reason.value}
                                currentInputValue={rejectValue.reason.value ? rejectDropdownOptions.filter((ele) => ele.label === rejectValue.reason.value)[0] : null}
                                error={rejectValue.reason.error}
                            />
                        </div>
                        <div className="page-pending-claim__modal-input">
                            <AppTextArea
                                placeholder="Remark (Optional)"
                                onChange={(e) => {
                                    setRejectValue({
                                        ...rejectValue,
                                        remark: {
                                            value: e.target.value,
                                        },
                                    });
                                }}
                                value={rejectValue.remark.value}
                            />
                        </div>
                        <div className="page-pending-claim__modal-button-row">
                            <div className="page-pending-claim__modal-button">
                                <AppButton
                                    label="Cancel"
                                    buttonType="outline"
                                    size="l"
                                    onClick={() => {
                                        setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
                                        setRejectValue(rejectValueInitialState);
                                    }}
                                />
                            </div>
                            <div className="page-pending-claim__modal-button">
                                <AppButton label="Confirm" size="l" onClick={() => rejectReasonConfirmed()} />
                            </div>
                        </div>
                    </div>
                </AppModal>
                <AppDualColumnsModal
                    title="Claim Details"
                    isOpenModal={modalIsOpenSequence.slice(-1)[0] === "MODAL_CLAIM_DETAILS"}
                    onRequestClose={() => {
                        setClaimDetailsApprovedAmount({
                            value: "",
                            error: "",
                        });
                        setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
                        setPendingClaimDetails(pendingClaimDetailsInitialValues);
                    }}
                    status="Pending"
                    statusColor="orange"
                    childrenLeft={
                        <div>
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Username" value={pendingClaimDetails.userName} disabled={true} />
                            </div>
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Claim Type" value={pendingClaimDetails.claimType} disabled={true} />
                            </div>
                            {pendingClaimDetails.attachment ? (
                                <div
                                    className="page-pending-claim__modal-input-wrapper page-pending-claim__modal-input-wrapper--hover"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setAttachmentSrc({
                                            file: pendingClaimDetails.attachment,
                                            type: pendingClaimDetails.fileType,
                                        });
                                        setModalIsOpenSequence([...modalIsOpenSequence, "MODAL_ATTACHMENT"]);
                                    }}>
                                    <span className="page-pending-claim__modal-icon-wrapper">
                                        <AppAttachmentImage fileType={pendingClaimDetails.fileType} />
                                    </span>
                                    <AppInputWithLabel
                                        placeholder="Attachment"
                                        value={`        ${pendingClaimDetails.attachment.split("/")[pendingClaimDetails.attachment.split("/").length - 1]}`}
                                        disabled={true}
                                        onClick={() => {
                                            setAttachmentSrc({
                                                file: pendingClaimDetails.attachment,
                                                type: pendingClaimDetails.fileType,
                                            });
                                            setModalIsOpenSequence([...modalIsOpenSequence, "MODAL_ATTACHMENT"]);
                                        }}
                                    />
                                </div>
                            ) : null}
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppTextArea placeholder="Description" value={pendingClaimDetails.desc ? pendingClaimDetails.desc : ""} disabled={true} />
                            </div>
                        </div>
                    }
                    childrenRight={
                        <div>
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Employee ID" value={pendingClaimDetails.staffId} disabled={true} />
                            </div>
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Bill Amount (RM)" value={pendingClaimDetails.amount} disabled={true} />
                            </div>
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel
                                    placeholder="Approved Amount (RM)"
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key) && event.key !== ".") {
                                            event.preventDefault();
                                        }
                                    }}
                                    onChange={(e) => {
                                        setKeyInApproveAmount(e.target.value);
                                        setClaimDetailsApprovedAmount({
                                            ...claimDetailsApprovedAmount,
                                            error: "",
                                        });
                                    }}
                                    onBlur={() => {
                                        const keyInApprovedAmountFloat = parseFloat(parseFloat(keyInApprovedAmount).toFixed(2));
                                        if (!keyInApprovedAmountFloat) {
                                            setKeyInApproveAmount("0");
                                            setClaimDetailsApprovedAmount({
                                                value: 0,
                                                error: "",
                                            });
                                            return;
                                        }
                                        if (keyInApprovedAmountFloat > 10) {
                                            setClaimDetailsApprovedAmount({
                                                value: keyInApprovedAmountFloat,
                                                error: "Amount exceeded balance amount.",
                                            });
                                        } else {
                                            setClaimDetailsApprovedAmount({
                                                value: keyInApprovedAmountFloat,
                                                error: "",
                                            });
                                        }
                                        setKeyInApproveAmount(keyInApprovedAmountFloat.toString());
                                    }}
                                    error={claimDetailsApprovedAmount.error}
                                    value={keyInApprovedAmount}
                                />
                            </div>
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Submit Date" value={pendingClaimDetails.submitDate} disabled={true} />
                            </div>
                        </div>
                    }
                    childrenBottom={
                        <div className="page-pending-claim__button-row">
                            <div className="page-pending-claim__button-wrapper">
                                <AppButton
                                    label="Reject"
                                    buttonType="outline"
                                    size="l"
                                    onClick={() => {
                                        setModalIsOpenSequence([...modalIsOpenSequence, "MODAL_REJECT"]);
                                    }}
                                />
                            </div>
                            <div className="page-pending-claim__button-wrapper">
                                <AppButton label="Approve" size="l" onClick={claimDetailModalApprove} disabled={claimDetailsApprovedAmount.error} />
                            </div>
                        </div>
                    }
                />
                <AppModalConfirmation
                    isOpenModal={modalIsOpenSequence.slice(-1)[0] === "MODAL_CONFIRMATION"}
                    onRequestClose={() => {
                        setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
                        if (confirmModal.path === "FROM_MODAL") {
                            setConfirmModal({
                                details: "",
                                confirmDisable: false,
                            });
                        } else if (confirmModal.path === "FROM_REJECT") {
                            setConfirmModal({
                                details: "",
                                confirmDisable: false,
                            });
                        } else {
                            setConfirmModal({
                                details: "",
                                confirmDisable: false,
                            });
                        }
                    }}
                    details={confirmModal.details}
                    onClick={() => confirmationModalConfirmed()}
                    buttonLabel="Confirm"
                    buttonDisabled={confirmModal.confirmDisable}
                />
                <AppPdfImgViewer
                    isOpenModal={modalIsOpenSequence.slice(-1)[0] === "MODAL_ATTACHMENT"}
                    onRequestClose={() => {
                        setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
                        setTimeout(() => {
                            setAttachmentSrc(attachmentSrcInitialState);
                        }, [250]);
                    }}
                    attachmentSrc={attachmentSrc}
                />

                <div className="page-pending-claim__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>
                <div className="page-pending-claim__user-info">
                    <div className="page-pending-claim__user-info--left">
                        <span>{workingStatus.userName}</span>
                        <span className="page-pending-claim__user-id">{workingStatus.staffId}</span>
                    </div>
                </div>

                <div className="page-pending-claim__tab-wrapper">
                    <AppTabLink tabList={appTabLink} />
                </div>
                <div className="page-pending-claim__table-wrapper">
                    <AppReactTable columns={tableColumn} data={tableData} onClickRow={tableRowClicked} />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PagePendingClaim;
