import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createLogger } from "redux-logger";
import { isDevelopmentMode } from "common/utilities";
import authReducer from "redux/slices/auth-slice";

const combinedReducers = combineReducers({ auth: authReducer });
const rootReducer = (state, action) => {
    if (action.type === "logout") {
        state = undefined
    }
    return combinedReducers(state, action)
}

const store = configureStore({
    reducer: persistReducer({ key: "root", storage }, rootReducer),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat([isDevelopmentMode() && createLogger({ collapse: true })].filter(Boolean)),
});

export const persistor = persistStore(store);

export default store;
