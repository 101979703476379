// libraries
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTabLink from "components/app-tab-link";
import AppReactTable from "components/app-react-table";
import AppCheckbox from "components/app-checkbox";
import AppDropdownMini from "components/app-dropdown-mini";
import AppButton from "components/app-button";
import AppModal from "components/app-modal";
import AppDropdown from "components/app-dropdown";
import AppTextArea from "components/app-text-area";
import appToast from "components/app-toast";
import AppDualColumnsModal from "components/app-dual-columns-modal";
import AppInputWithLabel from "components/app-input-with-label";
import AppModalConfirmation from "components/app-modal-confirmation";
// service
import api from "services/api";
// common
import { sanitizeError } from "common/utilities";
// routes
import pathnames from "routes/pathnames";
// assets
import iconDots from "assets/images/icon-vertical-dots.svg";

const breadcrumb = [
    {
        label: "Leave Management",
    },
    {
        label: "Leave Directory",
        pathname: pathnames.pageLeaveDirectory,
    },
    {
        label: "Pending Leave",
    },
];

const getStatusClassNames = (status) => {
    const classNames = ["page-pending-leave__user-status"];
    if (status.toLowerCase() === "working") classNames.push("page-pending-leave__user-status--green");
    if (status.toLowerCase() === "on leave") classNames.push("page-pending-leave__user-status--red");

    return classNames.join(" ");
};

const rejectDropdownOptions = [{ label: "Others", id: 1 }];

const PagePendingLeave = () => {
    const [idRowSelected, setIdRowSelected] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [rejectModal, setRejectModal] = useState(false);
    const [rejectValue, setRejectValue] = useState({
        from: "",
        reason: {
            value: "",
            error: "",
        },
        remark: {
            value: "",
        },
    });
    const { pathStaffId } = useParams();
    const staffId = pathStaffId.replace("value_", "");
    const [tableData, setTableData] = useState([]);
    const [allId, setAllId] = useState([]);
    const [leaveDetails, setLeaveDetails] = useState(null);
    const [largeModalIsOpen, setLargeModalIsOpen] = useState(false);
    const [confirmModal, setConfirmModal] = useState({
        isOpen: false,
        details: "",
        confirmDisable: false,
        leaveStatus: "",
        removeAmount: "",
    });
    const [singleIdSelected, setSingleIdSelected] = useState();
    const [workingStatus, setWorkingStatus] = useState({
        staffId: "",
        userName: "",
        workingStatus: "",
    });
    const [leaveDetailsConfirmModal, setLeaveDetailsConfirmModal] = useState({
        isOpen: false,
        details: "",
        leaveStatus: "",
        confirmDisable: false,
    });

    const appTabLink = [
        {
            label: "Leave Summary",
            pathname: pathnames.pageLeaveSummary.replace(":pathStaffId", "value_" + staffId),
        },
        {
            label: "Leave History",
            pathname: pathnames.pageLeaveHistory.replace(":pathStaffId", "value_" + staffId),
        },
        {
            label: "Pending Leave",
            pathname: pathnames.pagePendingLeave.replace(":pathStaffId", "value_" + staffId),
        },
    ];

    const tableDataDropdownOptions = [
        {
            item: "Approve",
            onClick: (item) => {
                setSingleIdSelected(item);
                setConfirmModal({
                    isOpen: true,
                    details: "Confirm to approve?",
                    leaveStatus: "approve",
                    removeAmount: "single",
                });
            },
        },
        {
            item: "Reject",
            onClick: (item) => {
                setSingleIdSelected(item);
                setConfirmModal({
                    ...confirmModal,
                    removeAmount: "single",
                });
                setRejectModal(true);
                setRejectValue({
                    ...rejectValue,
                    from: "dropdown",
                });
            },
        },
    ];

    const getTableData = async (staffId) => {
        try {
            const response = await api.get.leaveManagementPendingLeave(staffId);
            setTableData(response.data.result);
            setAllId(response.data.result.map((e) => e.id));
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    useEffect(() => {
        const getWorkingStatus = async () => {
            try {
                let payload = staffId;
                const response = await api.get.leaveManagementWorkingStatus(payload);
                setWorkingStatus(response.data.result);
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        };
        getWorkingStatus();
        getTableData(staffId);
    }, [staffId]);

    const selectAllFunction = (allId) => {
        if (_.isEqual(idRowSelected, allId)) {
            setIdRowSelected([]);
        } else {
            setIdRowSelected(allId);
        }
    };

    const selectRowFunction = (rowData) => {
        if (idRowSelected.includes(rowData.id)) {
            setIdRowSelected([...idRowSelected.filter((ele) => ele !== rowData.id)]);
        } else {
            const sortedIdRowSelected = [...idRowSelected, rowData.id];
            setIdRowSelected(sortedIdRowSelected.sort());
        }
    };

    const tableColumn = [
        {
            id: "Id",
            Header: (header) => {
                if (header.data.length) {
                    return (
                        <div className="react-table__checkbox-wrapper">
                            <AppCheckbox type="selectAll" onChange={() => selectAllFunction(allId)} checked={_.isEqual(idRowSelected, allId)} />
                        </div>
                    );
                } else {
                    return null;
                }
            },
            Cell: (row) => {
                return (
                    <div className="react-table__checkbox-wrapper" onClick={(e) => e.stopPropagation()}>
                        <AppCheckbox onChange={() => selectRowFunction(row.row.original)} checked={idRowSelected.includes(row.row.original.id)} />
                    </div>
                );
            },
            maxWidth: 50,
        },
        {
            id: "leaveType",
            Header: () => {
                return <span className="react-table__leave-type-header">Leave Type</span>;
            },
            Cell: (row) => {
                return <span className="react-table__leave-type-data">{row.row.original.leaveType}</span>;
            },
        },
        {
            id: "leaveDays",
            Header: () => {
                return <span>Days</span>;
            },
            Cell: (row) => {
                return <span className="react-table__days-data">{row.row.original.leaveDays}</span>;
            },
        },
        {
            id: "startDate",
            Header: () => {
                return <span>Start Date</span>;
            },
            accessor: "startDate",
        },
        {
            id: "endDate",
            Header: () => {
                return <span>End Date</span>;
            },
            accessor: "endDate",
        },
        {
            id: "timeOff",
            Header: () => {
                return <span>Time Off</span>;
            },
            accessor: "timeOff",
        },
        {
            id: "button",
            Cell: (row) => {
                return (
                    <div onClick={(e) => e.stopPropagation()}>
                        <AppDropdownMini
                            page="page-leave-pending-approval"
                            label={<img className="react-table__edit-icon" src={iconDots} alt="" />}
                            toggle={() => {
                                if (dropdownOpen) {
                                    setDropdownOpen(null);
                                } else {
                                    setDropdownOpen(row.row.original.id);
                                }
                            }}
                            isOpen={row.row.original.id === dropdownOpen}
                            dropdownItem={tableDataDropdownOptions}
                            outContentsSelected={dropdownOpen}
                        />
                    </div>
                );
            },
            maxWidth: 50,
        },
    ];

    const rejectReasonConfirmed = () => {
        if (!rejectValue.reason.value) {
            setRejectValue({
                ...rejectValue,
                reason: {
                    ...rejectValue.reason,
                    error: "Reason is required",
                },
            });
        } else {
            if (rejectValue.from === "dropdown") {
                setRejectModal(false);
                setConfirmModal({
                    ...confirmModal,
                    isOpen: true,
                    details: "Confirm to reject?",
                    leaveStatus: "reject",
                });
            } else if (rejectValue.from === "modal") {
                setRejectModal(false);
                setLeaveDetailsConfirmModal({
                    ...leaveDetailsConfirmModal,
                    isOpen: true,
                    details: "Confirm to reject?",
                    leaveStatus: "reject",
                });
            }
        }
    };

    const tableRowClicked = async (item) => {
        try {
            const response = await api.get.leaveManagementLeaveHistoryDetail(item.id);
            setLeaveDetails(response.data.result);
            setLargeModalIsOpen(true);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    const confirmationModalConfirmed = async () => {
        if (confirmModal.leaveStatus === "approve") {
            setConfirmModal({
                ...confirmModal,
                confirmDisable: true,
            });
            try {
                const payload = {
                    adminStaffId: staffId,
                    leaveDetailId: confirmModal.removeAmount === "multiple" ? idRowSelected : [singleIdSelected],
                    leaveStatus: "Approve",
                    rejectReason: "",
                    remark: "",
                };
                await api.post.leaveManagementUpdateLeave(payload);
                setConfirmModal({
                    isOpen: false,
                    details: "",
                    confirmDisable: false,
                    leaveStatus: "",
                    removeAmount: "",
                });
                setIdRowSelected([]);
                appToast("Leave has been approved successfully.", true);
                getTableData(staffId);
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
                setConfirmModal({
                    ...confirmModal,
                    confirmDisable: false,
                });
            }
        } else if (confirmModal.leaveStatus === "reject") {
            setConfirmModal({
                ...confirmModal,
                confirmDisable: true,
            });
            try {
                const payload = {
                    adminStaffId: staffId,
                    leaveDetailId: confirmModal.removeAmount === "multiple" ? idRowSelected : [singleIdSelected],
                    leaveStatus: "Reject",
                    rejectReason: rejectValue.reason.value,
                    remark: rejectValue.remark.value,
                };
                await api.post.leaveManagementUpdateLeave(payload);
                setConfirmModal({
                    isOpen: false,
                    details: "",
                    confirmDisable: false,
                    leaveStatus: "",
                    removeAmount: "",
                });
                setRejectModal(false);
                appToast("Leave has been rejected successfully.", true);
                setIdRowSelected([]);
                getTableData(staffId);
                setRejectValue({
                    from: "",
                    reason: {
                        value: "",
                        error: "",
                    },
                    remark: {
                        value: "",
                    },
                });
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
                setConfirmModal({
                    ...confirmModal,
                    confirmDisable: false,
                });
            }
        }
    };

    const leaveDetailsConfirmationModalConfirmed = async () => {
        if (leaveDetails.id) {
            setLeaveDetailsConfirmModal({
                ...leaveDetailsConfirmModal,
                confirmDisable: true,
            });
            if (leaveDetailsConfirmModal.leaveStatus === "approve") {
                try {
                    const payload = {
                        adminStaffId: staffId,
                        leaveDetailId: [leaveDetails.id],
                        leaveStatus: "Approve",
                        rejectReason: "",
                        remark: "",
                    };
                    await api.post.leaveManagementUpdateLeave(payload);
                    setLeaveDetailsConfirmModal({
                        isOpen: false,
                        details: "",
                        leaveStatus: "",
                        confirmDisable: false,
                    });
                    appToast("Leave has been approved successfully.", true);
                    getTableData(staffId);
                } catch (error) {
                    let sanitizedError = sanitizeError(error);
                    appToast(sanitizedError, false);
                    setLeaveDetailsConfirmModal({
                        ...leaveDetailsConfirmModal,
                        confirmDisable: false,
                    });
                }
            } else if (leaveDetailsConfirmModal.leaveStatus === "reject") {
                try {
                    const payload = {
                        adminStaffId: staffId,
                        leaveDetailId: [leaveDetails.id],
                        leaveStatus: "Reject",
                        rejectReason: rejectValue.reason.value,
                        remark: rejectValue.remark.value,
                    };
                    await api.post.leaveManagementUpdateLeave(payload);
                    setLeaveDetailsConfirmModal({
                        isOpen: false,
                        details: "",
                        leaveStatus: "",
                        confirmDisable: false,
                    });
                    setRejectModal(false);
                    appToast("Leave has been rejected successfully.", true);
                    getTableData(staffId);
                    setRejectValue({
                        from: "",
                        reason: {
                            value: "",
                            error: "",
                        },
                        remark: {
                            value: "",
                        },
                    });
                } catch (error) {
                    let sanitizedError = sanitizeError(error);
                    appToast(sanitizedError, false);
                    setLeaveDetailsConfirmModal({
                        ...leaveDetailsConfirmModal,
                        confirmDisable: false,
                    });
                }
            }
        }
    };

    const rejectLeaveHandler = () => {
        setLargeModalIsOpen(false);
        setRejectModal(true);
        setRejectValue({
            ...rejectValue,
            from: "modal",
        });
    };

    const approveLeaveHandler = () => {
        if (leaveDetails.id) {
            setLargeModalIsOpen(false);
            setLeaveDetailsConfirmModal({
                isOpen: true,
                details: "Confirm to approve?",
                leaveStatus: "approve",
                confirmDisable: false,
            });
        }
    };

    return (
        <AppPagesLayout>
            <div className="page-pending-leave">
                {leaveDetails ? (
                    <AppDualColumnsModal
                        title="Leave Details"
                        isOpenModal={largeModalIsOpen}
                        onRequestClose={() => setLargeModalIsOpen(false)}
                        status={leaveDetails.status}
                        statusColor="orange"
                        childrenLeft={
                            <div>
                                <div className="page-pending-leave__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Username" value={leaveDetails.userName} disabled={true} />
                                </div>
                                <div className="page-pending-leave__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Leave Type" value={leaveDetails.leaveType} disabled={true} />
                                </div>
                                <div className="page-pending-leave__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Start Date" value={leaveDetails.startDate} disabled={true} />
                                </div>
                                <div className="page-pending-leave__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Submit Date" value={leaveDetails.submitDate} disabled={true} />
                                </div>
                            </div>
                        }
                        childrenRight={
                            <div>
                                <div className="page-pending-leave__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Employee ID" value={leaveDetails.staffID} disabled={true} />
                                </div>
                                <div className="page-pending-leave__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="Time Off" value={leaveDetails.timeOff} disabled={true} />
                                </div>
                                <div className="page-pending-leave__modal-input-wrapper">
                                    <AppInputWithLabel placeholder="End Date" value={leaveDetails.endDate} disabled={true} />
                                </div>
                                {leaveDetails.rejectReason ? (
                                    <div className="page-pending-leave__modal-input-wrapper">
                                        <AppInputWithLabel placeholder="Reject Reason" value={leaveDetails.rejectReason} disabled={true} />
                                    </div>
                                ) : null}
                                {leaveDetails.remark ? (
                                    <div className="page-pending-leave__modal-input-wrapper">
                                        <AppTextArea placeholder="Remark" value={leaveDetails.remark} disabled={true} />
                                    </div>
                                ) : null}
                            </div>
                        }
                        childrenBottom={
                            <div className="page-pending-leave__modal-button-row">
                                <div className="page-pending-leave__modal-input-button-row">
                                    <AppButton label="Reject" buttonType="outline" onClick={rejectLeaveHandler} />
                                </div>
                                <div className="page-pending-leave__modal-input-button-row">
                                    <AppButton label="Approve" onClick={approveLeaveHandler} />
                                </div>
                            </div>
                        }
                    />
                ) : null}
                <AppModal
                    title="Reject Reason"
                    isOpenModal={rejectModal}
                    onRequestClose={() => {
                        setRejectValue({
                            from: "",
                            reason: {
                                value: "",
                                error: "",
                            },
                            remark: {
                                value: "",
                            },
                        });
                        setRejectModal(false);
                    }}>
                    <div className="page-pending-leave__modal">
                        <div className="page-pending-leave__modal-subtitle">The reject reason will apply to all selected leave.</div>
                        <div className="page-pending-leave__modal-input">
                            <AppDropdown
                                placeholder="Reject Reason"
                                dropdownOptions={rejectDropdownOptions}
                                onChange={(selected) => {
                                    setRejectValue({
                                        ...rejectValue,
                                        reason: {
                                            value: selected.label,
                                            error: "",
                                        },
                                    });
                                }}
                                value={rejectValue.reason.value}
                                currentInputValue={rejectValue.reason.value ? rejectDropdownOptions.filter((ele) => ele.label === rejectValue.reason.value)[0] : null}
                                error={rejectValue.reason.error}
                            />
                        </div>
                        <div className="page-pending-leave__modal-input">
                            <AppTextArea
                                placeholder="Remark (Optional)"
                                onChange={(e) => {
                                    setRejectValue({
                                        ...rejectValue,
                                        remark: {
                                            value: e.target.value,
                                        },
                                    });
                                }}
                                value={rejectValue.remark.value}
                            />
                        </div>
                        <div className="page-pending-leave__modal-button-row">
                            <div className="page-pending-leave__modal-button">
                                <AppButton
                                    label="Cancel"
                                    buttonType="outline"
                                    size="l"
                                    onClick={() => {
                                        if (rejectValue.from === "modal") {
                                            setLargeModalIsOpen(true);
                                        }
                                        setRejectValue({
                                            from: "",
                                            reason: {
                                                value: "",
                                                error: "",
                                            },
                                            remark: {
                                                value: "",
                                            },
                                        });
                                        setRejectModal(false);
                                    }}
                                />
                            </div>
                            <div className="page-pending-leave__modal-button">
                                <AppButton label="Confirm" size="l" onClick={() => rejectReasonConfirmed()} />
                            </div>
                        </div>
                    </div>
                </AppModal>

                <div className="page-pending-leave__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>
                <div className="page-pending-leave__user-info">
                    <div className="page-pending-leave__user-info--left">
                        <span>{workingStatus.userName}</span>
                        <span className="page-pending-leave__user-id">{workingStatus.staffId}</span>
                    </div>
                    <span className={getStatusClassNames("working")}>
                        <span>{workingStatus.workingStatus}</span>
                    </span>
                </div>
                <AppModalConfirmation
                    isOpenModal={confirmModal.isOpen}
                    onRequestClose={() => {
                        console.log("fuck_1")
                        setConfirmModal({
                            isOpen: false,
                            details: "",
                            confirmDisable: false,
                            leaveStatus: "",
                            removeAmount: "",
                        });
                        if (confirmModal.leaveStatus === "reject") {
                            setRejectModal(true);
                        }
                    }}
                    details={confirmModal.details}
                    onClick={confirmationModalConfirmed}
                    buttonDisabled={confirmModal.confirmDisable}
                    buttonLabel="Confirm"
                />
                <AppModalConfirmation
                    isOpenModal={leaveDetailsConfirmModal.isOpen}
                    onRequestClose={() => {
                        if (leaveDetailsConfirmModal.leaveStatus === "approve") {
                            setLargeModalIsOpen(true);
                        } else if (leaveDetailsConfirmModal.leaveStatus === "reject") {
                            setRejectModal(true);
                        }
                        setLeaveDetailsConfirmModal({
                            isOpen: false,
                            details: "",
                            leaveStatus: "",
                            confirmDisable: false,
                        });
                    }}
                    details={leaveDetailsConfirmModal.details}
                    onClick={leaveDetailsConfirmationModalConfirmed}
                    buttonDisabled={leaveDetailsConfirmModal.confirmDisable}
                    buttonLabel="Confirm"
                />
                <div className="page-pending-leave__tab-wrapper">
                    <AppTabLink tabList={appTabLink} />
                </div>
                <div className="page-pending-leave__table-wrapper">
                    <AppReactTable
                        headerSelectAllChildren={
                            <div className="page-pending-leave__table-header-row">
                                <div className="page-pending-leave__table-header-button-wrapper">
                                    <AppButton
                                        label="Approve Leave"
                                        size="s"
                                        onClick={() => {
                                            setConfirmModal({
                                                ...confirmModal,
                                                isOpen: true,
                                                details: "Confirm to approve?",
                                                leaveStatus: "approve",
                                                removeAmount: "multiple",
                                            });
                                        }}
                                    />
                                </div>
                                <div className="page-pending-leave__table-header-button-wrapper">
                                    <AppButton
                                        label="Reject Leave"
                                        buttonType="outline"
                                        size="s"
                                        onClick={() => {
                                            setConfirmModal({
                                                ...confirmModal,
                                                removeAmount: "multiple",
                                            });
                                            setRejectModal(true);
                                        }}
                                    />
                                </div>
                            </div>
                        }
                        columns={tableColumn}
                        data={tableData}
                        idSelectAll={idRowSelected.length}
                        totalItems={allId.length}
                        onClickRow={tableRowClicked}
                    />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PagePendingLeave;
