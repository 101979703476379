const ERRORS = {
    REQUIRED: "This field is required",
    PASSWORD_N_MATCH: "Password does not match",
    EMAIL: "Please enter a valid email",
    WORK_EMAIL: "Invalid work email",
    DATE: "Invalid date",
    IC: "Invalid IC number",
    ONLY9CHAR: "Only 9 characters are allowed",
    PASSPORT: "Invalid passport number",
    AMOUNT: "Invalid amount",
    MOBILE: "Only numeric are allowed",
    PERSONAL_EMAIL: "Invalid personal email"
};

export default ERRORS;