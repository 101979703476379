// libraries
import React from "react";
import { useLocation, useHistory } from "react-router-dom";
// components
import AppDropdownSolid from "components/app-dropdown-solid";

const getTabItemClassNames = (isActiveTab) => {
    const classNames = ["app-tab-link__item"];
    if (isActiveTab) classNames.push("app-tab-link__active-tab");

    return classNames.join(" ");
};

const AppTabLink = ({ tabList }) => {
    const location = useLocation();
    const history = useHistory();
    const currentUrlPrefix = location.pathname.split("value_")[0].split(":")[0];
    return (
        <div className="app-tab-link">
            <div className="app-tab-link--screen-small">
                <AppDropdownSolid
                    list={tabList?.map((o) => ({
                        label: o.label || "",
                        onClick: () => history.push(o.pathname),
                        details: o.pathname,
                    }))}
                    defaultValue={tabList.find((i) => i.pathname.includes(currentUrlPrefix)).label}
                />
            </div>
            <div className="app-tab-link--screen-large">
                <div className="app-tab-link__tab-wrapper">
                    {tabList.length &&
                        tabList.map((item, index) => {
                            const isActiveTab = item.pathname ? currentUrlPrefix === item.pathname.split(":")[0].split("value_")[0] : null;
                            const onTabSelect = () => {
                                if (isActiveTab) {
                                    history.go(0);
                                } else if (item.pathname) {
                                    history.push(item.pathname);
                                }
                            };
                            return (
                                <div onClick={() => onTabSelect()} key={index} className={getTabItemClassNames(isActiveTab)}>
                                    <span>{item.label}</span>
                                </div>
                            );
                        })}
                </div>
                <div className="app-tab-link--border" />
            </div>
        </div>
    );
};

export default AppTabLink;
