import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import moment from "moment";
import * as yup from "yup";

import ERRORS from "common/errors";
import CONSTANT from "common/constant";
import { sanitizeError, formatNRIC } from "common/utilities";

import api from "services/api";
import { updateProfile } from "redux/slices/auth-slice";
import employeePathnames from "routes/employee-pathnames";

import appToast from "components/app-toast";
import AppStatus from "components/app-status";
import AppButton from "components/app-button";
import AppDropdown from "components/app-dropdown";
import AppTextArea from "components/app-text-area";
import AppInputDate from "components/app-input-date";
import AppBreadcrumb from "components/app-breadcrumb";
import AppImageUpload from "components/app-image-upload";
import AppPagesLayout from "components/app-pages-layout";
import AppInputWithLabel from "components/app-input-with-label";
import AppModalConfirmation from "components/app-modal-confirmation";

const currentDate = moment();

const breadcrumb = [
    {
        label: "Profile & Settings",
    },
];

const employeeInformationFields = [
    { name: "joinedDate", placeholder: "Joined Date", type: "date" },
    { name: "probationEnd", placeholder: "Probation End Date", type: "date" },
    { name: "positionLevelId", placeholder: "Level", type: "dropdown" },
    { name: "position", placeholder: "Position", type: "dropdown" },
    { name: "contractType", placeholder: "Contract Type", type: "dropdown" },
    { name: "reportingManager", placeholder: "Reporting Manager", type: "dropdown" },
];

const employeePersonalFields = [
    { name: "staffID", placeholder: "Employee ID", disabled: true },
    { name: "name", placeholder: "Full Name" },
    { name: "userName", placeholder: "Username", disabled: true },
    { name: "typeOfId", placeholder: "Identification Type", type: "dropdown" },
    { name: "nric", placeholder: "IC No.", type: "ic" },
    { name: "passport", placeholder: "Passport No." },
    { name: "dob", placeholder: "Date of Birth", type: "date" },
    { name: "phoneNumber", placeholder: "Mobile Number", type: "mobile" },
    { name: "email", placeholder: "Work Email", disabled: true },
    { name: "personalEmail", placeholder: "Personal Email" },
    { name: "address", placeholder: "Address", type: "textarea" },
];

const validationSchema = yup.object().shape({
    position: yup.string().required(ERRORS.REQUIRED),
    positionLevelId: yup.string().required(ERRORS.REQUIRED),
    contractType: yup.string().required(ERRORS.REQUIRED),
    name: yup.string().required(ERRORS.REQUIRED),
    typeOfId: yup.string().required(ERRORS.REQUIRED),
    nric: yup.string().when(["typeOfId"], (typeOfId) => {
        if (typeOfId && typeOfId.toLowerCase() === "ic no.") {
            return yup
                .string()
                .required(ERRORS.REQUIRED)
                .test("", ERRORS.IC, (value) => {
                    let valueWithoutDash = value && value.split("-").join("");

                    if (value && valueWithoutDash.length >= 8 && valueWithoutDash.length <= 12) {
                        let date = value.substring(0, 6);
                        let dateChecking = moment(date, "YYMMDD");
                        let location = valueWithoutDash.substring(6, 8);
                        let locationChecking = CONSTANT.STATE_NUMBER.includes(location);

                        if (!dateChecking._isValid || !locationChecking) {
                            return false;
                        } else {
                            return true;
                        }
                    } else {
                        return true;
                    }
                });
        }
    }),
    passport: yup.string().when("typeOfId", {
        is: "passport",
        then: () =>
            yup
                .string()
                .required(ERRORS.REQUIRED)
                .test("passport", ERRORS.ONLY9CHAR, (value) => value && value.toString().length === 9)
                .matches(/^[a-z0-9]+$/i, ERRORS.PASSPORT),
    }),
    dob: yup
        .string()
        .required(ERRORS.REQUIRED)
        .test("", ERRORS.DATE, (value) => {
            if (value) {
                let valueWithoutSpaces = value.replace(/\s/g, "");
                return "Invaliddate" !== valueWithoutSpaces;
            }
        })
        .nullable(true),
});

const typeOfIdDropdownOption = [
    { label: "IC No.", value: "ic no." },
    { label: "Passport No.", value: "passport" },
];

const PageEmployeeProfileAndSettings = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.auth);
    const [employeeInfo, setEmployeeInfo] = useState({});
    const [pageDataValues, setPageDataValues] = useState({});
    const [profileImgUrl, setProfileImgUrl] = useState(null);
    const [staffPositionList, setStaffPositionList] = useState([]);
    const [positionLevel, setPositionLevelList] = useState([]);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const initialValues = useMemo(() => {
        let value = {
            image: "",
            joinedDate: "",
            probationEnd: "",
            promotionDate: "",
            lastEmpDate: "",
            position: "",
            positionLevelId: "",
            contractType: "",
            reportingManager: "",
            staffID: "",
            name: "",
            userName: "",
            nric: "",
            dob: "",
            phoneNumber: "",
            email: "",
            personalEmail: "",
            address: "",
            status: "",
            passport: "",
            typeOfId: "",
            role: "",
        };

        if (employeeInfo) {
            let value = {
                image: employeeInfo.image, // in multipart format
                joinedDate: employeeInfo.joinedDate,
                probationEnd: employeeInfo.probationEnd,
                promotionDate: employeeInfo.promotionDate,
                lastEmpDate: employeeInfo.lastEmpDate,
                position: employeeInfo.staffPosition,
                positionLevelId: positionLevel.find((o)=> o.id === employeeInfo.staffPositionLevelId)?.level,
                contractType: employeeInfo.contractType,
                reportingManager: employeeInfo.reportingManager,
                staffID: employeeInfo.staffID,
                name: employeeInfo.staffName,
                userName: employeeInfo.staffUserName,
                nric: employeeInfo.staffNRIC,
                dob: employeeInfo.staffDOB,
                phoneNumber: employeeInfo.staffPhoneNumber,
                email: employeeInfo.staffEmail,
                personalEmail: employeeInfo.staffPersonalEmail,
                address: employeeInfo.staffAddress,
                status: employeeInfo.status,
                passport: employeeInfo.staffNRIC,
                typeOfId: employeeInfo.typeOfId?.toLowerCase(),
                role: employeeInfo.staffRole?.toLowerCase(),
            };
            return value;
        }
        return value;
    }, [employeeInfo, positionLevel]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            setConfirmModalOpen(true);
            setPageDataValues(values);
        },
    });

    const onHandleChange = (name, value) => {
        formik.setFieldTouched(name);
        formik.setFieldValue(name, value);
    };

    const updateConfirmed = async (pageDataValues) => {
        const { image, ...dataExcludeImg } = pageDataValues;
        let inputData;
        let { passport, nric, ...dataExcludeIdentification } = dataExcludeImg;
        const typeOfId = dataExcludeImg.typeOfId?.toLowerCase();

        switch (typeOfId) {
            case "ic no.":
                inputData = {
                    ...dataExcludeIdentification,
                    nricOrPassport: nric,
                };
                break;
            case "passport":
                inputData = {
                    ...dataExcludeIdentification,
                    nricOrPassport: passport,
                };
                break;
            default:
                break;
        }

        const positionId = staffPositionList.filter((o) => o.value === inputData.position)[0]?.id;

        if (positionId) {
            inputData.position = positionId;
        }
        
        const positionLevelId = employeeInfo.staffPositionLevelId;

        if (positionId) {
            inputData.positionLevelId = positionLevelId;
        }

        try {
            const formData = new FormData();
            const dataBlob = new Blob([JSON.stringify(inputData)], { type: "application/json" });
            formData.append("data", dataBlob);
            formData.append("image", new Blob([""]));

            const response = await api.post.updateStaffDetails(formData);
            const result = response.data.result;

            if (result.staffID === profile.staffID) {
                dispatch(
                    updateProfile({
                        ...profile,
                        staffName: result.staffName,
                        staffUserName: result.staffUserName,
                    })
                );
            }

            appToast("Profile details has been updated successfully.", true, 300);
            history.push(employeePathnames.pageEmployeeDashboard);
        } catch (error) {
            const sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    const onHandleTypeOfId = (name, selected) => {
        onHandleChange(name, selected.value);
        formik.setFieldValue("nric", "");
        formik.setFieldValue("passport", "");
        formik.setFieldValue("dob", "");
    };

    const onHandleIcBlur = (name, values) => {
        let removedDashValue = values.nric.split("-").join("");

        if (removedDashValue.length >= 12) {
            let withDashesValue = removedDashValue.substring(0, 6) + "-" + removedDashValue.substring(6, 8) + "-" + removedDashValue.substring(8);
            formik.setFieldValue(name, withDashesValue);
            let year;
            let currentYear = currentDate.format("YY");

            if (parseInt(withDashesValue.substring(0, 2)) < parseInt(currentYear)) {
                year = `20${withDashesValue.substring(0, 2)}`;
            } else {
                year = `19${withDashesValue.substring(0, 2)}`;
            }

            let date = `${withDashesValue.substring(4, 6)}/${withDashesValue.substring(2, 4)}/${year}`;
            formik.setFieldValue("dob", date);

        } else {
            formik.setFieldValue("dob", "");
        }
    };
    const onHandleMobileBlur = (name, values) => {
        let removedDashValue = values.phoneNumber.split("-").join("");

        if (removedDashValue >= 10) {
            let withDashValue = removedDashValue.substring(0, 3) + "-" + removedDashValue.substring(3);
            formik.setFieldValue(name, withDashValue);
        }
    };

    useEffect(() => {
        const onHandleGetProfile = async () => {
            try {
                let payload = {
                    staffId: profile.staffID,
                };

                const response = await api.get.getUsernameDetails(payload);
                const staffPosition = await api.get.getStaffNamePosition();
                const positionLevel = await api.get.getPositionLevel();
                const result = response.data.result[0];

                setStaffPositionList(staffPosition.data.result.positionList);
                setPositionLevelList(positionLevel.data.result);
                setEmployeeInfo(result);
                setProfileImgUrl(result?.image);
                
            } catch (error) {
                const errorMessage = sanitizeError(error);
                appToast(errorMessage, false, 300);
                history.push(employeePathnames.pageEmployeeDashboard);
            }
        };
        onHandleGetProfile();
    }, [history, profile]);

    const onHandleRemoveImage = async () => {
        setProfileImgUrl(null);
        
        if (profileImgUrl) {
            try {
                let staffId = profile.staffID;
                const response = await api.delete.userManagementDeleteProfileImage(staffId);
                if (response) appToast("Employee profile has been deleted successfully.", true, 300);
            } catch (error) {
                const sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        }
    };

    return (
        <AppPagesLayout>
            <div className="page-employee">
                <AppModalConfirmation isOpenModal={confirmModalOpen} onRequestClose={() => setConfirmModalOpen(false)} details={"Confirm to save?"} onClick={() => updateConfirmed(pageDataValues)} buttonLabel={"Save"} />
                <div className="page-employee__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>
                <div className="page-employee__content">
                    <div className="page-employee__left">
                        <div className="page-employee__left-content">
                            <div className="page-employee__image-upload-wrapper">
                                <AppImageUpload onChange={(value) => onHandleChange("image", value[0])} value={profileImgUrl} />
                            </div>

                            {profileImgUrl && (
                                <div className="page-employee__remove-image" onClick={onHandleRemoveImage}>
                                    Remove profile photo
                                </div>
                            )}

                            <div className="page-employee__title">Employment Information</div>

                            {employeeInformationFields.map((item, index) => {
                                return (
                                    <div className="page-employee__input-wrapper" key={index}>
                                        <AppInputWithLabel disabled={true} placeholder={item.placeholder} value={formik.values[item.name] ? formik.values[item.name] : ""} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="page-employee__right">
                        <div className="page-employee__right-content">
                            <div className="page-employee__app-status-wrapper">
                                <AppStatus label={formik.values.status} />
                            </div>
                            <div className="page-employee__title">Personal Information</div>

                            {employeePersonalFields.map((item, index) => {
                                const name = item.name;
                                const disable = item.disabled;
                                const placeholder = item.placeholder;
                                const values = formik.values;
                                const error = formik.errors[name];
                                const value = formik.values[name];
                                const touched = formik.touched[name];

                                switch (item.type) {
                                    case "date":
                                        const dateDisabled = name === "dob" && values.typeOfId === "passport" ? false : true;
                                        return (
                                            <div key={index} className="page-employee__input-wrapper">
                                                <AppInputDate value={value} maxDate="yesterday" error={touched && error} placeholder="Date of Birth" onChange={(value) => onHandleChange(name, value || null)} disabled={dateDisabled} />
                                            </div>
                                        );
                                    case "typeOfId":
                                        return (
                                            <div key={index} className="page-employee__input-wrapper">
                                                <AppDropdown
                                                    placeholder={placeholder}
                                                    onChange={(selected) => {
                                                        onHandleTypeOfId(name, selected);
                                                    }}
                                                    dropdownOptions={typeOfIdDropdownOption}
                                                    error={touched && error}
                                                    value={values[name]}
                                                    currentInputValue={values[name] ? typeOfIdDropdownOption.filter((ele) => ele.value.toLowerCase() === values[name])[0] : null}
                                                />
                                            </div>
                                        );
                                    case "ic":
                                        if (values.typeOfId === "ic no.") {
                                            return (
                                                <div key={index} className="page-employee__input-wrapper">
                                                    <AppInputWithLabel
                                                        placeholder={placeholder}
                                                        onChange={(e) => {
                                                            onHandleChange(name, formatNRIC(e.target.value));
                                                        }}
                                                        error={touched && error}
                                                        value={value ? value : ""}
                                                        onBlur={() => {
                                                            onHandleIcBlur(name, values);
                                                        }}
                                                    />
                                                </div>
                                            );
                                        } else return null;
                                    case "mobile":
                                        return (
                                            <div key={index} className="page-employee__input-wrapper">
                                                <AppInputWithLabel
                                                    placeholder={placeholder}
                                                    onChange={(e) => {
                                                        onHandleChange(name, e.target.value);
                                                    }}
                                                    error={touched && error}
                                                    value={values[name]}
                                                    onBlur={() => {
                                                        onHandleMobileBlur(name, values);
                                                    }}
                                                />
                                            </div>
                                        );
                                    case "textarea":
                                        return (
                                            <div key={index} className="page-employee__input-wrapper">
                                                <AppTextArea
                                                    placeholder={placeholder}
                                                    onChange={(e) => {
                                                        onHandleChange(name, e.target.value);
                                                    }}
                                                    error={touched && error}
                                                    value={values[name]}
                                                />
                                            </div>
                                        );
                                    case "username":
                                        return (
                                            <div key={index} className="page-employee__input-wrapper">
                                                <AppInputWithLabel
                                                    placeholder={placeholder}
                                                    onChange={(e) => {
                                                        onHandleChange(
                                                            name,
                                                            e.target.value
                                                                .toLowerCase()
                                                                .split(" ")
                                                                .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
                                                                .join(" ")
                                                        );
                                                    }}
                                                    error={touched && error}
                                                    value={values[name]}
                                                    disabled={disable}
                                                />
                                            </div>
                                        );
                                    default:
                                        if (name === "passport" && values.typeOfId !== "passport") {
                                            return null;
                                        } else {
                                            return (
                                                <div key={index} className="page-employee__input-wrapper">
                                                    <AppInputWithLabel
                                                        placeholder={placeholder}
                                                        onChange={(e) => {
                                                            onHandleChange(name, e.target.value);
                                                        }}
                                                        error={touched && error}
                                                        value={value}
                                                        disabled={disable}
                                                    />
                                                </div>
                                            );
                                        }
                                }
                            })}

                            <div className="page-employee__button-row">
                                <div className="page-employee__button-wrapper">
                                    <AppButton label="Cancel" buttonType="outline" size="l" onClick={() => history.goBack()} />
                                </div>
                                <div className="page-employee__button-wrapper">
                                    <AppButton disabled={!formik.dirty} onClick={formik.handleSubmit} label={"Save"} size="l" />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageEmployeeProfileAndSettings;
