// libraries
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTabLink from "components/app-tab-link";
import AppReactTable from "components/app-react-table";
import appToast from "components/app-toast";
// common
import { sanitizeError } from "common/utilities";
// services
import api from "services/api";
// routes
import pathnames from "routes/pathnames";

const breadcrumb = [
    {
        label: "Claim Management",
    },
    {
        label: "Claim Directory",
        pathname: pathnames.pageClaimDirectory,
    },
    {
        label: "Claim Summary",
    },
];

const PageClaimSummary = () => {
    const [tableData, setTableData] = useState([]);
    const [workingStatus, setWorkingStatus] = useState({
        staffId: "",
        userName: "",
        workingStatus: "",
    });
    const history = useHistory();
    const { pathStaffId } = useParams();
    let staffId = pathStaffId.replace("value_", "");

    const appTabLink = [
        {
            label: "Claim Summary",
            pathname: pathnames.pageClaimSummary.replace(":pathStaffId", "value_" + staffId),
        },
        {
            label: "Claim History",
            pathname: pathnames.pageClaimHistory.replace(":pathStaffId", "value_" + staffId),
        },
        {
            label: "Pending Claim",
            pathname: pathnames.pagePendingClaim.replace(":pathStaffId", "value_" + staffId),
        },
    ];

    useEffect(() => {
        const getWorkingStatus = async () => {
            try {
                let payload = ("000" + staffId).slice(-3);
                const response = await api.get.leaveManagementWorkingStatus(payload);
                setWorkingStatus(response.data.result);
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        };
        getWorkingStatus();
        const getStaffClaimSummary = async () => {
            try {
                const response = await api.post.claimManagementClaimSummary(staffId);
                setTableData(response.data.result);
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        };
        getStaffClaimSummary();
    }, [history, staffId]);

    const tableColumn = [
        {
            id: "claimType",
            Header: () => {
                return <span className="react-table__claim-type-header">Claim Type</span>;
            },
            Cell: (row) => {
                return <span className="react-table__claim-type-row">{row.row.original.claimType}</span>;
            },
        },
        {
            id: "entitledAmount",
            Header: () => {
                return <span>Entitled Amount (RM)</span>;
            },
            Cell: (row) => {
                if (row.row.original.claimType.toLowerCase() === "others") {
                    return (
                        <div className="react-table__amount-width">
                            <div className="react-table__amount-row">
                                <span>subject to approval</span>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className="react-table__amount-width">
                            <div className="react-table__amount-row">
                                <span>{parseFloat(row.row.original.entitledAmount) ? parseFloat(row.row.original.entitledAmount).toFixed(2) : row.row.original.entitledAmount}</span>
                            </div>
                        </div>
                    );
                }
            },
            searchFilterValue: "entitled_amount",
            maxWidth: 80,
        },
        {
            id: "balancedAmount",
            Header: () => {
                return <span>Balance Amount (RM)</span>;
            },
            Cell: (row) => {
                if (row.row.original.claimType.toLowerCase() === "others") {
                    return (
                        <div className="react-table__amount-width">
                            <div className="react-table__amount-row">
                                <span>subject to approval</span>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className="react-table__amount-width">
                            <div className="react-table__amount-row">
                                <span>{parseFloat(row.row.original.balanceAmount) ? parseFloat(row.row.original.balanceAmount).toFixed(2) : row.row.original.balanceAmount}</span>
                            </div>
                        </div>
                    );
                }
            },
        },
    ];

    return (
        <AppPagesLayout>
            <div className="page-claim-summary">
                <div className="page-claim-summary__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>
                <div className="page-claim-summary__user-info">
                    <div className="page-claim-summary__user-info--left">
                        <span>{workingStatus.userName}</span>
                        <span className="page-claim-summary__user-id">{workingStatus.staffId}</span>
                    </div>
                </div>
                <div className="page-claim-summary__tab-wrapper">
                    <AppTabLink tabList={appTabLink} />
                </div>

                <div className="page-claim-summary__table-wrapper">
                    <AppReactTable columns={tableColumn} data={tableData} customEmptyListMessage="Looks like you don't have any benefits yet , please contact the HR team for further clarification" />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageClaimSummary;
