import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { MdOutlineArrowDropDown } from "react-icons/md";

function AppDropdownSolid({ list, defaultValue }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
        <div className="app-dropdown-solid">
            <Dropdown isOpen={dropdownOpen} toggle={toggle} className="app-dropdown-solid__wrapper">
                <DropdownToggle className="app-dropdown-solid__toggle">
                    <div className="app-dropdown-solid__toggle-wrapper">
                        {defaultValue}
                        <div className="app-dropdown-solid__caret"><MdOutlineArrowDropDown size={22}/></div>
                    </div>
                </DropdownToggle>
                <DropdownMenu className="app-dropdown-solid__menu">
                    {list?.map((item, index) => {
                        return (
                            <DropdownItem key={index} onClick={item.onClick} className="app-dropdown-solid__item">
                                {item.label}
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}

export default AppDropdownSolid;
