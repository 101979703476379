// libraries
import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
// components
import AppInput from "components/app-input";
import appToast from "components/app-toast";
import AppButton from "components/app-button";
import AppTabLink from "components/app-tab-link";
import AppPaginate from "components/app-paginate";
import AppDropdown from "components/app-dropdown";
import AppCheckbox from "components/app-checkbox";
import AppTextArea from "components/app-text-area";
import AppInputDate from "components/app-input-date";
import AppBreadcrumb from "components/app-breadcrumb";
import AppReactTable from "components/app-react-table";
import AppPagesLayout from "components/app-pages-layout";
import AppDropdownMini from "components/app-dropdown-mini";
import AppPdfImgViewer from "components/app-pdf-img-viewer";
import AppInputWithLabel from "components/app-input-with-label";
import AppAttachmentImage from "components/app-attachment-image";
import AppDualColumnsModal from "components/app-dual-columns-modal";
import AppModalConfirmation from "components/app-modal-confirmation";
// service
import api from "services/api";
// routes
import pathnames from "routes/pathnames";
// hooks
import usePrevious from "hooks/use-previous";
// utilities
import { statusFormat } from "common/utilities";
// common
import { sanitizeError } from "common/utilities";
// assets
import iconSetting from "assets/images/icon-setting.svg";
import iconDots from "assets/images/icon-vertical-dots.svg";
import iconTailedArrowUp from "assets/images/icon-tailed-arrow-up.svg";
import iconSearch from "assets/images/components/app-input/icon-search.svg";

const breadcrumb = [
    {
        label: "Claim Management",
    },
    {
        label: "Pending Payment",
    },
];

const appTabLink = [
    {
        label: "Claim Directory",
        pathname: pathnames.pageClaimDirectory,
    },
    {
        label: "Pending Approval",
        pathname: pathnames.pageClaimPendingApproval,
    },
    {
        label: "Pending Payment",
        pathname: pathnames.pageClaimPendingPayment,
    },
];

const advanceSearch = [
    {
        name: "claimType",
        placeholder: "Claim Type",
        type: "dropdown",
    },
    {
        name: "submitDate",
        placeholder: "Submit Date",
        type: "date",
    },
    {
        name: "amount",
        placeholder: "Amount (RM)",
        type: "input",
    },
];

const pendingClaimDetailsInitialValues = {
    billAmount: "",
    approveAmount: "",
    attachment: "",
    claimType: "",
    currentBalance: "",
    desc: "",
    id: "",
    staffID: "",
    status: "",
    submitDate: "",
    userName: "",
    fileType: "",
};

const attachmentSrcInitialState = {
    file: "",
    type: "",
};

const PageClaimPendingPayment = () => {
    const profile = useSelector((state) => state.auth);
    const searchKeywordInputRef = useRef(null);
    const [tableIds, setTableIds] = useState([]);
    const [idRowSelected, setIdRowSelected] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [singleIdSelected, setSingleIdSelected] = useState();
    const [claimTypeDropdown, setClaimTypeDropdown] = useState([]);
    const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
    const [modalIsOpenArrangement, setModalIsOpenArrangement] = useState([]);
    const [attachmentSrc, setAttachmentSrc] = useState(attachmentSrcInitialState);
    const [pendingClaimDetails, setPendingClaimDetails] = useState(pendingClaimDetailsInitialValues);

    const [detailSearch, setDetailSearch] = useState({
        claimType: "",
        submitDate: "",
        amount: "",
    });

    const [tableFilter, setTableFilter] = useState({
        pageSize: 5,
        pageNo: 0,
        sortBy: "id",
        sortDir: "asc",
    });

    const [tableData, setTableData] = useState({
        content: [],
        last: null,
        pageNo: 0,
        pageSize: 5,
        totalElements: null,
        totalPages: 1,
    });

    const prevValueSortHeader = usePrevious({
        sortBy: tableFilter.sortBy,
        sortDir: tableFilter.sortDir,
    });

    const [confirmModal, setConfirmModal] = useState({
        details: "",
        confirmDisable: false,
        claimStatus: "",
    });

    const tableDataDropdownOptions = [
        {
            item: "Paid",
            onClick: (item) => {
                setSingleIdSelected(item);
                setModalIsOpenArrangement(["APPROVE_CONFIRMATION"]);
                setConfirmModal({
                    details: "Confirm payment made?",
                    claimStatus: "approve",
                });
            },
        },
    ];

    const getDefaultClaimType = async () => {
        try {
            const response = await api.get.claimManagementClaimType();
            const claimTypeArray = response.data.result.map((e, i) => {
                return {
                    label: e,
                    value: i + 1,
                };
            });
            setClaimTypeDropdown(claimTypeArray);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    const getTableData = async (payload) => {
        try {
            const response = await api.get.claimManagementSearchPaymentPending(payload ? payload : null);
            setTableData(response.data.result);
            const result = response.data.result;
            const ids = result.content.map((item) => item.id);
            setTableIds(ids);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    useEffect(() => {
        getDefaultClaimType();
        getTableData();
    }, []);

    const sortSearchApply = async (tableFilterPayload) => {
        let payload = {
            searchValue: searchKeyword,
            ...tableFilterPayload,
            ...detailSearch,
        };
        getTableData(payload);
    };

    const searchKeywordTyped = async (value) => {
        setSearchKeyword(value);
        setTableFilter({
            pageNo: 0,
            pageSize: 5,
            sortBy: "",
            sortDir: "asc",
        });
        let payload = {
            searchValue: value,
            pageNo: 0,
            pageSize: 5,
            sortBy: "",
            sortDir: "asc",
        };
        getTableData(payload);
    };

    const applySearchKeywordSearch = async () => {
        let payload = {
            searchValue: "",
            ...tableFilter,
        };
        getTableData(payload);
    };

    const searchBarOnChange = (e) => {
        searchKeywordTyped(e.target.value);
    };

    const handleKeypress = (e) => {
        if (e.code === "Enter") {
            searchKeywordTyped(e.target.value);
        }
    };

    const applyAdvanceSearch = async () => {
        let payload = {
            ...detailSearch,
            ...tableFilter,
        };
        getTableData(payload);
    };

    const applyResetSearch = async () => {
        let payload = {
            ...tableFilter,
        };
        getTableData(payload);
    };

    const selectRowFunction = (rowData) => {
        if (idRowSelected.includes(rowData.id)) {
            setIdRowSelected([...idRowSelected.filter((ele) => ele !== rowData.id)]);
        } else {
            const sortedIdRowSelected = [...idRowSelected, rowData.id];
            setIdRowSelected(sortedIdRowSelected.sort());
        }
    };

    const selectAllFunction = (tableIds) => {
        if (_.isEqual(idRowSelected, tableIds)) {
            setIdRowSelected([]);
        } else {
            setIdRowSelected(tableIds);
        }
    };

    const headerArrowRotate = (active) => {
        const classNames = ["react-table__arrow-icon"];
        if (active && tableFilter.sortDir === "desc") classNames.push("react-table__arrow-icon-down");
        if (active) classNames.push("react-table__arrow-icon--active");

        return classNames.join(" ");
    };

    const headerFilterClick = (header) => {
        let tableFilterPayload;
        let column = header.column;

        if (prevValueSortHeader && prevValueSortHeader.sortBy === column.searchFilterValue) {
            if (prevValueSortHeader.sortDir === "asc") {
                tableFilterPayload = {
                    ...tableFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "desc",
                    pageNo: 0,
                };
                setTableFilter(tableFilterPayload);
                sortSearchApply(tableFilterPayload);
            } else {
                tableFilterPayload = {
                    ...tableFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "asc",
                    pageNo: 0,
                };
                setTableFilter(tableFilterPayload);
                sortSearchApply(tableFilterPayload);
            }
        } else {
            tableFilterPayload = {
                ...tableFilter,
                sortBy: column.searchFilterValue,
                sortDir: "asc",
                pageNo: 0,
            };
            setTableFilter(tableFilterPayload);
            sortSearchApply(tableFilterPayload);
        }
    };

    const onHandlePageChange = (event) => {
        let tableFilterPayload = {
            ...tableFilter,
            pageNo: event.selected,
        };
        setTableFilter(tableFilterPayload);

        let payload = {
            searchValue: searchKeyword,
            ...tableFilterPayload,
            ...detailSearch,
        };
        getTableData(payload);
    };

    const confirmationModalConfirmed = async () => {
        setConfirmModal({
            ...confirmModal,
            confirmDisable: true,
        });

        if (confirmModal.claimStatus === "approve" || confirmModal.claimStatus === "modal_approve") {
            try {
                const stringId = ("000" + singleIdSelected.toString()).slice(-3);
                let payload = {
                    claimId: stringId,
                    adminStaffId: profile.staffID,
                };
                await api.get.claimManagementIssuePaidStatus(payload);
                setSingleIdSelected("");
                setModalIsOpenArrangement([]);
                setConfirmModal({
                    details: "",
                    confirmDisable: false,
                    claimStatus: "",
                });
                appToast("Payment had been updated successfully.", true);
                getTableData();
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
                setConfirmModal({
                    ...confirmModal,
                    confirmDisable: false,
                });
            }
        } else {
            try {
                let payload = {
                    approveClaimsId: idRowSelected.toString(),
                    adminStaffId: profile.staffID,
                };
                await api.post.claimManagementBulkIssuePaidStatus(payload);
                setSingleIdSelected("");
                setModalIsOpenArrangement([]);
                setConfirmModal({
                    details: "",
                    confirmDisable: false,
                    claimStatus: "",
                });
                appToast("Payment had been updated successfully.", true);
                getTableData();
                setIdRowSelected([]);
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
                setConfirmModal({
                    ...confirmModal,
                    confirmDisable: false,
                });
                setModalIsOpenArrangement([]);
            }
        }
    };

    const tableColumn = [
        {
            id: "Id",
            Header: (header) => {
                if (header.data.length) {
                    return (
                        <div className="react-table__checkbox-wrapper">
                            <AppCheckbox type="selectAll" onChange={() => selectAllFunction(tableIds)} checked={_.isEqual(idRowSelected, tableIds)} />
                        </div>
                    );
                } else {
                    return null;
                }
            },
            Cell: (row) => {
                return (
                    <div className="react-table__checkbox-wrapper" onClick={(e) => e.stopPropagation()}>
                        <AppCheckbox onChange={() => selectRowFunction(row.row.original)} checked={idRowSelected.includes(row.row.original.id)} />
                    </div>
                );
            },
            maxWidth: 32,
        },
        {
            id: "staffUserName",
            Header: () => {
                return <span className="react-table__userName-header">Username</span>;
            },
            Cell: (row) => {
                return <div className="react-table__userName-data">{row.row.original.staffUserName}</div>;
            },
            maxWidth: 90,
        },
        {
            id: "claimType",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Claim Type
                        <img className={headerArrowRotate(tableFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <div style={{ wordWrap: "break-word" }}>{row.row.original.claimType}</div>;
            },
            maxWidth: 120,
            searchFilterValue: "claim_type",
        },
        {
            id: "submitDate",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Submit Date
                        <img className={headerArrowRotate(tableFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <span>{moment(row.row.original.submitDate).format("DD/MM/YYYY")}</span>;
            },
            maxWidth: 80,
            searchFilterValue: "submit_date",
        },
        {
            id: "claim_amount",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Amount (RM)
                        <img className={headerArrowRotate(tableFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            accessor: "amount",
            Cell: (row) => {
                return (
                    <div className="react-table__amount-row">
                        <div className="react-table__amount">
                            <span>{row.row.original.amount.toFixed(2)}</span>
                        </div>
                    </div>
                );
            },
            searchFilterValue: "claim_amount",
            maxWidth: 50,
        },
        {
            id: "button",
            Cell: (row) => {
                return (
                    <div onClick={(e) => e.stopPropagation()}>
                        <AppDropdownMini
                            page="page-claim-pending-payment"
                            label={<img className="react-table__edit-icon" src={iconDots} alt="" />}
                            toggle={() => {
                                if (dropdownOpen) {
                                    setDropdownOpen(null);
                                } else {
                                    setDropdownOpen(row.row.original.id);
                                }
                            }}
                            isOpen={row.row.original.id === dropdownOpen}
                            dropdownItem={tableDataDropdownOptions}
                            outContentsSelected={dropdownOpen}
                            tableDottedButton={true}
                        />
                    </div>
                );
            },
            maxWidth: 25,
        },
    ];

    const claimDetailModalApprove = () => {
        setModalIsOpenArrangement([...modalIsOpenArrangement, "APPROVE_CONFIRMATION"]);
        setConfirmModal({
            ...confirmModal,
            details: "Confirm payment made?",
            claimStatus: "modal_approve",
        });
    };

    const onHandleTableRowClicked = async (row) => {
        try {
            const response = await api.get.claimManagementPendingClaimDetails(row.id);
            let result = response.data.result;
            const fileSplitted = result.attachment.split(".");
            const fileType = fileSplitted[fileSplitted.length - 1];
            setPendingClaimDetails({ ...result, fileType: fileType });
            setSingleIdSelected(row.id);
            setModalIsOpenArrangement(["MODAL_CLAIM_DETAILS"]);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    const onHandleInputFields = (e, name) => {
        setDetailSearch({
            ...detailSearch,
            [name]: e.target.value,
        });
    };

    const onHandleDropdownFields = (selected, name) => {
        setDetailSearch({
            ...detailSearch,
            [name]: selected.label,
        });
    };

    const onHandleDateFields = (value, name) => {
        if (value === "") {
            setDetailSearch({
                ...detailSearch,
                [name]: null,
            });
        } else {
            setDetailSearch({
                ...detailSearch,
                [name]: moment(value, "DD/MM/YYYY").format("YYYY-MM-DD"),
            });
        }
    };

    const onHandleReset = () => {
        setDetailSearch({
            claimType: "",
            submitDate: "",
            amount: "",
        });
        applyResetSearch();
    };

    const onHandlePaid = () => {
        setConfirmModal({
            ...confirmModal,
            details: "Confirm to approve?",
            confirmDisable: false,
            claimStatus: "modal_multiple_approve",
        });
        setModalIsOpenArrangement(["APPROVE_CONFIRMATION"]);
    };

    return (
        <AppPagesLayout>
            <div className="page-claim-pending-payment">
                <AppDualColumnsModal
                    title="Claim Details"
                    isOpenModal={modalIsOpenArrangement.slice(-1)[0] === "MODAL_CLAIM_DETAILS"}
                    onRequestClose={() => {
                        setModalIsOpenArrangement(modalIsOpenArrangement.slice(0, -1));
                        setPendingClaimDetails(pendingClaimDetailsInitialValues);
                    }}
                    status={statusFormat(pendingClaimDetails.status)}
                    statusColor="green"
                    childrenLeft={
                        <div>
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Username" value={pendingClaimDetails.userName} disabled={true} />
                            </div>
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Claim Type" value={pendingClaimDetails.claimType} disabled={true} />
                            </div>
                            {pendingClaimDetails.attachment ? (
                                <div
                                    className="page-pending-claim__modal-input-wrapper page-pending-claim__modal-input-wrapper--hover"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setAttachmentSrc({
                                            file: pendingClaimDetails.attachment,
                                            type: pendingClaimDetails.fileType,
                                        });
                                        setModalIsOpenArrangement([...modalIsOpenArrangement, "MODAL_ATTACHMENT"]);
                                    }}>
                                    <span className="page-pending-claim__modal-icon-wrapper">
                                        <AppAttachmentImage fileType={pendingClaimDetails.fileType} />
                                    </span>
                                    <AppInputWithLabel
                                        placeholder="Attachment"
                                        value={`        ${pendingClaimDetails.attachment.split("/")[pendingClaimDetails.attachment.split("/").length - 1]}`}
                                        disabled={true}
                                        onClick={() => {
                                            setAttachmentSrc({
                                                file: pendingClaimDetails.attachment,
                                                type: pendingClaimDetails.fileType,
                                            });
                                            setModalIsOpenArrangement([...modalIsOpenArrangement, "MODAL_ATTACHMENT"]);
                                        }}
                                    />
                                </div>
                            ) : null}
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppTextArea placeholder="Description" value={pendingClaimDetails.desc ? pendingClaimDetails.desc : ""} disabled={true} />
                            </div>
                        </div>
                    }
                    childrenRight={
                        <div>
                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Employee ID" value={pendingClaimDetails.staffId} disabled={true} />
                            </div>

                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Bill Amount (RM)" value={parseFloat(pendingClaimDetails.amount).toFixed(2)} disabled={true} />
                            </div>

                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Approved Amount (RM)" value={parseFloat(pendingClaimDetails.approveAmount).toFixed(2)} disabled={true} />
                            </div>

                            <div className="page-pending-claim__modal-input-wrapper">
                                <AppInputWithLabel placeholder="Submit Date" value={pendingClaimDetails.submitDate} disabled={true} />
                            </div>
                        </div>
                    }
                    childrenBottom={
                        <div className="page-pending-claim__button-row">
                            <div className="page-pending-claim__button-wrapper">
                                <AppButton
                                    label="Cancel"
                                    buttonType="outline"
                                    size="l"
                                    onClick={() => {
                                        setModalIsOpenArrangement(modalIsOpenArrangement.slice(0, -1));
                                    }}
                                />
                            </div>
                            <div className="page-pending-claim__button-wrapper">
                                <AppButton label="Paid" size="l" onClick={claimDetailModalApprove} />
                            </div>
                        </div>
                    }
                />

                <AppPdfImgViewer
                    isOpenModal={modalIsOpenArrangement.slice(-1)[0] === "MODAL_ATTACHMENT"}
                    onRequestClose={() => {
                        setModalIsOpenArrangement(modalIsOpenArrangement.slice(0, -1));
                        setTimeout(() => {
                            setAttachmentSrc(attachmentSrcInitialState);
                        }, [250]);
                    }}
                    attachmentSrc={attachmentSrc}
                />

                <AppModalConfirmation
                    isOpenModal={modalIsOpenArrangement.slice(-1)[0] === "APPROVE_CONFIRMATION"}
                    onRequestClose={() => {
                        setConfirmModal({
                            details: "",
                            confirmDisable: false,
                            claimStatus: "",
                            removeAmount: "",
                        });
                        setModalIsOpenArrangement(modalIsOpenArrangement.slice(0, -1));
                    }}
                    details={confirmModal.details}
                    onClick={confirmationModalConfirmed}
                    buttonDisabled={confirmModal.confirmDisable}
                    buttonLabel="Confirm"
                />

                <div className="page-claim-pending-payment__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>

                <div className="page-claim-pending-payment__tab-wrapper">
                    <AppTabLink tabList={appTabLink} />
                </div>

                <div className="page-claim-pending-payment__searchBar-wrapper">
                    <AppInput
                        leftSrc={iconSearch}
                        rightSrc={iconSetting}
                        rightSrcOnClick={() => {
                            setShowAdvanceSearch(!showAdvanceSearch);
                            searchKeywordInputRef.current.value = "";
                            searchKeywordTyped("");
                        }}
                        onFocus={() => {
                            if (showAdvanceSearch) {
                                setShowAdvanceSearch(false);
                                searchKeywordInputRef.current.value = "";
                                searchKeywordTyped("");
                                applySearchKeywordSearch();
                            }
                        }}
                        ref={searchKeywordInputRef}
                        placeholder="Search keyword"
                        onChange={_.debounce((e) => searchBarOnChange(e), 1000)}
                        onKeyPress={handleKeypress}
                    />
                </div>

                {showAdvanceSearch ? (
                    <div className="page-claim-pending-payment__search-content">
                        <div className="page-claim-pending-payment__input">
                            {advanceSearch.map((item, index) => {
                                const placeholder = item.placeholder;
                                const name = item.name;
                                switch (item.type) {
                                    case "input":
                                        return (
                                            <div key={index} className="page-claim-pending-payment__input-wrapper">
                                                <AppInputWithLabel placeholder={placeholder} onChange={(e) => onHandleInputFields(e, name)} value={detailSearch[name]} />
                                            </div>
                                        );
                                    case "dropdown":
                                        return (
                                            <div key={index} className="page-claim-pending-payment__input-wrapper">
                                                <AppDropdown
                                                    placeholder={placeholder}
                                                    dropdownOptions={claimTypeDropdown}
                                                    onChange={(selected) => onHandleDropdownFields(selected, name)}
                                                    currentInputValue={detailSearch[name] ? claimTypeDropdown.filter((e) => e.label === detailSearch[name])[0] : ""}
                                                />
                                            </div>
                                        );
                                    case "date":
                                        return (
                                            <div key={index} className="page-claim-pending-payment__input-wrapper">
                                                <AppInputDate
                                                    placeholder={placeholder}
                                                    onChange={(value) => onHandleDateFields(value, name)}
                                                    value={moment(detailSearch[name], "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                />
                                            </div>
                                        );
                                    default:
                                        return null;
                                }
                            })}
                        </div>

                        <div className="page-claim-pending-payment__button-row">
                            <div className="page-claim-pending-payment__button-wrapper">
                                <AppButton
                                    label="Reset"
                                    buttonType="outline"
                                    size="s"
                                    onClick={onHandleReset}
                                />
                            </div>
                            <div className="page-claim-pending-payment__button-wrapper">
                                <AppButton label="Apply" size="s" onClick={applyAdvanceSearch} />
                            </div>
                        </div>
                    </div>
                ) : null}

                <div className="page-claim-pending-payment__table-wrapper">
                    <AppReactTable
                        headerSelectAllChildren={
                            <div className="page-leave-pending-approval__button-row">
                                <div className="page-leave-pending-approval__button-wrapper">
                                    <AppButton
                                        label="Paid"
                                        size="s"
                                        onClick={onHandlePaid}
                                    />
                                </div>
                            </div>
                        }
                        columns={tableColumn}
                        data={tableData.content}
                        searchKeyword={searchKeyword || !Object.values(detailSearch).every((e) => e === "")}
                        onClickRow={onHandleTableRowClicked}
                        idSelectAll={idRowSelected.length}
                        totalItems={tableIds.length}
                    />
                </div>

                <div className="page-claim-pending-payment__paginate-wrapper">
                    <AppPaginate onPageChange={onHandlePageChange} pageCount={tableData.totalPages} forcePage={tableData.pageNo} />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageClaimPendingPayment;
