// staff pathnames
import employeePathnames from "./employee-pathnames";
// dashboard
import PageEmployeeDashboard from "pages/employee/home/page-dashboard";
import PageEmployeeProfileAndSettings from "pages/employee/home/page-profile-and-settings";
// leave management
import PageEmployeeLeaveCalendar from 'pages/employee/leave-management/page-leave-calendar';
import PageEmployeeLeaveSummary from "pages/employee/leave-management/page-leave-summary";
import PageEmployeeLeaveHistory from "pages/employee/leave-management/page-leave-history";
import PageEmployeePendingLeave from "pages/employee/leave-management/page-pending-leave";
// claim management
import PageEmployeeClaimSummary from "pages/employee/claim-management/page-claim-summary";
import PageEmployeeClaimHistory from "pages/employee/claim-management/page-claim-history";
import PageEmployeePendingClaim from "pages/employee/claim-management/page-pending-claim";
// payslip
import PageEmployeePortalPayslip from "pages/employee/payslip/page-payslip";
// coaching session
import PageEmployeeUpcomingSchedule from "pages/employee/coaching-session/page-upcoming-schedule";
import PageEmployeeCoachingHistory from "pages/employee/coaching-session/page-coaching-history";

const employeeRoutes = [
    {
        path: employeePathnames.pageEmployeeDashboard,
        component: PageEmployeeDashboard,
        title: "Dashboard",
        authentication: true,
    },
    {
        path: employeePathnames.pageEmployeeDashboardProfileAndSettings,
        component: PageEmployeeProfileAndSettings,
        title: "Dashboard",
        authentication: true,
    },
    {
        path: employeePathnames.pageEmployeeLeaveCalendar,
        component: PageEmployeeLeaveCalendar,
        title: "Leave Management",
        authentication: true,
    },
    {
        path: employeePathnames.pageEmployeeLeaveSummary,
        component: PageEmployeeLeaveSummary,
        title: "Leave Management",
        authentication: true,
    },
    {
        path: employeePathnames.pageEmployeeLeaveHistory,
        component: PageEmployeeLeaveHistory,
        title: "Leave Management",
        authentication: true,
    },
    {
        path: employeePathnames.pageEmployeePendingLeave,
        component: PageEmployeePendingLeave,
        title: "Leave Management",
        authentication: true,
    },
    {
        path: employeePathnames.pageEmployeeClaimSummary,
        component: PageEmployeeClaimSummary,
        title: "Claim Management",
        authentication: true,
    },
    {
        path: employeePathnames.pageEmployeeClaimHistory,
        component: PageEmployeeClaimHistory,
        title: "Claim Management",
        authentication: true,
    },
    {
        path: employeePathnames.pageEmployeePendingClaim,
        component: PageEmployeePendingClaim,
        title: "Claim Management",
        authentication: true,
    },
    {
        path: employeePathnames.pageEmployeePayslip,
        component: PageEmployeePortalPayslip,
        title: "Payslip",
        authentication: true,
    },
    {
        path: employeePathnames.pageEmployeeUpcomingSchedule,
        component: PageEmployeeUpcomingSchedule,
        title: "Coaching Session",
        authentication: true,
    },
    {
        path: employeePathnames.pageEmployeeCoachingHistory,
        component: PageEmployeeCoachingHistory,
        title: "Coaching Session",
        authentication: true,
    }
];

export default employeeRoutes;