// libraries
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { BiFilterAlt } from "react-icons/bi";
import { BsExclamationCircle } from "react-icons/bs";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppReactTable from "components/app-react-table";
import AppPaginate from "components/app-paginate";
import appToast from "components/app-toast";
import AppDropdownCheckbox from "components/app-dropdown-checkbox";
import AppCheckbox from "components/app-checkbox";
import AppPdfImgViewer from "components/app-pdf-img-viewer";
// service
import api from "services/api";
// common
import { sanitizeError } from "common/utilities";
// hooks
import usePrevious from "hooks/use-previous";
// route
import pathnames from "routes/pathnames";
// assets
import iconSearch from "assets/images/components/app-input/icon-search.svg";
import iconTailedArrowUp from "assets/images/icon-tailed-arrow-up.svg";
import iconCalendar from "assets/images/components/app-button/icon-calendar.svg";
import iconLetter from "assets/images/components/app-button/icon-letter.svg";
import iconFilePdf from "assets/images/icon-file-pdf.svg";

const breadcrumb = [
    {
        label: "Employee Confirmation",
    },
];

const tableStatusFilter = [
    { label: "Probation", name: "probation", value: "probation" },
    { label: "Permanent", name: "permanent", value: "permanent" },
];

const getTableStatusClassNames = (status) => {
    const classNames = ["react-table__status-data"];
    if (status.toLowerCase() === "probation") classNames.push("react-table__status--lightBlue");
    if (status.toLowerCase() === "confirmed") classNames.push("react-table__status--green");
    if (status.toLowerCase() === "permanent") classNames.push("react-table__status--blue");

    return classNames.join(" ");
};

const getButtonIconClassNames = (type, disable) => {
    const classNames = ["page-employee-confirmation__button-icon"];
    if (type === "calendar" && !disable) classNames.push("page-employee-confirmation__button-icon--calendar-enable");
    if (type === "letter" && !disable) classNames.push("page-employee-confirmation__button-icon--letter-enable");

    return classNames.join(" ");
};

let statusToFilter = [];

const PageEmployeeConfirmation = () => {
    const history = useHistory();
    const [tableSearchFilter, setTableSearchFilter] = useState({
        filter: "",
        pageNo: 0,
        pageSize: 5,
        searchValue: "",
        sortBy: "",
        sortDir: "asc",
    });
    const [tableData, setTableData] = useState({
        content: [],
        last: null,
        pageNo: 0,
        pageSize: 5,
        totalElements: null,
        totalPages: 1,
    });
    const prevValueSortHeader = usePrevious({
        sortBy: tableSearchFilter.sortBy,
        sortDir: tableSearchFilter.sortDir,
    });
    const [statusFilterOpen, setStatusFilterOpen] = useState(false);
    const [statusFilter, setStatusFilter] = useState({
        probation: false,
        confirmed: false,
    });
    const [idRowSelected, setIdRowSelected] = useState([]);
    const [allId, setAllId] = useState([]);
    const [pdfModalOpen, setPdfModalOpen] = useState("");

    useEffect(() => {
        const getTableData = async () => {
            try {
                const response = await api.get.employeeConfirmationSearchEmployee(tableSearchFilter);
                setTableData(response.data.result);
                setAllId(response.data.result.staffIds);
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        };
        getTableData();
    }, [tableSearchFilter]);

    const headerArrowRotate = (active) => {
        const classNames = ["react-table__arrow-icon"];
        if (active && tableSearchFilter.sortDir === "desc") classNames.push("react-table__arrow-icon-down");
        if (active) classNames.push("react-table__arrow-icon--active");

        return classNames.join(" ");
    };

    const headerFilterClick = (header) => {
        let column = header.column;
        if (prevValueSortHeader && prevValueSortHeader.sortBy === column.searchFilterValue) {
            if (prevValueSortHeader.sortDir === "asc") {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "desc",
                    pageNo: 0,
                });
            } else {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "asc",
                    pageNo: 0,
                });
            }
        } else {
            setTableSearchFilter({
                ...tableSearchFilter,
                sortBy: column.searchFilterValue,
                sortDir: "asc",
                pageNo: 0,
            });
        }
    };

    const selectAllFunction = (allId) => {
        if (_.isEqual(idRowSelected, allId)) {
            setIdRowSelected([]);
        } else {
            setIdRowSelected(allId);
        }
    };

    const selectRowFunction = (rowData) => {
        if (idRowSelected.includes(rowData.staffID)) {
            setIdRowSelected([...idRowSelected.filter((ele) => ele !== rowData.staffID)]);
        } else {
            const sortedIdRowSelected = [...idRowSelected, rowData.staffID];
            setIdRowSelected(sortedIdRowSelected.sort());
        }
    };

    const tableColumn = [
        {
            id: "Id",
            Header: (header) => {
                if (header.data.length) {
                    return (
                        <div className="react-table__checkbox-wrapper">
                            <AppCheckbox type="selectAll" onChange={() => selectAllFunction(allId)} checked={_.isEqual(idRowSelected, allId)} />
                        </div>
                    );
                } else {
                    return null;
                }
            },
            Cell: (row) => {
                if (allId.includes(row.row.original.staffID)) {
                    return (
                        <div className="react-table__checkbox-wrapper" onClick={(e) => e.stopPropagation()}>
                            <AppCheckbox onChange={() => selectRowFunction(row.row.original)} checked={idRowSelected.includes(row.row.original.staffID)} />
                        </div>
                    );
                } else
                    return (
                        <div className="react-table__checkbox-wrapper">
                            <div className="react-table__row-checkbox-disable" />
                        </div>
                    );
            },
            maxWidth: 50,
        },
        {
            id: "staffUserName",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Username
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <span>{row.row.original.staffUserName}</span>;
            },
            searchFilterValue: "user_name",
        },
        {
            id: "staffID",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Employee ID
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            accessor: "staffID",
            searchFilterValue: "staff_id",
        },
        {
            id: "joinedDate",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Joined Date
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <span>{moment(row.row.original.joinedDate).format("DD/MM/YYYY")}</span>;
            },
            accessor: "joinedDate",
            searchFilterValue: "joined_date",
        },
        {
            id: "probationEndDate",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Probation End Date
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <span>{moment(row.row.original.probationEndDate).format("DD/MM/YYYY")}</span>;
            },
            searchFilterValue: "probation_end",
        },
        {
            id: "attachment",
            Header: () => {
                return <span className="react-table__header react-table__header--cursor-default">Attachment</span>;
            },
            Cell: (row) => {
                if (row.row.original.attachment) {
                    return (
                        <div className="react-table__attachment" onClick={(e) => e.stopPropagation()}>
                            <img className="react-table__attachment-icon" alt="" src={iconFilePdf} onClick={() => setPdfModalOpen(row.row.original.attachment)} />
                        </div>
                    );
                } else {
                    return null;
                }
            },
            maxWidth: 80
        },
        {
            id: "claimStatus",
            Header: () => {
                const getStatusClassNames = (statusFilterOpen) => {
                    const classNames = ["react-table__icon-filter"];
                    if (statusFilterOpen) classNames.push("react-table__icon-filter--active");
                    return classNames.join(" ");
                };
                return (
                    <div onClick={(e) => e.stopPropagation()}>
                        <span className="react-table__status-header" onClick={() => setStatusFilterOpen(!statusFilterOpen)}>
                            Status
                            <BiFilterAlt className={getStatusClassNames(statusFilterOpen)} />
                        </span>
                        {statusFilterOpen ? (
                            <div className="react-table__dropdown">
                                <AppDropdownCheckbox
                                    dropdownList={tableStatusFilter}
                                    onChange={(item, value) => {
                                        setStatusFilter({
                                            ...statusFilter,
                                            [item.name]: value,
                                        });
                                        if (value) {
                                            statusToFilter.push(item.value);
                                        } else {
                                            statusToFilter = statusToFilter.filter((e) => e !== item.value);
                                        }
                                        if (statusToFilter.length === 1) {
                                            setTableSearchFilter({
                                                ...tableSearchFilter,
                                                filter: statusToFilter[0],
                                                pageNo: 0,
                                            });
                                        } else {
                                            setTableSearchFilter({
                                                ...tableSearchFilter,
                                                filter: "",
                                                pageNo: 0,
                                            });
                                        }
                                    }}
                                    state={statusFilter}
                                />
                            </div>
                        ) : null}
                    </div>
                );
            },
            Cell: (row) => {
                return (
                    <div className={getTableStatusClassNames(row.row.original.status)}>
                        <span>{row.row.original.status}</span>
                    </div>
                );
            },
            searchFilterValue: "status",
        },
        {
            id: "button",
            Cell: (row) => {
                if (moment(row.row.original.probationEndDate).isBefore(moment()) && row.row.original.status === "Probation") {
                    return (
                        <span>
                            <BsExclamationCircle color="#dc4c4c" size={16} />
                        </span>
                    );
                } else return null;
            },
            maxWidth: 50,
        },
    ];

    const searchKeywordTyped = (value) => {
        setTableSearchFilter({
            filter: "",
            pageNo: 0,
            pageSize: 5,
            searchValue: value,
            sortBy: "",
            sortDir: "asc",
        });
    };

    const searchBarOnChange = (e) => {
        searchKeywordTyped(e.target.value);
    };

    const handleKeypress = (e) => {
        if (e.code === "Enter") {
            searchKeywordTyped(e.target.value);
        }
    };

    const onPageChange = (e) => {
        setTableSearchFilter({
            ...tableSearchFilter,
            pageNo: e.selected,
        });
    };

    const sendConfirmationLetterOnClick = async () => {
        try {
            let payload = {
                staffId: idRowSelected.join(","),
            };
            const response = await api.get.getUsernameDetails(payload);
            history.push({ pathname: pathnames.pageEmployeeConfirmationSendLetter, state: { selectedUserDetails: response.data.result, page: "CONFIRMATION_LETTER" } });
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    const sendProbationLetterOnClick = async () => {
        try {
            let payload = {
                staffId: idRowSelected.join(","),
            };
            const response = await api.get.getUsernameDetails(payload);
            history.push({ pathname: pathnames.pageEmployeeConfirmationSendLetter, state: { selectedUserDetails: response.data.result, page: "PROBATION_LETTER" } });
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    return (
        <AppPagesLayout>
            <div className="page-employee-confirmation" onClick={() => setStatusFilterOpen(false)}>
                <AppPdfImgViewer isOpenModal={pdfModalOpen} onRequestClose={() => setPdfModalOpen("")} attachmentSrc={{ type: "pdf", file: pdfModalOpen }} />

                <div className="page-employee-confirmation__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>

                <div className="page-employee-confirmation__row">
                    <div className="page-employee-confirmation__searchBar-wrapper">
                        <AppInput leftSrc={iconSearch} placeholder="Search keyword" onChange={_.debounce((e) => searchBarOnChange(e), 1000)} onKeyPress={handleKeypress} />
                    </div>
                    <div className="page-employee-confirmation__button-row">
                        <div className="page-employee-confirmation__button-wrapper">
                            <AppButton
                                size="s"
                                buttonIcon={<img className={getButtonIconClassNames("calendar", !idRowSelected.length)} alt="" src={iconCalendar} />}
                                buttonType="outline"
                                label="Extend Probation Date"
                                onClick={sendProbationLetterOnClick}
                                disabled={!idRowSelected.length}
                            />
                        </div>

                        <div className="page-employee-confirmation__button-wrapper">
                            <AppButton
                                size="s"
                                buttonIcon={<img className={getButtonIconClassNames("letter", !idRowSelected.length)} alt="" src={iconLetter} />}
                                label="Send Confirmation Letter"
                                onClick={sendConfirmationLetterOnClick}
                                disabled={!idRowSelected.length}
                            />
                        </div>
                    </div>
                </div>
                <div className="page-employee-confirmation__table-wrapper">
                    <AppReactTable columns={tableColumn} data={tableData.content} searchKeyword={tableSearchFilter.searchValue} idSelectAll={idRowSelected.length} totalItems={allId.length} />
                </div>
                <div className="page-employee-confirmation__pagination">
                    <AppPaginate onPageChange={onPageChange} pageCount={tableData.totalPages} forcePage={tableSearchFilter.pageNo} />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageEmployeeConfirmation;
