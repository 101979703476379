import React, { useState, useEffect } from "react";
// assets
import iconUp from "assets/images/icon-arrow-up-gray.svg";
import iconDown from "assets/images/icon-arrow-down-gray.svg";

const getInputWrapperClassNames = (timeIsOpenPlaceholder, error, disabled) => {
    const classNames = ["app-time-picker__input-wrapper"];
    if (timeIsOpenPlaceholder) classNames.push("app-time-picker__input-wrapper--focused");
    if (error) classNames.push("app-time-picker__input-wrapper--error");
    if (disabled) classNames.push("app-time-picker__input-wrapper--disabled") 
    return classNames.join(" ");
};

const getInputClassNames = (disabled) => {
    const classNames = ["app-time-picker__input"];
    if (disabled) classNames.push("app-time-picker__input--disabled");
    return classNames.join(" ");
}

const getAmPmClassNames = (type, ampm) => {
    const classNames = ["app-time-picker__ampm"];
    if (type === ampm.toLowerCase()) classNames.push("app-time-picker__ampm--active");
    return classNames.join(" ");
};

const AppTimePicker = ({ placeholder, onFocus, timeIsOpenPlaceholder, valueFunc, startTime, error, touched, onBlur, disabled }) => {
    const [inputValue, setInputValue] = useState(
        startTime
            ? startTime
            : {
                  hours: "00",
                  minutes: "00",
                  ampm: "AM",
              }
    );

    useEffect(() => {
        if (valueFunc && touched) {
            valueFunc(inputValue);
        }
    }, [inputValue, valueFunc, touched]);

    return (
        <div className="app-time-picker">
            <div className={getInputWrapperClassNames(timeIsOpenPlaceholder === placeholder, error, disabled)}>
                <input
                    className={getInputClassNames(disabled)}
                    value={touched && inputValue.hours && inputValue.minutes && inputValue.ampm ? `${inputValue.hours}:${inputValue.minutes} ${inputValue.ampm.toUpperCase()}` : ""}
                    onFocus={(e) => !disabled ? onFocus(e, placeholder) : null}
                    placeholder={placeholder}
                    readOnly
                    onBlur={onBlur}
                />
                {touched && (inputValue.hours || inputValue.minutes || inputValue.ampm) && timeIsOpenPlaceholder !== placeholder ? <div className="app-time-picker__placeholder-label">{placeholder}</div> : null}
            </div>
            {error ? <span className="app-time-picker__error-message">{error}</span> : null}
            {timeIsOpenPlaceholder === placeholder ? (
                <div className="app-time-picker__wrapper" onClick={(e) => onFocus(e, placeholder)}>
                    <div className="app-time-picker__wrapper-column">
                        <button
                            className="app-time-picker__arrow-button"
                            onClick={() => {
                                if (parseInt(inputValue.hours) < 12) {
                                    setInputValue({
                                        ...inputValue,
                                        hours: ("00" + JSON.stringify(parseInt(inputValue.hours) + 1)).slice(-2),
                                    });
                                } else if (parseInt(inputValue.hours) === 12) {
                                    setInputValue({
                                        ...inputValue,
                                        hours: "01",
                                    });
                                }
                            }}>
                            <img alt="" src={iconUp} />
                        </button>
                        <input
                            className="app-time-picker__value-input"
                            onChange={(e) => {
                                if (parseInt(e.target.value) <= 12) {
                                    setInputValue({
                                        ...inputValue,
                                        hours: ("00" + e.target.value).slice(-2),
                                    });
                                }
                            }}
                            value={inputValue.hours}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        />
                        <button
                            className="app-time-picker__arrow-button"
                            onClick={() => {
                                if (parseInt(inputValue.hours) > 1) {
                                    setInputValue({
                                        ...inputValue,
                                        hours: ("00" + JSON.stringify(parseInt(inputValue.hours) - 1)).slice(-2),
                                    });
                                } else if (parseInt(inputValue.hours) === 1) {
                                    setInputValue({
                                        ...inputValue,
                                        hours: "12",
                                    });
                                }
                            }}>
                            <img alt="" src={iconDown} />
                        </button>
                    </div>
                    <div className="app-time-picker__wrapper-column">
                        <div className="app-time-picker__semicolon">:</div>
                    </div>
                    <div className="app-time-picker__wrapper-column">
                        <button
                            className="app-time-picker__arrow-button"
                            onClick={() => {
                                if (parseInt(inputValue.minutes) < 59) {
                                    setInputValue({
                                        ...inputValue,
                                        minutes: ("00" + JSON.stringify(parseInt(inputValue.minutes) + 1)).slice(-2),
                                    });
                                } else if (parseInt(inputValue.minutes) === 59) {
                                    setInputValue({
                                        ...inputValue,
                                        minutes: "00",
                                    });
                                }
                            }}>
                            <img alt="" src={iconUp} />
                        </button>
                        <input
                            className="app-time-picker__value-input"
                            onChange={(e) => {
                                if (parseInt(e.target.value) <= 59) {
                                    setInputValue({
                                        ...inputValue,
                                        minutes: ("00" + e.target.value).slice(-2),
                                    });
                                }
                            }}
                            value={inputValue.minutes}
                        />
                        <button
                            className="app-time-picker__arrow-button"
                            onClick={() => {
                                if (parseInt(inputValue.minutes) > 0) {
                                    setInputValue({
                                        ...inputValue,
                                        minutes: ("00" + JSON.stringify(parseInt(inputValue.minutes) - 1)).slice(-2),
                                    });
                                } else if (parseInt(inputValue.minutes) === 0) {
                                    setInputValue({
                                        ...inputValue,
                                        minutes: "59",
                                    });
                                }
                            }}>
                            <img alt="" src={iconDown} />
                        </button>
                    </div>
                    <div className="app-time-picker__wrapper-column" />
                    <div className="app-time-picker__wrapper-column">
                        <button
                            className={getAmPmClassNames("am", inputValue.ampm)}
                            onClick={() =>
                                setInputValue({
                                    ...inputValue,
                                    ampm: "am",
                                })
                            }>
                            <span>AM</span>
                        </button>
                        <div className="app-time-picker__ampm-divider" />
                        <button
                            className={getAmPmClassNames("pm", inputValue.ampm)}
                            onClick={() =>
                                setInputValue({
                                    ...inputValue,
                                    ampm: "pm",
                                })
                            }>
                            <span>PM</span>
                        </button>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default AppTimePicker;
