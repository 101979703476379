import React from "react";
import { BsUpload } from "react-icons/bs";

const getLabelClassNames = (error) => {
    const classNames = ["app-input-upload-attachment__label"];
    if (error) classNames.push("app-input-upload-attachment__label--error");
    return classNames.join(" ");
}

const AppInputUploadAttachment = ({ placeholder, onChange, value, accept, error }) => {
    return (
        <div className="app-input-upload-attachment">
            <label htmlFor="appInputUploadAttachment" className={getLabelClassNames(error)}>
                {value ? <div className="app-input-upload-attachment__value">{value}</div> : <div className="app-input-upload-attachment__placeholder">{placeholder}</div>}
                <div className="app-input-upload-attachment__upload-icon">
                    <BsUpload size={14} />
                </div>
            </label>
            {error ? <div className="app-input-upload-attachment--error">{error}</div> : null}
            <input type="file" accept={accept} id="appInputUploadAttachment" className="app-input-upload-attachment__input" onChange={onChange} />
        </div>
    );
};

export default AppInputUploadAttachment;
