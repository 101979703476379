// libraries
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTabLink from "components/app-tab-link";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppReactTable from "components/app-react-table";
import AppPaginate from "components/app-paginate";
import appToast from "components/app-toast";
// service
import api from "services/api";
// common
import { sanitizeError } from "common/utilities";
// hooks
import usePrevious from "hooks/use-previous";
// route
import pathnames from "routes/pathnames";
// assets
import iconSearch from "assets/images/components/app-input/icon-search.svg";
import iconEdit from "assets/images/icon-edit.svg";
import iconTailedArrowUp from "assets/images/icon-tailed-arrow-up.svg";

const appTabLink = [
    {
        label: "Position",
        pathname: pathnames.pageCompanyBenefitsPosition,
    },
    {
        label: "Benefit",
        pathname: pathnames.pageCompanyBenefitsBenefit,
    },
];

const breadcrumb = [
    {
        label: "Company Benefits",
    },
    {
        label: "Benefit",
    },
];

const PageCompanyBenefitsBenefit = () => {
    const history = useHistory();
    const [tableSearchFilter, setTableSearchFilter] = useState({
        pageNo: 0,
        pageSize: 5,
        searchValue: "",
        sortBy: "",
        sortDir: "asc",
    });
    const [tableData, setTableData] = useState({
        benefitList: [],
        last: null,
        pageNo: 0,
        pageSize: 5,
        totalElements: null,
        totalPages: 1,
    });
    const prevValueSortHeader = usePrevious({
        sortBy: tableSearchFilter.sortBy,
        sortDir: tableSearchFilter.sortDir,
    });

    useEffect(() => {
        const getTableData = async () => {
            try {
                const response = await api.get.companyBenefitListSearchedBenefit(tableSearchFilter);
                setTableData(response.data.result);
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        };
        getTableData();
    }, [tableSearchFilter]);

    const headerArrowRotate = (active) => {
        const classNames = ["react-table__arrow-icon"];
        if (active && tableSearchFilter.sortDir === "desc") classNames.push("react-table__arrow-icon-down");
        if (active) classNames.push("react-table__arrow-icon--active");

        return classNames.join(" ");
    };

    const headerFilterClick = (header) => {
        let column = header.column;
        if (prevValueSortHeader && prevValueSortHeader.sortBy === column.searchFilterValue) {
            if (prevValueSortHeader.sortDir === "asc") {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "desc",
                    pageNo: 0,
                });
            } else {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "asc",
                    pageNo: 0,
                });
            }
        } else {
            setTableSearchFilter({
                ...tableSearchFilter,
                sortBy: column.searchFilterValue,
                sortDir: "asc",
                pageNo: 0,
            });
        }
    };
    const tableColumn = [
        {
            id: "attachedBenefit",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__attached-benefit-header react-table__header">
                        Attached Benefit
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <span className="react-table__attached-benefit-data">{row.row.original.packageName}</span>;
            },
            maxWidth: 80,
            searchFilterValue: "package_name",
        },
        {
            id: "position",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Position
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <div className="react-table__attached-benefit-position">{row.row.original.linkedPositions.split(",").join(", ")}</div>;
            },
            searchFilterValue: "linked_positions",
        },
        {
            id: "button",
            Cell: () => {
                return <img className="react-table__edit-icon" src={iconEdit} alt="" />;
            },
            maxWidth: 20,
        },
    ];

    const searchKeywordTyped = (value) => {
        setTableSearchFilter({
            pageNo: 0,
            pageSize: 5,
            searchValue: value,
            sortBy: "",
            sortDir: "asc",
        });
    };

    const searchBarOnChange = (e) => {
        searchKeywordTyped(e.target.value);
    };

    const handleKeypress = (e) => {
        if (e.code === "Enter") {
            searchKeywordTyped(e.target.value);
        }
    };

    const onPageChange = (e) => {
        setTableSearchFilter({
            ...tableSearchFilter,
            pageNo: e.selected,
        });
    };

    const onRowSelect = (row) => {
        history.push(pathnames.pageEditBenefitPackage.replace(":pathPackageName", "value_" + row.packageName));
    };

    return (
        <AppPagesLayout>
            <div className="page-benefits-benefit">
                <div className="page-benefits-benefit__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>
                <div className="page-benefits-benefit__tab-wrapper">
                    <AppTabLink tabList={appTabLink} />
                </div>

                <div className="page-benefits-benefit__row">
                    <div className="page-benefits-benefit__searchBar-wrapper">
                        <AppInput leftSrc={iconSearch} placeholder="Search keyword" onChange={_.debounce((e) => searchBarOnChange(e), 1000)} onKeyPress={handleKeypress} />
                    </div>
                    <div className="page-benefits-benefit__button-wrapper">
                        <AppButton size="s" buttonIcon={<AiOutlinePlus size={12} />} label="New Benefit" onClick={() => history.push(pathnames.pageCreateBenefitPackage)} />
                    </div>
                </div>
                <div className="page-benefits-benefit__table-wrapper">
                    <AppReactTable onClickRow={onRowSelect} columns={tableColumn} data={tableData.benefitList} searchKeyword={tableSearchFilter.searchValue} />
                </div>
                <div className="page-benefits-benefit__pagination">
                    <AppPaginate onPageChange={onPageChange} pageCount={tableData.totalPages} forcePage={tableSearchFilter.pageNo} />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageCompanyBenefitsBenefit;
