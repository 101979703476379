// libraries
import React from "react";
import { useTable, useFlexLayout } from "react-table";

const getTableHeaderRowClassNames = (idSelectAll) => {
    const classNames = ["header__row"];
    if (idSelectAll) classNames.push("header__selected-all");
    return classNames.join(" ");
};

const AppReactTable = ({ columns, data, onClickRow, checkedIdArray, idSelectAll, totalItems, headerSelectAllChildren, searchKeyword, customEmptyListMessage }) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
        },
        useFlexLayout
    );

    const tableHeader = (column, index, idSelectAll, totalItems) => {
        if (idSelectAll) {
            if (index === 0) {
                return column.render("Header");
            } else if (index === 1) {
                return <div>{idSelectAll} records selected</div>;
            } else {
                return null;
            }
        } else {
            return column.render("Header");
        }
    };

    return (
        <div className="app-react-table">
            <table className="app-react-table__table" {...getTableProps()}>
                <div className="header">
                    {idSelectAll && headerSelectAllChildren ? (
                        <div className="header__row-header-buttons">
                            <div className="header__row-header-divider" />
                            <div>{headerSelectAllChildren}</div>
                        </div>
                    ) : null}
                    {headerGroups.map((headerGroup) => (
                        <tr className={getTableHeaderRowClassNames(idSelectAll)} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, index) => (
                                <th className="header__row-header" {...column.getHeaderProps()}>
                                    {tableHeader(column, index, idSelectAll, totalItems)}
                                </th>
                            ))}
                        </tr>
                    ))}
                </div>
                {rows.length ? (
                    <tr {...getTableBodyProps()} className="body">
                        {rows.map((row, i) => {
                            prepareRow(row);

                            return (
                                <td {...row.getRowProps()} className="body__row" onClick={() => (onClickRow ? onClickRow(row.original) : null)}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <div className="body__item" {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </div>
                                        );
                                    })}
                                </td>
                            );
                        })}
                    </tr>
                ) : (
                    <div className="app-react-table__empty-body">
                        <div className="app-react-table__empty-body-row">
                            <div className="app-react-table__empty-body-detail-1">No records found</div>
                            <div className="app-react-table__empty-body-detail-2">{customEmptyListMessage ? customEmptyListMessage : searchKeyword ? `Keyword does not match with the records` : `You don't have any records yet`}</div>
                        </div>
                    </div>
                )}
            </table>
        </div>
    );
};

export default AppReactTable;
