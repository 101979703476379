// libraries
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTabLink from "components/app-tab-link";
import AppReactTable from "components/app-react-table";
import appToast from "components/app-toast";
import AppButton from "components/app-button";
// common
import { sanitizeError } from "common/utilities";
// services
import api from "services/api";
// routes
import employeePathnames from "routes/employee-pathnames";
// assets
import iconCalendar from "assets/images/components/app-button/icon-calendar.svg";
// new claim
import AppEmployeeCreateNewClaim from "./page-new-claim";

const breadcrumb = [
    {
        label: "Claim Management",
    },
    {
        label: "Claim Summary",
    },
];

const PageEmployeeClaimSummary = () => {
    const [tableData, setTableData] = useState([]);
    const profile = useSelector((state) => state.auth);
    const createClaimModal = useRef(null);
    const staffId = profile ? profile.staffID : "";

    const appTabLink = [
        {
            label: "Claim Summary",
            pathname: employeePathnames.pageEmployeeClaimSummary,
        },
        {
            label: "Claim History",
            pathname: employeePathnames.pageEmployeeClaimHistory,
        },
        {
            label: "Pending Claim",
            pathname: employeePathnames.pageEmployeePendingClaim,
        },
    ];

    const getStaffClaimSummary = async (staffId) => {
        try {
            const response = await api.post.claimManagementClaimSummary(staffId);
            setTableData(response.data.result);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    useEffect(() => {
        getStaffClaimSummary(staffId);
    }, [staffId]);

    const tableColumn = [
        {
            id: "claimType",
            Header: () => {
                return <span className="react-table__claim-type-header">Claim Type</span>;
            },
            Cell: (row) => {
                return <span className="react-table__claim-type-row">{row.row.original.claimType}</span>;
            },
        },
        {
            id: "entitledAmount",
            Header: () => {
                return <span>Entitled Amount (RM)</span>;
            },
            Cell: (row) => {
                if (row.row.original.claimType.toLowerCase() === "others") {
                    return (
                        <div className="react-table__amount-width">
                            <div className="react-table__amount-row">
                                <span>subject to approval</span>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className="react-table__amount-width">
                            <div className="react-table__amount-row">
                                <span>{parseFloat(row.row.original.entitledAmount) ? parseFloat(row.row.original.entitledAmount).toFixed(2) : row.row.original.entitledAmount}</span>
                            </div>
                        </div>
                    );
                }
            },
            searchFilterValue: "entitled_amount",
            maxWidth: 80,
        },
        {
            id: "balancedAmount",
            Header: () => {
                return <span>Balance Amount (RM)</span>;
            },
            Cell: (row) => {
                if (row.row.original.claimType.toLowerCase() === "others") {
                    return (
                        <div className="react-table__amount-width">
                            <div className="react-table__amount-row">
                                <span>subject to approval</span>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className="react-table__amount-width">
                            <div className="react-table__amount-row">
                                <span>{parseFloat(row.row.original.balanceAmount) ? parseFloat(row.row.original.balanceAmount).toFixed(2) : row.row.original.balanceAmount}</span>
                            </div>
                        </div>
                    );
                }
            },
            maxWidth: 80,
        },
    ];

    return (
        <AppPagesLayout>
            <div className="page-claim-summary">
                <AppEmployeeCreateNewClaim ref={createClaimModal} onSuccess={() => getStaffClaimSummary(staffId)} />
                <div className="page-claim-summary__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>

                <div className="page-claim-summary__button-row">
                    <div className="page-claim-summary__new-button">
                        <AppButton buttonIcon={<img src={iconCalendar} alt="" className="page-claim-summary__calendar-icon" />} size="s" label="New Claim" onClick={() => createClaimModal.current.createClaimModalIsOpen()} />
                    </div>
                </div>

                <div className="page-claim-summary__tab-wrapper">
                    <AppTabLink tabList={appTabLink} />
                </div>

                <div className="page-claim-summary__table-wrapper">
                    <AppReactTable columns={tableColumn} data={tableData} customEmptyListMessage="Looks like you don't have any benefits yet , please contact the HR team for further clarification" />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageEmployeeClaimSummary;
