// libraries
import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { compose } from "redux";
// components
import AppLayout from "components/app-layout";
// routes
import routes from "routes/routes";
// pathnames
import pathnames from "routes/pathnames";
// contexts
import withAuthenticate from "contexts/with-authenticate";

const App = () => {
    return (
        <Router history={createBrowserHistory()}>
            <AppLayout>
                <Switch>
                    {routes.map((route) => {
                        const EnhanceRoute = compose(withAuthenticate)(route.component, route.authentication, route.role);
                        return <Route key={route.path} path={route.path} exact={true} render={(props) => <EnhanceRoute {...props} />} />;
                    })}
                    <Redirect to={pathnames.pageLogin} />
                </Switch>
            </AppLayout>
        </Router>
    );
};

export default App;
