// libraries
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { BsUpload } from "react-icons/bs";
import AppModal from "components/app-modal";
import { useDropzone } from "react-dropzone";
import { CgCloseO } from "react-icons/cg";
import { BsExclamationCircle } from "react-icons/bs";
import ReactTooltip from "react-tooltip";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppReactTable from "components/app-react-table";
import AppPaginate from "components/app-paginate";
import appToast from "components/app-toast";
import AppCheckbox from "components/app-checkbox";
// service
import api from "services/api";
// common
import { sanitizeError } from "common/utilities";
// hooks
import usePrevious from "hooks/use-previous";
// route
import pathnames from "routes/pathnames";
// assets
import iconEmptyFile from "assets/images/icon-empty-file-format.svg";
import iconSearch from "assets/images/components/app-input/icon-search.svg";
import iconCheck from "assets/images/icon-check-circle.svg";
import iconTailedArrowUp from "assets/images/icon-tailed-arrow-up.svg";

const breadcrumb = [
    {
        label: "Payslip",
    },
];

const buttonInitialStates = {
    confirmUploadConfirmButton: {
        disabled: false,
        label: "Confirm",
    },
};

const PagePayslip = () => {
    const history = useHistory();
    const [tableSearchFilter, setTableSearchFilter] = useState({
        pageNo: 0,
        pageSize: 5,
        searchValue: "",
        sortBy: "",
        sortDir: "asc",
    });
    const [tableData, setTableData] = useState({
        content: [],
        pageNo: 0,
        pageSize: 5,
        totalElements: null,
        totalPages: 1,
        last: null,
    });
    const prevValueSortHeader = usePrevious({
        sortBy: tableSearchFilter.sortBy,
        sortDir: tableSearchFilter.sortDir,
    });
    const [uploadPayslipFile, setUploadPayslipFile] = useState([]);
    const [fileNameValidateResponse, setFileNameValidateResponse] = useState([]);
    const [notifyEmployee, setNotifyEmployee] = useState(true);
    const [buttonState, setButtonState] = useState(buttonInitialStates);
    const uploadFileType = "application/pdf";

    useEffect(() => {
        const getTableData = async () => {
            try {
                const response = await api.get.payslipSearchEmployee(tableSearchFilter);
                setTableData(response.data.result);
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        };
        getTableData();
    }, [tableSearchFilter]);

    const headerArrowRotate = (active) => {
        const classNames = ["react-table__arrow-icon"];
        if (active && tableSearchFilter.sortDir === "desc") classNames.push("react-table__arrow-icon-down");
        if (active) classNames.push("react-table__arrow-icon--active");

        return classNames.join(" ");
    };

    const headerFilterClick = (header) => {
        let column = header.column;
        if (prevValueSortHeader && prevValueSortHeader.sortBy === column.searchFilterValue) {
            if (prevValueSortHeader.sortDir === "asc") {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "desc",
                    pageNo: 0,
                });
            } else {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "asc",
                    pageNo: 0,
                });
            }
        } else {
            setTableSearchFilter({
                ...tableSearchFilter,
                sortBy: column.searchFilterValue,
                sortDir: "asc",
                pageNo: 0,
            });
        }
    };

    const tableColumn = [
        {
            id: "staffUserName",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__staff-id-header react-table__header">
                        Username
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <span className="react-table__staff-id-data react-table__">{row.row.original.staffUserName}</span>;
            },
            searchFilterValue: "user_name",
        },
        {
            id: "staffID",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Employee ID
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            accessor: "staffID",
            searchFilterValue: "staff_id",
        },
    ];

    const removeValidatedFileNames = (i) => {
        setFileNameValidateResponse(fileNameValidateResponse.filter((item, index) => index !== i));
    };

    const tableColumnPayslip = [
        {
            id: "payslip",
            Header: () => {
                return <span className="react-table__filenames-header">Payslip</span>;
            },
            Cell: (row) => {
                return (
                    <div className="react-table__filenames-data">
                        <span>{row.row.original.fileName}</span>
                    </div>
                );
            },
        },
        {
            id: "staffID",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Employee ID
                    </span>
                );
            },
            Cell: (row) => {
                return <div className="react-table__staff-id-data">{row.row.original.staffId}</div>;
            },
            width: 50,
        },
        {
            id: "errorMessage",
            Cell: (row) => {
                if (row.row.original.errorMsg.length) {
                    return (
                        <div>
                            <ReactTooltip id={`${row.row.index}_${row.row.original.fileName}`} className="react-table__tooltip" arrowColor="white">
                                <span className="react-table__tooltip-error-message">{row.row.original.errorMsg}</span>
                            </ReactTooltip>
                            <BsExclamationCircle data-tip data-for={`${row.row.index}_${row.row.original.fileName}`} color="#dc4c4c" size={16} />
                        </div>
                    );
                } else {
                    return null;
                }
            },
        },
        {
            id: "button",
            Cell: (row) => {
                return (
                    <span
                        onClick={() => {
                            removeValidatedFileNames(row.row.index);
                        }}
                        className="react-table__delete-icon">
                        <CgCloseO color="#dc4c4c" size={16} />
                    </span>
                );
            },
        },
    ];

    const searchKeywordTyped = (value) => {
        setTableSearchFilter({
            pageNo: 0,
            pageSize: 5,
            searchValue: value,
            sortBy: "",
            sortDir: "asc",
        });
    };

    const searchBarOnChange = (e) => {
        searchKeywordTyped(e.target.value);
    };

    const handleKeypress = (e) => {
        if (e.code === "Enter") {
            searchKeywordTyped(e.target.value);
        }
    };

    const onPageChange = (e) => {
        setTableSearchFilter({
            ...tableSearchFilter,
            pageNo: e.selected,
        });
    };

    const onRowSelect = (row) => {
        history.push(pathnames.pageEmployeePayslip.replace(":pathStaffId", "value_" + parseInt(row.staffID)));
    };

    const [modalIsOpen, setModalIsOpen] = useState({
        uploadingModal: false,
        confirmUploadModal: false,
    });

    const onDrop = (newFiles) => {
        setUploadPayslipFile([...uploadPayslipFile, ...newFiles]);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const removeUploadedFile = (i) => {
        setUploadPayslipFile(uploadPayslipFile.filter((item, index) => index !== i));
    };

    const validateFilesUploadName = async () => {
        try {
            let payload = {
                fileName: uploadPayslipFile.map((e) => e.name).join(","),
            };
            const response = await api.get.payslipValidateFileNames(payload);
            let result = response.data.result;
            const sortResponseToErrorAbove = [...result.filter((ele) => ele.errorMsg.length > 0), ...result.filter((ele) => ele.errorMsg.length === 0)];
            setFileNameValidateResponse(sortResponseToErrorAbove);
            setModalIsOpen({
                uploadingModal: false,
                confirmUploadModal: true,
            });
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    const confirmPayslipUploadConfirm = async () => {
        setButtonState({
            confirmUploadConfirmButton: {
                disabled: true,
                label: "Sending..",
            },
        });
        try {
            const selectedUploadFiles = uploadPayslipFile.filter((file) => fileNameValidateResponse.find((selected) => selected.fileName === file.name));
            const formData = new FormData();
            selectedUploadFiles.forEach((file) => {
                formData.append("file", file);
            });
            let params = {
                sendEmail: notifyEmployee ? 1 : 0,
            };
            await api.post.payslipBulkupload(formData, params);
            setModalIsOpen({
                uploadingModal: false,
                confirmUploadModal: false,
            });
            setUploadPayslipFile([]);
            setFileNameValidateResponse([]);
            setButtonState(buttonInitialStates);
            appToast("Payslip has been uploaded and sent out successfully.", true);
        } catch (error) {
            setButtonState(buttonInitialStates);
            appToast(sanitizeError(error), false);
        }
    };

    return (
        <AppPagesLayout>
            <div className="page-payslip">
                <AppModal
                    title="Upload Payslip"
                    bodyPadding={false}
                    isOpenModal={modalIsOpen.uploadingModal}
                    onRequestClose={() => {
                        setModalIsOpen({
                            ...modalIsOpen,
                            uploadingModal: false,
                        });
                        setUploadPayslipFile([]);
                    }}>
                    <div className="page-payslip__bulk-upload">
                        <div className="bulk-upload__upload-contents">
                            <input className="bulk-upload__input" {...getInputProps()} />
                            <div {...getRootProps({ className: "dropzone" })}>
                                <div className="bulk-upload__upload-container">
                                    <BsUpload size={32} />
                                    <span className="bulk-upload__upload-title">Drag and Drop file</span>
                                    <span className="bulk-upload__upload-subtitle">or</span>
                                    <div className="bulk-upload__button-wrapper">
                                        <AppButton size="s" buttonType="outline" label="Browse File" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bulk-upload__files-contents">
                            <div className="bulk-upload__files-contents-scroll">
                                <div className="bulk-upload__files-contents-header">
                                    <span className="bulk-upload__files-content-header-title">Uploaded file</span>
                                    {uploadPayslipFile.every((o, i) => o.type === uploadFileType) ? null : <span className="bulk-upload__files-content-header-error">Please remove invalid file to proceed.</span>}
                                </div>
                                <div className="bulk-upload__files-wrapper">
                                    {uploadPayslipFile.map((o, i) => {
                                        return (
                                            <div key={i} className="bulk-upload__files-row">
                                                <div className="bulk-upload__files-upload-details">
                                                    <div className="bulk-upload__file-icon-wrapper">
                                                        <img alt="" src={iconEmptyFile} />
                                                        <div className="bulk-upload__file-icon-name">
                                                            <span>{o.name.toUpperCase().split(".").slice(-1)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="bulk-upload__files-upload-contents">
                                                        <div className="bulk-upload__files-upload-title">{o.name}</div>
                                                        <div className="bulk-upload__files-upload-size">{(o.size / 1000000).toFixed(2)} MB</div>
                                                    </div>
                                                </div>
                                                <div className="bulk-upload__files-upload-logo-symbol">
                                                    <span>{o.type === uploadFileType ? <img alt="" src={iconCheck} /> : <BsExclamationCircle color="#dc4c4c" size={16} />}</span>
                                                    <span className="bulk-upload__remove-icon" onClick={() => removeUploadedFile(i)}>
                                                        <CgCloseO size={16} />
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="bulk-upload__files-button-row">
                                <div className="bulk-upload__files-button-wrapper">
                                    <AppButton
                                        size="s"
                                        label="Cancel"
                                        buttonType="outline"
                                        onClick={() => {
                                            setModalIsOpen({
                                                ...modalIsOpen,
                                                uploadingModal: false,
                                            });
                                            setUploadPayslipFile([]);
                                        }}
                                    />
                                </div>
                                <div className="bulk-upload__files-button-wrapper">
                                    <AppButton size="s" label="Confirm" disabled={uploadPayslipFile.length === 0 || uploadPayslipFile.some((o, i) => o.type !== uploadFileType)} onClick={validateFilesUploadName} />
                                </div>
                            </div>
                        </div>
                    </div>
                </AppModal>
                <AppModal
                    title="Confirm Payslip Upload"
                    isOpenModal={modalIsOpen.confirmUploadModal}
                    onRequestClose={() => {
                        if (!buttonState.confirmUploadConfirmButton.disabled) {
                            setModalIsOpen({
                                uploadingModal: true,
                                confirmUploadModal: false,
                            });
                        }
                    }}>
                    <div className="page-payslip__confirm-upload">
                        <div className="confirm-upload__header">
                            <div className="confirm-upload__error">{!fileNameValidateResponse.every((obj) => obj.errorMsg.length === 0) ? <span>Please take note on the failed uploaded pdf files if you do not wish to proceed</span> : null}</div>
                            <div className="confirm-upload__notify">
                                <span className="confirm-upload__checkbox">
                                    <AppCheckbox onChange={() => setNotifyEmployee(!notifyEmployee)} checked={notifyEmployee} />
                                </span>
                                <div className="confirm-upload__notify-employee">Notify Employee</div>
                            </div>
                        </div>
                        <div className="confirm-upload__react-table-wrapper">
                            <AppReactTable columns={tableColumnPayslip} data={fileNameValidateResponse} bodyStyle={{ maxHeight: "250px", overflowY: "scroll" }} />
                        </div>

                        <div className="confirm-upload__files-button-row">
                            <div className="confirm-upload__files-button-wrapper">
                                <AppButton
                                    size="s"
                                    label="Cancel"
                                    buttonType="outline"
                                    disabled={buttonState.confirmUploadConfirmButton.disabled}
                                    onClick={() => {
                                        setModalIsOpen({
                                            uploadingModal: true,
                                            confirmUploadModal: false,
                                        });
                                    }}
                                />
                            </div>
                            <div className="confirm-upload__files-button-wrapper">
                                <AppButton
                                    size="s"
                                    label={buttonState.confirmUploadConfirmButton.label}
                                    onClick={confirmPayslipUploadConfirm}
                                    disabled={!fileNameValidateResponse.every((obj) => obj.errorMsg.length === 0 || obj.errorMsg.every((ele) => ele.includes("overwrite"))) || buttonState.confirmUploadConfirmButton.disabled}
                                />
                            </div>
                        </div>
                    </div>
                </AppModal>

                <div className="page-payslip__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>

                <div className="page-payslip__row">
                    <div className="page-payslip__searchBar-wrapper">
                        <AppInput leftSrc={iconSearch} placeholder="Search keyword" onChange={_.debounce((e) => searchBarOnChange(e), 1000)} onKeyPress={handleKeypress} />
                    </div>
                    <div className="page-payslip__button-wrapper">
                        <AppButton
                            size="s"
                            buttonIcon={<BsUpload size={16} />}
                            label="Upload Payslip"
                            onClick={() => {
                                setModalIsOpen({
                                    ...modalIsOpen,
                                    uploadingModal: true,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="page-payslip__table-wrapper">
                    <AppReactTable onClickRow={onRowSelect} columns={tableColumn} data={tableData.content} searchKeyword={tableSearchFilter.searchValue} />
                </div>
                <div className="page-payslip__pagination">
                    <AppPaginate onPageChange={onPageChange} pageCount={tableData.totalPages} forcePage={tableSearchFilter.pageNo} />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PagePayslip;
