import React, { useEffect, useState, forwardRef } from "react";

const getInputWrapperClassNames = (error, focused, disabled) => {
    const classNames = ["app-input-with-label__wrapper"];
    if (error) classNames.push("app-input-with-label__input-error");
    if (focused) classNames.push("app-input-with-label__focused");
    if (disabled) classNames.push("app-input-with-label__disabled");
    return classNames.join(" ");
};

const getInputClassNames = (onClick) => {
    const classNames = ["app-input-with-label__input"];
    if (onClick) classNames.push("app-input-with-label__onClick");
    return classNames.join(" ");
};

const AppInputWithLabel = forwardRef(({ value, error, placeholder, onChange, onFocus, onBlur, disabled, label, onClick, maxLength, onKeyPress, type }, ref) => {
    const [showlabel, setShowlabel] = useState(false);
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        if ((value || value === 0) && !focused) {
            setShowlabel(true);
        } else {
            setShowlabel(false);
        }
    }, [value, focused]);

    const inputOnFocusHandler = () => {
        setFocused(true);
        setShowlabel(false);
        if (onFocus) {
            onFocus();
        }
    };

    const inputOnBlurHandler = () => {
        setFocused(false);
        if (value || value === 0) {
            setShowlabel(true);
        }
        if (onBlur) {
            onBlur();
        }
    };

    return (
        <div className="app-input-with-label">
            <div className={getInputWrapperClassNames(error, focused, disabled)} onClick={onClick ? onClick : null}>
                <input type={type} ref={ref} disabled={disabled} onChange={onChange} value={value} onFocus={inputOnFocusHandler} onBlur={inputOnBlurHandler} className={getInputClassNames(onClick)} placeholder={placeholder} maxLength={maxLength} onKeyPress={onKeyPress}/>
                {showlabel ? <span className="app-input-with-label__label">{label ? label : placeholder}</span> : null}
            </div>
            {error ? <span className="app-input-with-label__error-message">{error}</span> : null}
        </div>
    );
});

export default AppInputWithLabel;
