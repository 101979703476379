import React, { useContext, useEffect, useState } from "react";
import api from "services/api";
import appToast from "components/app-toast";
import { sanitizeError } from "common/utilities";
import { AppLetterInputContext } from "./page-send-letter";
import AppInputResponsive from "components/app-input-responsive";

const AppLetterDefaultTemplate = () => {
    const { letterInput, setLetterInput } = useContext(AppLetterInputContext);
    const [positionLevelList, setPositionLevelList] = useState([]);
    const [posLevel, setPosLevel] = useState();

    useEffect(() => {
        const onHandleGetLevel = async () => {
            try {
                const response = await api.get.getPositionLevel();
                const labelPositionLevel = response.data.result
                    .sort((a, b) => (a.id > b.id ? 1 : -1))
                    .map((o) => {
                        return {
                            ...o,
                            label: o.level,
                            value: o.id,
                            description: o.description,
                        };
                    });
                setPositionLevelList(labelPositionLevel);
            } catch (error) {
                const errorMessage = sanitizeError(error);
                appToast(errorMessage, false, 300);
            }
        };
        onHandleGetLevel();
    }, []);

    useEffect(() => {
        const setLevelId = () => {
            const item = positionLevelList.find((d) => d.label === letterInput.positionLevelId);
            if (item) {
                setPosLevel(item.value);
            }
        };
        setLevelId();
    }, [positionLevelList, letterInput]);

    const onHandleSelectPositionLevel = (event) => {
        const newValue = event.target.value;
        const item = positionLevelList.find((d) => d.value === Number(newValue));

        setLetterInput({
            ...letterInput,
            positionLevelId: newValue,
            level: item.label,
        });

        setPosLevel(newValue);
    };

    const onHandleChange = (event, name) => {
        setLetterInput({
            ...letterInput,
            [name]: event.target.value,
        });
    };

    return (
        <div>
            <div style={{ fontWeight: 700 }}>CONFIRMATION OF APPOINTMENT</div>
            <br />
            <div>
                We are pleased to inform you that your work performance during your probationary period has been satisfactory and that you will be confirmed in your appointment as employment as below with effect from [
                <AppInputResponsive className="page-send-letter__input" onChange={(evt) => onHandleChange(evt, "startDate")} value={letterInput.startDate} />
                ].
            </div>
            <br />
            <div className="page-send-letter__table">
                <table>
                    <thead>
                        <tr>
                            <th>LEVEL</th>
                            <th>POSITION</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td style={{ textAlign: "left", width: "100px" }}>
                                <select className="page-send-letter__input page-send-letter__input--bold page-send-letter__dropdown" onChange={(event) => onHandleSelectPositionLevel(event)} value={posLevel}>
                                    <option value={""}></option>
                                    {positionLevelList.map((d, i) => {
                                        return (
                                            <option key={i} value={d.value}>
                                                {d.label}
                                            </option>
                                        );
                                    })}
                                </select>
                            </td>
                            <td>
                                <AppInputResponsive className="page-send-letter__input page-send-letter__input--bold" onChange={(evt) => onHandleChange(evt, "position")} value={letterInput.position} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <div>
                All other terms and conditions of your employment as specified in your Letter of Offer dated [
                <AppInputResponsive className="page-send-letter__input" onChange={(evt) => onHandleChange(evt, "lastOfferLetter")} value={letterInput.lastOfferLetter} />] remain unchanged.
            </div>
            <br />
            <div>We take this opportunity to acknowledge and to thank you for your dedication, effort and contributions, and we wish you continuous success with Altecflex Sdn. Bhd.</div>
            <br />
            <div className="page-send-letter__table">
                <table>
                    <thead>
                        <tr>
                            <th>REF</th>
                            <th>LEVEL</th>
                            <th>DESCRIPTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {positionLevelList.map((d, i) => {
                            return (
                                <tr key={i}>
                                    <td>{d.value + 1}</td>
                                    <td>{d.label}</td>
                                    <td>{d.description}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AppLetterDefaultTemplate;
