import React from "react";
// assets
import iconEmptyFile from "assets/images/icon-empty-file-format.svg";

const getAttachmentWrapper = (onClick) => {
    const classNames = ["app-attachment-image"];
    if (onClick) classNames.push("app-attachment-image--hover-cursor");
    return classNames.join(" ");
}

const AppAttachmentImage = ({ fileType, onClick }) => {
    return (
        <div className={getAttachmentWrapper(onClick)} onClick={onClick}>
            <img alt="" src={iconEmptyFile} />
            <div className="app-attachment-image__file-type">{fileType?.toUpperCase()}</div>
        </div>
    );
};

export default AppAttachmentImage;
