// libraries
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { BsDownload } from "react-icons/bs";
import moment from "moment";
import { useSelector } from "react-redux";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppButton from "components/app-button";
import AppReactTable from "components/app-react-table";
import AppPaginate from "components/app-paginate";
import appToast from "components/app-toast";
import AppModal from "components/app-modal";
import AppCheckbox from "components/app-checkbox";
import AppInput from "components/app-input";
// service
import api from "services/api";
// common
import { sanitizeError } from "common/utilities";
// hooks
import usePrevious from "hooks/use-previous";
// assets
import iconTailedArrowUp from "assets/images/icon-tailed-arrow-up.svg";
import iconFilePdf from "assets/images/icon-file-pdf.svg";
import iconSearch from "assets/images/components/app-input/icon-search.svg";
import iconClose from "assets/images/icon-close-black.svg";

const breadcrumb = [
    {
        label: "Payslip",
    },
];

const PageEmployeePortalPayslip = () => {
    const profile = useSelector((state) => state.auth);
    let staffId = profile ? profile.staffID : "";
    const [tableSearchFilter, setTableSearchFilter] = useState({
        pageNo: 0,
        pageSize: 5,
        sortBy: "",
        sortDir: "asc",
        staffId: staffId,
    });
    const [tableData, setTableData] = useState({
        content: [],
        last: null,
        pageNo: 0,
        pageSize: 5,
        totalElements: null,
        totalPages: 1,
    });
    const prevValueSortHeader = usePrevious({
        sortBy: tableSearchFilter.sortBy,
        sortDir: tableSearchFilter.sortDir,
    });
    const [pdfModalOpen, setPdfModalOpen] = useState(false);
    const [idRowSelected, setIdRowSelected] = useState([]);
    const [allFilenames, setAllFilenames] = useState([]);
    const [attachmentUrlSelected, setAttachmentUrlSelected] = useState("");

    useEffect(() => {
        const getTableData = async () => {
            try {
                const response = await api.get.payslipStaffId(tableSearchFilter);
                setTableData(response.data.result);
                setAllFilenames(response.data.result.filenames.sort());
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        };
        getTableData();
    }, [tableSearchFilter]);

    const headerArrowRotate = (active) => {
        const classNames = ["react-table__arrow-icon"];
        if (active && tableSearchFilter.sortDir === "desc") classNames.push("react-table__arrow-icon-down");
        if (active) classNames.push("react-table__arrow-icon--active");

        return classNames.join(" ");
    };

    const headerFilterClick = (header) => {
        let column = header.column;
        if (prevValueSortHeader && prevValueSortHeader.sortBy === column.searchFilterValue) {
            if (prevValueSortHeader.sortDir === "asc") {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "desc",
                    pageNo: 0,
                });
            } else {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "asc",
                    pageNo: 0,
                });
            }
        } else {
            setTableSearchFilter({
                ...tableSearchFilter,
                sortBy: column.searchFilterValue,
                sortDir: "asc",
                pageNo: 0,
            });
        }
    };

    const selectAllFunction = (allFilenames) => {
        if (_.isEqual(idRowSelected, allFilenames)) {
            setIdRowSelected([]);
        } else {
            setIdRowSelected(allFilenames);
        }
    };

    const selectRowFunction = (filenames) => {
        if (idRowSelected.includes(filenames)) {
            setIdRowSelected([...idRowSelected.filter((ele) => ele !== filenames)]);
        } else {
            const sortedIdRowSelected = [...idRowSelected, filenames];
            setIdRowSelected(sortedIdRowSelected.sort());
        }
    };

    const downloadIconOnClicked = async (staffID, filenames) => {
        let payload = {
            payslipFile: [filenames],
            staffId: staffID,
        };
        try {
            const response = await api.post.payslipdownload(payload, "arraybuffer");
            // create a download anchor tag
            var downloadLink = document.createElement("a");
            downloadLink.target = "_blank";
            downloadLink.download = filenames;
            // convert downloaded data to a Blob
            var blob = new Blob([response.data], { type: "application/pdf" });
            // create an object URL from the Blob
            var URL = window.URL || window.webkitURL;
            var downloadUrl = URL.createObjectURL(blob);
            // set object URL as the anchor's href
            downloadLink.href = downloadUrl;
            // append the anchor to document body
            document.body.appendChild(downloadLink);
            // fire a click event on the anchor
            downloadLink.click();
            // cleanup: remove element and revoke object URL
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    const tableColumn = [
        {
            id: "Id",
            Header: (header) => {
                if (header.data.length) {
                    return (
                        <div className="react-table__checkbox-wrapper">
                            <AppCheckbox type="selectAll" onChange={() => selectAllFunction(allFilenames)} checked={_.isEqual(idRowSelected, allFilenames)} />
                        </div>
                    );
                } else {
                    return null;
                }
            },
            Cell: (row) => {
                return (
                    <div className="react-table__checkbox-wrapper" onClick={(e) => e.stopPropagation()}>
                        <AppCheckbox onChange={() => selectRowFunction(row.row.original.attachment)} checked={idRowSelected.includes(row.row.original.attachment)} />
                    </div>
                );
            },
            maxWidth: 50,
        },
        {
            id: "issueMonth",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Year / Month
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <span>{moment(row.row.original.issueMonth).format("YYYY/MM")}</span>;
            },
            searchFilterValue: "payslip_date",
        },
        {
            id: "attachmentPath",
            Header: () => {
                return <span className="react-table__header react-table__header--cursor-default">Attachment</span>;
            },
            Cell: (row) => {
                if (row.row.original.attachmentPath) {
                    return (
                        <div className="react-table__attachment" onClick={(e) => e.stopPropagation()}>
                            <img
                                className="react-table__attachment-icon"
                                alt=""
                                src={iconFilePdf}
                                onClick={() => {
                                    setAttachmentUrlSelected(row.row.original.attachmentPath);
                                    setPdfModalOpen(true);
                                }}
                            />
                        </div>
                    );
                } else {
                    return null;
                }
            },
        },
        {
            id: "button",
            Cell: (row) => {
                return (
                    <div className="react-table__download-icon" onClick={() => downloadIconOnClicked(row.row.original.staffID, row.row.original.attachment)}>
                        <BsDownload size={16} />
                    </div>
                );
            },
            maxWidth: 30,
        },
    ];

    const searchKeywordTyped = (value) => {
        setTableSearchFilter({
            ...tableSearchFilter,
            pageNo: 0,
            pageSize: 5,
            searchValue: value,
            sortBy: "",
            sortDir: "asc",
        });
    };

    const searchBarOnChange = (e) => {
        searchKeywordTyped(e.target.value);
    };

    const handleKeypress = (e) => {
        if (e.code === "Enter") {
            searchKeywordTyped(e.target.value);
        }
    };

    const onPageChange = (e) => {
        setTableSearchFilter({
            ...tableSearchFilter,
            pageNo: e.selected,
        });
    };

    const onDownloadClicked = async () => {
        let payload = {
            payslipFile: idRowSelected,
            staffId: staffId,
        };
        try {
            const response = await api.post.payslipZipDownload(payload, "arraybuffer");
            // create a download anchor tag
            var downloadLink = document.createElement("a");
            downloadLink.target = "_blank";
            downloadLink.download = "payslip.zip";
            // convert downloaded data to a Blob
            var blob = new Blob([response.data], { type: "application/zip" });
            // create an object URL from the Blob
            var URL = window.URL || window.webkitURL;
            var downloadUrl = URL.createObjectURL(blob);
            // set object URL as the anchor's href
            downloadLink.href = downloadUrl;
            // append the anchor to document body
            document.body.appendChild(downloadLink);
            // fire a click event on the anchor
            downloadLink.click();
            // cleanup: remove element and revoke object URL
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    return (
        <AppPagesLayout>
            <div className="page-employee-payslip">
                <AppModal isOpenModal={pdfModalOpen} onRequestClose={() => setPdfModalOpen(false)} emptyModal={true} className="page-employee-payslip__pdf-modal">
                    <div className="page-employee-payslip__modal-attachment">
                        <img
                            onClick={() => {
                                setPdfModalOpen(false);
                            }}
                            className="page-employee-payslip__modal-close"
                            src={iconClose}
                            alt=""
                        />
                        <iframe src={attachmentUrlSelected} title={attachmentUrlSelected} />
                    </div>
                </AppModal>
                <div className="page-employee-payslip__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>
                <div className="page-payslip__row">
                    <div className="page-payslip__searchBar-wrapper">
                        <AppInput leftSrc={iconSearch} placeholder="Search keyword" onChange={_.debounce((e) => searchBarOnChange(e), 1000)} onKeyPress={handleKeypress} />
                    </div>
                </div>
                <div className="page-employee-payslip__table-wrapper">
                    <AppReactTable
                        headerSelectAllChildren={
                            <div className="page-employee-payslip__table-header-button-wrapper">
                                <AppButton label="Download" size="s" onClick={onDownloadClicked} />
                            </div>
                        }
                        idSelectAll={idRowSelected.length}
                        totalItems={allFilenames.length}
                        columns={tableColumn}
                        data={tableData.content}
                    />
                </div>
                <div className="page-employee-payslip__pagination">
                    <AppPaginate onPageChange={onPageChange} pageCount={tableData.totalPages} forcePage={tableSearchFilter.pageNo} />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageEmployeePortalPayslip;
