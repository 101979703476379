const CONSTANT = {
    ENDPOINT_PATH: {
        LOGIN: "/staff/login",
        STAFF_LOGIN: "/staff/staffLogin",
        REFRESH_TOKEN: "/staff/refreshToken",
        FORGOT_PASSWORD: "/staff/forgotPassword",
        VALIDATE_RESET_PASSWORD_TOKEN: "/staff/validateToken",
        RESET_PASSWORD: "/staff/resetPassword",
        ADMIN_CHANGE_PASSWORD: "/staff/changePassword",

        CREATE_NEW_EMPLOYEE: "/admin/userMgmt/createStaff",
        USER_MANAGEMENT_TABLE: "/admin/userMgmt/allStaff",
        USER_MANAGEMENT_TABLE_SEARCH: "/admin/userMgmt/searchStaff",
        GET_USERNAME_DETAILS: "/userMgmt/getStaff",
        GET_STAFF_NAME_POSITION: "/userMgmt/listStaffPosition",
        UPDATE_STAFF_DETAILS: "/userMgmt/updateStaff",
        EMPLOYEE_BULK_UPLOAD: "/admin/userMgmt/bulkUpload",
        EMPLOYEE_EXPORT: "/admin/userMgmt/bulkExport",
        USER_MANAGEMENT_UPLOAD_TEMPLATE_VERSION: "/userMgmt/templateVersion",
        USER_MANAGEMENT_DOWNLOAD_TEMPLATE: "/userMgmt/downloadTemplate",
        USER_MANAGEMENT_DELETE_PROFILE_IMG: "/userMgmt/deleteProfileImage",
        USER_MANAGEMENT_POSITION_LEVEL: "/userMgmt/listingPositionLevel",
        USER_MANAGEMENT_STAFF_CONTRACT_TYPE: "/admin/userMgmt/staffContractType",

        CREATE_BENEFIT: "/admin/companyBenf/createBenefit",
        COMPANY_BENEFIT_BENEFIT_TABLE: "/companyBenf/allBenefits",
        COMPANY_BENEFIT_LIST_ALL_BENEFIT: "/companyBenf/listAllBenefit",
        COMPANY_BENEFIT_CREATE_POSITION: "/admin/companyBenf/createPosition",
        COMPANY_BENEFIT_LIST_ALL_POSITION: "/companyBenf/listAllPosition",
        COMPANY_BENEFIT_LIST_SEARCHED_BENEFIT: "/companyBenf/searchBenefits",
        COMPANY_BENEFIT_LIST_SEARCHED_POSITION: "/companyBenf/searchPosition",
        COMPANY_BENEFIT_GET_POSITION_BY_ID: "/companyBenf/getPosition",
        COMPANY_BENEFIT_UPDATE_POSITION: "/admin/companyBenf/updatePosition",
        COMPANY_BENEFIT_GET_BENEFIT: "/companyBenf/getBenefit",
        COMPANY_BENEFIT_UPDATE_BENEFIT: "/admin/companyBenf/updateBenefit",
        COMPANY_BENEFIT_REMOVE_BENEFIT: "/admin/companyBenf/removeBenefit",
        COMPANY_BENEFIT_ENTITLED_BENEFIT: "/companyBenf/entitledBenefits",

        LEAVE_MANAGEMENT_LIST_SEARCHED_LEAVE_DIRECTORY: "/admin/leaveMgmt/searchLeaveDirectory",
        LEAVE_MANAGEMENT_GET_LEAVE_SUMMARY: "/leaveMgmt/getLeaveSummary",
        LEAVE_MANAGEMENT_UPCOMING_LEAVE: "/leaveMgmt/upcomingLeave",
        LEAVE_MANAGEMENT_LEAVE_HISTORY: "/leaveMgmt/leaveHistory",
        LEAVE_MANAGEMENT_PENDING_LEAVE: "/leaveMgmt/getPendingLeave",
        LEAVE_MANAGEMENT_PENDING_APPROVAL: "/admin/leaveMgmt/searchLeaveApproval",
        LEAVE_MANAGEMENT_LEAVE_HISTORY_DETAIL: "/leaveMgmt/getLeaveHistoryDetail",
        LEAVE_MANAGEMENT_LEAVE_STAFF_TO_CALENDAR: "/leaveMgmt/getLeaveStaffToCalender",
        LEAVE_MANAGEMENT_PUBLIC_HOLIDAY_CALENDAR: "/leaveMgmt/getPublicHolidayToCalender",
        LEAVE_MANAGEMENT_UPDATE_LEAVE: "/admin/leaveMgmt/updateLeave",
        LEAVE_MANAGEMENT_LIST_LEAVE_TYPE: "/leaveMgmt/listLeaveType",
        LEAVE_MANAGEMENT_WORKING_STATUS: "/admin/leaveMgmt/workingStatus",
        LEAVE_MANAGEMENT_CREATE_LEAVE: "/leaveMgmt/createLeave",
        LEAVE_MANAGEMENT_CANCEL_LEAVE: "/leaveMgmt/cancelLeave",
        LEAVE_MANAGEMENT_LEAVE_DETAIL: "/leaveMgmt/getLeaveDetail",
        LEAVE_MANAGEMENT_LEAVE_DIRECTORY: "/admin/leaveMgmt/leaveDirectory",
        LEAVE_MANAGEMENT_LEAVE_CHECKER: "/admin/leaveMgmt/leaveChecker",

        CLAIM_MANAGEMENT_CLAIM_DIRECTORY: "/admin/claimMgmt/searchClaimDirectory",
        CLAIM_MANAGEMENT_SEARCH_CLAIM_APPROVAL: "/admin/claimMgmt/searchClaimApproval",
        CLAIM_MANAGEMENT_CLAIM_SUMMARY: "/claimMgmt/getClaimSummary",
        CLAIM_MANAGEMENT_APPROVE_CLAIM: "/admin/claimMgmt/approveClaim",
        CLAIM_MANAGEMENT_REJECT_CLAIM: "/admin/claimMgmt/rejectClaim",
        CLAIM_MANAGEMENT_CLAIM_HISTORY: "/claimMgmt/listClaimHistory",
        CLAIM_MANAGEMENT_CLAIM_DETAILS: "/claimMgmt/getClaimHistoryDetail",
        CLAIM_MANAGEMENT_CLAIM_TYPE: "/claimMgmt/listClaimType",
        CLAIM_MANAGEMENT_PENDING_CLAIM: "/claimMgmt/searchPendingClaim",
        CLAIM_MANAGEMENT_PENDING_CLAIM_DETAIL: "/admin/claimMgmt/getClaimDetail",
        CLAIM_MANAGEMENT_CREATE_CLAIM: "/claimMgmt/createClaim",
        CLAIM_MANAGEMENT_SEARCH_PAYMENT_PENDING: "/admin/claimMgmt/searchPaymentPending",
        CLAIM_MANAGEMENT_ISSUE_PAID_STATUS: "/admin/claimMgmt/issuePaidStatus",
        CLAIM_MANAGEMENT_BULK_ISSUE_PAID_STATUS: "/admin/claimMgmt/bulkIssuePaidStatus",
        CLAIM_MANAGEMENT_CLAIM_PENDING_PAYMENT_DETAIL: "/admin/claimMgmt/getClaimPendingDetail",
        CLAIM_MANAGEMENT_UPDATE_CLAIM_STATUS: "/admin/claimMgmt/updateClaimStatus",
        CLAIM_MANAGEMENT_EXPORT_PENDING_STATUS: "/admin/claimMgmt/exportPendingClaim",

        TIMESHEET_MANAGEMENT_LIST_TIMESHEET: "/admin/timesheetMgmt/listTimesheet",
        TIMESHEET_MANAGEMENT_SAVE_TIMESHEET: "/admin/timesheetMgmt/saveTimesheet",
        TIMESHEET_MANAGEMENT_EXPORT: "/admin/timesheetMgmt/timesheetExport",
        TIMESHEET_MANAGEMENT_GET_TIMESHEET: "/admin/timesheetMgmt/getTimesheet",
        TIMESHEET_MANAGEMENT_CREATE_TIMESHEET_CRM_SQUAD: "/admin/timesheetMgmt/createTimesheetCrmSquad",
        TIMESHEET_MANAGEMENT_CREATE_TIMESHEET_PROJECT: "/admin/timesheetMgmt/createTimesheetProject",
        TIMESHEET_MANAGEMENT_DELETE_TIMESHEET_PROJECT: "/admin/timesheetMgmt/deleteTimesheetProject",
        TIMESHEET_MANAGEMENT_DELETE_TIMESHEET_CRM_SQUAD: "/admin/timesheetMgmt/deleteTimesheetCrmSquad",
        TIMESHEET_MANAGEMENT_GET_TIMESHEET_SQUAD_LIST: "/admin/timesheetMgmt/timesheetSquadList",
        TIMESHEET_MANAGEMENT_GET_TIMESHEET_PROJECT_LIST: "/admin/timesheetMgmt/timesheetProjectList",
        TIMESHEET_MANAGEMENT_UPDATE_TIMESHEET: "/admin/timesheetMgmt/updateTimesheet",
        TIMESHEET_MANAGEMENT_GET_TIME_SHEET: "/admin/timesheetMgmt/timeSheet",
        TIMESHEET_MANAGEMENT_GET_TIMESHEET_REMARK_LIST: "/admin/timesheetMgmt/timesheetRemarkList",

        PAYSLIP_SEARCH_EMPLOYEE: "/admin/payslipMgmt/payslipUsers",
        PAYSLIP_GET_STAFF_ID_SEARCH_PAYSLIP: "/payslipMgmt/getPayslip",
        PAYSLIP_DOWNLOAD: "/payslipMgmt/downloadPayslip",
        PAYSLIP_ZIP_DOWNLOAD: "/payslipMgmt/downloadAllPayslip",
        PAYSLIP_VALIDATE_FILENAME: "/admin/payslipMgmt/bulkUploadPaySlip",
        PAYSLIP_BULKUPLOAD: "/admin/payslipMgmt/confirmBulkUpload",
        PAYSLIP_LIST_ALL: "/admin/payslipMgmt/allPayslip",

        EMPLOYEE_CONFIRMATION_GET_PDF_DETAILS: "/admin/empConfirmation/getConfirmPdfDetail",
        EMPLOYEE_CONFIRMATION_SEARCH_EMPLOYEE: "/admin/empConfirmation/listEmployee",
        EMPLOYEE_CONFIRMATION_SAVE_PDF: "/admin/empConfirmation/savePdfDetail",
        EMPLOYEE_CONFIRMATION_PDF_TO_EMAIL: "/admin/empConfirmation/pdfToEmail",

        COACHING_SESSION_UPCOMING_LIST: "/coachingMgmt/upcomingSessions",
        COACHING_SESSION_COACHING_HISTORY: "/coachingMgmt/coachingHistory",
        COACHING_SESSION_SCHEDULE_SESSION: "/coachingMgmt/scheduleCoachingSession",
        COACHING_SESSION_SESSION_DETAIL: "/coachingMgmt/getSessionDetails",
        COACHING_SESSION_EDIT_SESSION: "/coachingMgmt/editCoachingSession",
        COACHING_SESSION_UPCOMING_LIST_ADVANCE_SEARCH: "/coachingMgmt/upcomingSessionsADVSearch",

        DASHBOARD_CREATE_ANNOUNCEMENT: "/admin/dashboardMgmt/createAnnouncement",
        DASHBOARD_GET_ON_LEAVE: "/dashboardMgmt/getOnLeave",
        DASHBOARD_GET_TOTAL_EMPLOYEE: "/dashboardMgmt/getTotalEmployee",
        DASHBOARD_GET_EMPLOYEE_BIRTHDAY: "/dashboardMgmt/getEmployeeBirthday",
        DASHBOARD_GET_PENDING_CLAIM: "/dashboardMgmt/getPendingClaim",
        DASHBOARD_GET_PENDING_LEAVE: "/dashboardMgmt/getPendingLeave",
        DASHBOARD_UPCOMING_ANNOUNCEMENT: "/dashboardMgmt/listUpcomingAnnouncement",
        DASHBOARD_CHANGE_PIN_STATUS: "/dashboardMgmt/changePinnedStatus",
        DASHBOARD_DELETE_ANNOUNCEMENT: "/admin/dashboardMgmt/deleteAnnouncement",
        DASHBOARD_POSTED_ANNOUNCEMENT: "/dashboardMgmt/listActiveAnnouncement",
        DASHBOARD_GET_PUBLIC_HOLIDAY: "/dashboardMgmt/getPublicHolidays",
        DASHBOARD_GET_NEW_EMPLOYEE: "/admin/dashboardMgmt/countNewEmployee",
        DASHBOARD_GET_TURNOVER_EMPLOYEE: "/admin/dashboardMgmt/countTurnover",
        DASHBOARD_GET_EMPLOYEE_UPCOMING_LEAVE: "/dashboardMgmt/EmployeePortal/getUpcomingLeave",
        DASHBOARD_GET_EMPLOYEE_BALANCE_DAY: "/dashboardMgmt/EmployeePortal/getBalanceDays",
        DASHBOARD_GET_EMPLOYEE_UPCOMING_COACHING: "/dashboardMgmt/EmployeePortal/dashboardUpcomingCoaching",
        DASHBOARD_GET_EMPLOYEE_ALL_ANNOUNCEMENT: "/dashboardMgmt/listAllAnnouncement",
        DASHBOARD_ARCHIVE_ANNOUNCEMENT: "/admin/dashboardMgmt/archiveAnnouncement",

        NOTIFICATION_ADMIN: "/admin/notification/adminNotification",
        NOTIFICATION_EMPLOYEE: "/notification/employeeNotification",
        NOTIFICATION_ADMIN_READ: "/admin/notification/updateReadStatus",
        NOTIFICATION_EMPLOYEE_READ: "/notification/employee/updateReadStatus",
        NOTIFICATION_UPDATE_TO_READ: "/notification/updateNotificationToRead",
    },
    REGEX: {
        atLeastOneNumber: /.*[0-9].*/,
        atLeastOneAlphabet: /[a-z]/i,
        spaces: /\s/g,
        icNo: /^\d{0,6}[-]\d{2}[-]\d{4}$/,
        mobileNo: /^(01)\d{1}[-]\d{7,8}$/,
    },
    BE_STATUS: {
        CREATE_ANNOUNCEMENT: {
            PIN: "pin",
            REMOVE_PIN: "unpin",
        },
        PAYSLIP: {
            DUPLICATE_PAYSLIP: "this payslip is duplicate",
        },
        LEAVE_TYPE: {
            ANNUAL_LEAVE: "Annual Leave",
            CARRY_FORWARD_LEAVE: "Carry Forward Leave",
            COMPASSIONATE_LEAVE: "Compassionate Leave",
            EMERGENCY_LEAVE: "Emergency Leave",
            HOSPITALIZATION_LEAVE: "Hospitalization Leave",
            MATERNITY_LEAVE: "Maternity Leave",
            MEDICAL_LEAVE: "Medical Leave",
            PATERNITY_LEAVE: "Paternity Leave",
            REPLACEMENT_LEAVE: "Replacement Leave",
            UNPAID_LEAVE: "Unpaid Leave",
        },
        CLAIM: {
            CLAIM_TYPE: {
                MEDICAL_CLAIM: "Medical Claim",
                OTHERS: "Others",
                BODY_CHECK_OPTICAL_DENTAL: "Body Check/ Optical/ Dental",
            },
        },
        TIMEOFF: {
            FULL_DAY: "Full Day",
            AM: "Half Day AM",
            PM: "Half Day PM",
        },
    },
    EMPLOYEE_STATUS: {
        PERMANEMT: "Permanent",
        RESIGNED: "Resigned",
        PROBATION: "Probation",
        TERMINATION: "Termination",
        PART_TIME: "Part-Time",
        CONTRACT: "Contract",
        INTERN: "Intern",
        DISMISSAL: "Dismissal",
    },
    STATE_NUMBER: [
        "01",
        "21",
        "22",
        "23",
        "24",
        "02",
        "25",
        "26",
        "27",
        "03",
        "28",
        "29",
        "04",
        "30",
        "05",
        "31",
        "59",
        "06",
        "32",
        "33",
        "07",
        "34",
        "35",
        "08",
        "36",
        "37",
        "38",
        "39",
        "09",
        "40",
        "10",
        "41",
        "42",
        "43",
        "44",
        "11",
        "45",
        "46",
        "12",
        "47",
        "48",
        "49",
        "13",
        "50",
        "51",
        "52",
        "53",
        "14",
        "54",
        "55",
        "56",
        "57",
        "15",
        "58",
        "16",
        "82",
    ],
};

export default CONSTANT;
