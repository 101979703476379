export const isDevelopmentMode = () => !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const sanitizeError = (originalError) => {
    let error = originalError;
    if (typeof originalError === "object") {
        if (originalError.response) {
            if (originalError.response.data) {
                error = originalError.response.data;
            } else {
                error = originalError.response;
            }
        } else if (originalError.data) {
            error = originalError.data;
        }
        if (error.error) {
            return error.error;
        } else {
            return error.message;
        }
    } else {
        error = originalError;
        return error;
    }
};

export const statusFormat = (status) => {
    if (status.toLowerCase() === "reject") {
        return "Rejected";
    } else if (status.toLowerCase() === "approve") {
        return "Approved";
    } else if (status.toLowerCase() === "cancel") {
        return "Cancelled";
    } else {
        return status.charAt(0).toUpperCase() + status.substring(1);
    }
};

export const statusColor = (status) => {
    if (status.toLowerCase() === "upcoming") {
        return "orange";
    } else if (status.toLowerCase() === "overdue") {
        return "red";
    } else if (status.toLowerCase() === "completed") {
        return "green";
    } else if (status.toLowerCase() === "cancelled") {
        return "red";
    } else {
        return "";
    }
};

export const formatNRIC = (value) => {
    const val = value.replace(/-/g, "");
    const val_1 = val.substring(0, 6);
    const val_2 = val.substring(6, 8);
    const val_3 = val.substring(8, 12);

    if (val.length <= 6) {
        return val;
    } else if (val.length > 6 && val.length < 9) {
        return `${val_1}-${val_2}`;
    } else if (val.length >= 9) {
        return `${val_1}-${val_2}-${val_3}`;
    }
};

export const formatHolidayDate = (date) => {
    let today = new Date().getDate();
    let day = new Date(date).getDate();

    if (day === today) {
        return "Today";
    } else {
        switch (day) {
            case "1":
            case "21":
            case "31":
                day = day + "st";
                break;
            case "2":
            case "22":
                day = day + "nd";
                break;
            case "3":
            case "23":
                day = day + "rd";
                break;
            default:
                day = day + "th";
                break;
        }
        return day;
    }
};
