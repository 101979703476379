import React from "react";
import ReactPaginate from "react-paginate";

const AppPaginate = ({ onPageChange, pageCount, forcePage }) => {
    return (
        <ReactPaginate
            nextLabel=">"
            onPageChange={onPageChange}
            pageRangeDisplayed={4}
            marginPagesDisplayed={1}
            pageCount={pageCount}
            forcePage={forcePage}
            previousLabel="<"
            pageClassName="app-paginate__box-item"
            pageLinkClassName="app-paginate__link-item"
            previousClassName="app-paginate__box-item app-paginate__arrow"
            previousLinkClassName="app-paginate__link-item  app-paginate__arrow-link"
            nextClassName="app-paginate__box-item app-paginate__arrow"
            nextLinkClassName="app-paginate__link-item app-paginate__arrow-link"
            breakLabel="..."
            breakClassName="app-paginate__box-item"
            breakLinkClassName="app-paginate__link-item"
            containerClassName="app-paginate"
            activeClassName="app-paginate__active"
            renderOnZeroPageCount={null}
        />
    );
};

export default AppPaginate;
