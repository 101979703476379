// libraries
import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { BsQuestionCircle } from "react-icons/bs";
import ReactTooltip from "react-tooltip";
import moment from "moment";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTabLink from "components/app-tab-link";
import appToast from "components/app-toast";
// routes
import pathnames from "routes/pathnames";
import { sanitizeError } from "common/utilities";
// hooks
import useIsMount from "hooks/use-is-mount";
// services
import api from "services/api";
// common
import CONSTANT from "common/constant";
// assets
import iconPublicHoliday from "assets/images/pages/leave-management/icon-public-holiday.svg";
import iconTakeLeave from "assets/images/pages/leave-management/icon-taken-leave.svg";
import iconRestDay from "assets/images/pages/leave-management/icon-rest-day.svg";
import iconMedicalLeaveFull from "assets/images/pages/leave-management/icon-medical-leave-full.svg";
import iconMedicalLeaveAM from "assets/images/pages/leave-management/icon-medical-leave-am.svg";
import iconMedicalLeavePM from "assets/images/pages/leave-management/icon-medical-leave-pm.svg";
import iconAnnualLeaveFull from "assets/images/pages/leave-management/icon-annual-leave-full.svg";
import iconAnnualLeaveAM from "assets/images/pages/leave-management/icon-annual-leave-am.svg";
import iconAnnualLeavePM from "assets/images/pages/leave-management/icon-annual-leave-pm.svg";
import iconCompassionateLeave from "assets/images/pages/leave-management/icon-compassionate-leave.svg";
import iconMaternityLeave from "assets/images/pages/leave-management/icon-maternity-leave.svg";
import iconOthersLeave from "assets/images/pages/leave-management/icon-others-leave.svg";
import iconClose from "assets/images/icon-close-black.svg";

const breadcrumb = [
    {
        label: "Leave Management",
    },
    {
        label: "Leave Calendar",
    },
];

const appTabLink = [
    {
        label: "Leave Calendar",
        pathname: pathnames.pageLeaveManagementCalendar,
    },
    {
        label: "Leave Directory",
        pathname: pathnames.pageLeaveDirectory,
    },
    {
        label: "Pending Approval",
        pathname: pathnames.pageLeavePendingApproval,
    },
];

const calendarLegend = [
    { icon: iconPublicHoliday, label: "Public Holiday" },
    { icon: iconTakeLeave, label: "Taken Leave" },
    { icon: iconRestDay, label: "Rest Day" },
    { icon: iconMedicalLeaveFull, label: "Medical Leave - Full Day", leaveType: CONSTANT.BE_STATUS.LEAVE_TYPE.MEDICAL_LEAVE, timeOff: "Full Day" },
    { icon: iconMedicalLeaveAM, label: "Medical Leave - AM", leaveType: CONSTANT.BE_STATUS.LEAVE_TYPE.MEDICAL_LEAVE, timeOff: "Half Day AM" },
    { icon: iconMedicalLeavePM, label: "Medical Leave - PM", leaveType: CONSTANT.BE_STATUS.LEAVE_TYPE.MEDICAL_LEAVE, timeOff: "Half Day PM" },
    { icon: iconAnnualLeaveFull, label: "Annual Leave - Full Day", leaveType: CONSTANT.BE_STATUS.LEAVE_TYPE.ANNUAL_LEAVE, timeOff: "Full Day" },
    { icon: iconAnnualLeaveAM, label: "Annual Leave - AM", leaveType: CONSTANT.BE_STATUS.LEAVE_TYPE.ANNUAL_LEAVE, timeOff: "Half Day AM" },
    { icon: iconAnnualLeavePM, label: "Annual Leave - PM", leaveType: CONSTANT.BE_STATUS.LEAVE_TYPE.ANNUAL_LEAVE, timeOff: "Half Day PM" },
    { icon: iconCompassionateLeave, label: "Compassionate Leave", leaveType: CONSTANT.BE_STATUS.LEAVE_TYPE.COMPASSIONATE_LEAVE },
    { icon: iconMaternityLeave, label: "Maternity Leave", leaveType: CONSTANT.BE_STATUS.LEAVE_TYPE.MATERNITY_LEAVE },
    { icon: iconOthersLeave, label: "Others Leave", leaveType: "Others Leave" },
];

const LeaveIconImg = ({ leaveInfo }) => {
    if (leaveInfo.type.toLowerCase() === "public holiday") {
        return null;
    }
    const iconFind = calendarLegend.find((o) => o.leaveType === leaveInfo.type && o.timeOff === leaveInfo.timeOff);
    if (iconFind) {
        return <img alt="" src={iconFind.icon} className="fc__event-icon" />;
    } else {
        return <img alt="" src={iconOthersLeave} className="fc__others-icon" />;
    }
};

const getLegendImgClassNames = (index, label) => {
    const classNames = [];
    if (index < 3) {
        classNames.push("page-leave-calendar__color-icon");
    } else {
        classNames.push("page-leave-calendar__legend-icon");
    }
    if (label === "Others Leave") {
        classNames.push("page-leave-calendar__others-icon");
    }
    classNames.join(" ");
};

const PageLeaveCalender = () => {
    const isMount = useIsMount();
    const [calendarLegendOpen, setCalendarLegendOpen] = useState(false);
    const [eventsData, setEventsData] = useState([]);
    const [currentMonthYear, setCurrentMonthYear] = useState({
        month: "",
        year: "",
    });
    const [publicHolidayEventData, setPublicHolidayEventData] = useState([]);
    const [triggerInsertHtml, setTriggerInsertHtml] = useState(false);
    const [showMoreContents, setShowMoreContents] = useState([]);

    useEffect(() => {
        document.getElementsByClassName("fc-today-button")[0].innerHTML = "Today";
        document.getElementsByClassName("fc-toolbar-chunk")[1].classList.add("fc-toolbar-responsive");
    }, []);

    const convertResponseToFullCalendarData = (result) => {
        const calenderEvents = [];
        result.forEach((o, i) => {
            calenderEvents.push({
                ...o,
                event: {
                    start: moment(o.startDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
                    end: moment(o.endDate, "DD/MM/YYYY").add(1, "day").format("YYYY-MM-DD"),
                    type: o.leaveType,
                    timeOff: o.timeOff,
                },
            });
        });

        const calenderEventsSplit = calenderEvents.reduce((a, o) => {
            if (a[JSON.stringify(o.event)] == null) {
                a[JSON.stringify(o.event)] = [];
            }
            a[JSON.stringify(o.event)].push(o);
            return a;
        }, {});

        const calenderEventsSplittedKey = Object.keys(calenderEventsSplit);
        const calenderEventsSplittedKeyObject = calenderEventsSplittedKey.map((o, i) => {
            return JSON.parse(o);
        });

        const eventsArray = [];
        calenderEventsSplittedKeyObject.forEach((o, i) => {
            eventsArray.push({
                ...o,
                title: calenderEventsSplit[JSON.stringify(o)].map((o) => o.userName).join(", "),
            });
        });
        setEventsData(eventsArray);
    };

    useEffect(() => {
        if (!isMount) {
            const getCalendarData = async () => {
                try {
                    const payload = {
                        month: currentMonthYear.month,
                        year: currentMonthYear.year,
                    };
                    const response = await api.get.leaveManagementLeaveStaffToCalendar(payload);
                    const result = response.data.result;
                    convertResponseToFullCalendarData(result);
                } catch (error) {
                    let sanitizedError = sanitizeError(error);
                    appToast(sanitizedError, false);
                }
            };
            getCalendarData();
        }
    }, [currentMonthYear, isMount]);

    const getPublicHoliday = async (currentMonthYear) => {
        try {
            const payload = {
                month: currentMonthYear.month,
                year: currentMonthYear.year,
            };
            const response = await api.get.leaveManagementPublicHolidayCalendar(payload);
            const formattedPublicHoliday = response.data.result.map((o) => {
                return {
                    end: moment(o.holidayDate).format("YYYY-MM-DD"),
                    start: moment(o.holidayDate).format("YYYY-MM-DD"),
                    timeOff: "Full Day",
                    type: "public holiday",
                    title: o.holiday,
                };
            });
            setPublicHolidayEventData([...formattedPublicHoliday]);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    useEffect(() => {
        if (!isMount) {
            getPublicHoliday(currentMonthYear);
        }
    }, [currentMonthYear, isMount]);
    useEffect(() => {
        let fcPopoverElemet = document.getElementsByClassName("fc-popover-body");
        if (fcPopoverElemet.length) {
            document.getElementsByClassName("fc-popover-body")[0].remove();
            document.getElementsByClassName("fc-popover-title")[0].remove();
            showMoreContents.forEach((content, index) => {
                let wrapper = document.createElement("div");
                wrapper.id = `fc-more-wrapper_${index}`;
                wrapper.classList.add(`fc-more-wrapper`);
                document.getElementsByClassName("fc-more-popover")[0].appendChild(wrapper);

                const leaveType = content.event._def.extendedProps.type;
                if (leaveType !== "public holiday") {
                    const timeOff = content.event._def.extendedProps.timeOff;
                    const iconFind = calendarLegend.find((o) => o.leaveType === leaveType && o.timeOff === timeOff);
                    let imgElement = document.createElement("img");
                    imgElement.classList.add( iconFind && iconFind.icon ? "fc-more-icon" : "fc-more-others-icon" );
                    imgElement.setAttribute("src", iconFind && iconFind.icon ? iconFind.icon : iconOthersLeave);
                    document.getElementById(`fc-more-wrapper_${index}`).appendChild(imgElement);
                }

                let titleDiv = document.createElement("div");
                titleDiv.classList.add("fc-more-title");
                let titleText = document.createTextNode(content.event._def.title);
                titleDiv.appendChild(titleText);
                document.getElementById(`fc-more-wrapper_${index}`).appendChild(titleDiv);
            });
        }
    }, [triggerInsertHtml, showMoreContents]);

    return (
        <AppPagesLayout>
            <div className="page-leave-calendar" onClick={() => setCalendarLegendOpen(false)}>
                <div className="page-leave-calendar__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>
                <div className="page-leave-calendar__tab-wrapper">
                    <AppTabLink tabList={appTabLink} />
                </div>

                <div className="page-leave-calendar__body">
                    <div className="page-leave-calendar__calendar-wrapper">
                        <FullCalendar
                            headerToolbar={{
                                start: "prev,next today",
                                center: "title",
                                end: null,
                            }}
                            plugins={[dayGridPlugin]}
                            initialView="dayGridMonth"
                            firstDay={1}
                            viewClassNames="fc__view"
                            dayHeaderClassNames="fc__day-header"
                            eventDisplay="block"
                            eventBorderColor="transparent"
                            showNonCurrentDates={false}
                            datesSet={(dates) => {
                                let month = moment(dates.view.title, "MMMM YYYY").format("MM");
                                let year = moment(dates.view.title, "MMMM YYYY").format("YYYY");
                                setCurrentMonthYear({
                                    month: month,
                                    year: year,
                                });
                            }}
                            fixedWeekCount={false}
                            dayMaxEventRows={4}
                            events={[...eventsData, ...publicHolidayEventData]}
                            eventClassNames={(arg) => {
                                const eventClassNames = ["fc__event"];
                                if (arg.event._def.extendedProps.type === "public holiday") {
                                    eventClassNames.push("fc__event--red");
                                } else {
                                    eventClassNames.push("fc__event--green");
                                }
                                return eventClassNames.join(" ");
                            }}
                            moreLinkClick={(e) => {
                                setShowMoreContents(e.hiddenSegs);
                                setTriggerInsertHtml((state) => !state);
                            }}
                            eventContent={(arg) => {
                                return (
                                    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                        {arg.event._def.extendedProps.type === "public holiday" ? null : (
                                            <div className="fc__icon-wrapper">
                                                <LeaveIconImg leaveInfo={arg.event._def.extendedProps} />
                                            </div>
                                        )}
                                        <ReactTooltip
                                            type="light"
                                            id={`${arg.event._def.defId}_${arg.event._def.title}`}
                                            place="top"
                                            effect="solid"
                                            clickable={true}
                                            // event="click"
                                            isCapture={true}
                                            arrowColor="transparent"
                                            className="fc__tooltip">
                                            <div className="fc__tooltip-wrapper">
                                                <div className="fc__tooltip-icon">
                                                    <LeaveIconImg leaveInfo={arg.event._def.extendedProps} />
                                                </div>
                                                <div className="fc__tooltip-title">{arg.event._def.title}</div>
                                            </div>
                                        </ReactTooltip>
                                        <div className="fc__event-title" data-tip data-for={`${arg.event._def.defId}_${arg.event._def.title}`} style={{ cursor: "pointer" }}>
                                            {arg.event._def.title}
                                        </div>
                                    </div>
                                );
                            }}
                        />
                    </div>
                    <div onClick={(e) => e.stopPropagation()}>
                        <div
                            className="page-leave-calendar__legend-button"
                            onClick={() => {
                                setCalendarLegendOpen(true);
                            }}>
                            <BsQuestionCircle size={20} />
                        </div>
                        {calendarLegendOpen ? (
                            <div className="page-leave-calendar__legend-wrapper">
                                <div className="page-leave-calendar__legend-close" onClick={() => setCalendarLegendOpen(false)}>
                                    <img className="page-leave-calendar__legend-close-icon" alt="" src={iconClose} />
                                </div>
                                {calendarLegend.map((item, index) => (
                                    <div key={index} className="page-leave-calendar__legend-contents">
                                        <div className="page-leave-calendar__legend-icon-wrapper">
                                            <img className={getLegendImgClassNames(index, item.label)} alt="" src={item.icon} style={item.label === "Others Leave" ? { height: 11 } : {}} />
                                        </div>
                                        <span className="page-leave-calendar__legend-label">{item.label}</span>
                                    </div>
                                ))}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageLeaveCalender;
