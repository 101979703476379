// libraries
import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// assets
import iconCalendar from "assets/images/components/app-input-date/icon-calendar.svg";

const CalendarIcon = () => {
    return <img src={iconCalendar} alt="" />;
};

const getDateInputClassNames = (error, disabled) => {
    const classNames = ["app-input-date__input"];
    if (error) classNames.push("app-input-date__input-error");
    if (disabled) classNames.push("app-input-date__input-disabled");
    return classNames.join(" ");
};

const AppInputDate = ({ onChange, error, placeholder, onClose, value, maxDate, disabled, minDate, newLeave }) => {
    const [dateIsOpen, setDateIsOpen] = useState(false);
    const [formattedValue, setFormattedValue] = useState(null);
    const [showTitle, setShowTitle] = useState(false);

    useEffect(() => {
        let unixTimestamp = moment(value, "DD/MM/YYYY").format("x");
        const formatedDate = new Date(parseInt(unixTimestamp));
        if (formatedDate && formatedDate.toString().toLowerCase() !== "invalid date") {
            setFormattedValue(formatedDate);
        } else {
            setFormattedValue(null);
        }
        if (value && value.length === 10 && !dateIsOpen) {
            setShowTitle(true);
        } else {
            setShowTitle(false);
        }
    }, [value, dateIsOpen]);

    const onCloseDate = () => {
        setDateIsOpen(false);
        if (onClose) {
            onClose();
        }
    };

    const inputWrapperOnClick = () => {
        setDateIsOpen(true);
    };

    const maxDateFunc = useMemo(() => {
        return maxDate === "today" ? moment() : maxDate === "yesterday" ? moment().subtract(1, "days") : moment().add(newLeave ? 1 : 20, "years");
    },[maxDate, newLeave]);

    const minDateFunc = useMemo(() => {
        return minDate === "today" ? moment() : moment().set("year", 1950);
    },[minDate]);

    return (
        <div className="app-input-date">
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                    disabled={disabled}
                    maxDate={maxDateFunc}
                    minDate={minDateFunc}
                    value={formattedValue}
                    open={disabled ? false : dateIsOpen}
                    onClose={onCloseDate}
                    openTo="year"
                    views={["year", "month", "day"]}
                    inputFormat="DD/MM/YYYY"
                    allowSameDateSelection={true}
                    onChange={(value) => {
                        if (!disabled) {
                            if (value) {
                                if (value._i === "1") {
                                    onChange("");
                                } else {
                                    const formatConvert = value !== "Invalid Date" ? moment(value, "ddd MMM DD YYYY HH:mm:ss").format("DD/MM/YYYY") : "";
                                    onChange(formatConvert);
                                }
                            } else {
                                onChange("");
                            }
                        }
                    }}
                    components={{
                        OpenPickerIcon: CalendarIcon,
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                        <div className="app-input-date__input-wrapper" onClick={inputWrapperOnClick}>
                            <input className={getDateInputClassNames(error, disabled)} ref={inputRef} {...inputProps} placeholder={placeholder} />
                            {showTitle ? <div className="app-input-date__title">{placeholder}</div> : null}
                            <div className="app-input-date__calendar-icon">{InputProps?.endAdornment}</div>
                        </div>
                    )}
                />
            </LocalizationProvider>
            {error ? <div className="app-input-date__error-message">{error}</div> : null}
        </div>
    );
};

export default AppInputDate;
