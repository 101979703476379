// libraries
import React, { useState, useEffect } from "react";
import { BsShare, BsUpload, BsPlusLg } from "react-icons/bs";
import { BsExclamationCircle } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { CgCloseO } from "react-icons/cg";
import ReactTooltip from "react-tooltip";
import ReactLoading from "react-loading";
import _ from "lodash";
// api
import api from "services/api";
// common
import { sanitizeError } from "common/utilities";
// hooks
import usePrevious from "hooks/use-previous";
import useIsMount from "hooks/use-is-mount";
// routes
import pathnames from "routes/pathnames";
// components
import AppInput from "components/app-input";
import AppModal from "components/app-modal";
import appToast from "components/app-toast";
import AppStatus from "components/app-status";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppPaginate from "components/app-paginate";
import AppBreadcrumb from "components/app-breadcrumb";
import AppReactTable from "components/app-react-table";
import AppPagesLayout from "components/app-pages-layout";
// assets
import iconEdit from "assets/images/icon-edit.svg";
import iconFileCsv from "assets/images/icon-file-csv.svg";
import iconTailedArrowUp from "assets/images/icon-tailed-arrow-up.svg";
import iconSearch from "assets/images/components/app-input/icon-search.svg";

const tableDataInitialState = {
    content: [],
    ids: [],
    last: true,
    pageNo: 0,
    pageSize: 5,
    totalElement: 0,
    totalPages: 1,
};

const PageUserManagement = () => {
    const history = useHistory();
    const isMount = useIsMount();
    const [idRowSelected, setIdRowSelected] = useState([]);
    const [tableData, setTableData] = useState(tableDataInitialState);
    const [tableFilter, setTableFilter] = useState({
        pageSize: 5,
        pageNo: 0,
        sortBy: "id",
        sortDir: "asc",
    });
    const [allId, setAllId] = useState([]);
    const [tableSearchFilter, setTableSearchFilter] = useState({
        pageSize: 5,
        pageNo: 0,
        sortBy: "id",
        sortDir: "asc",
        searchValue: "",
    });
    const prevEmptyValueSortHeader = usePrevious({
        columnId: tableFilter.sortBy,
        sortDir: tableFilter.sortDir,
    });
    const prevValueSortHeader = usePrevious({
        columnId: tableSearchFilter.sortBy,
        sortDir: tableSearchFilter.sortDir,
    });
    const [csvFiles, setCsvFiles] = useState([]);
    const [bulkUploadConfirmButton, setBulkUploadConfirmButton] = useState({
        label: "Confirm",
        disabled: false,
    });
    const [dropFileErrorMessage, setDropFileErrorMessage] = useState("");
    const [bulkUploadErrorMessage, setBulkUploadErrorMessage] = useState("");
    const [modalIsOpenSequence, setModalIsOpenSequence] = useState([]);
    const [templateVersionNumber, setTemplateVersionNumber] = useState("");
    const [searchValue, setSearchValue] = useState("");

    const getUserManagementTableDataNoSearchValue = async (tableFilter) => {
        try {
            const response = await api.get.userManagementTable(tableFilter);
            const result = response.data.result;
            setTableData(result);
            setAllId(result.ids);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    useEffect(() => {
        getUserManagementTableDataNoSearchValue(tableFilter);
    }, [tableFilter]);

    useEffect(() => {
        const getTemplateVersionNumber = async () => {
            try {
                const response = await api.get.userManagementUploadTemplateVersion();
                setTemplateVersionNumber(response.data.result);
            } catch (error) {
                appToast(sanitizeError(error), false);
            }
        };
        getTemplateVersionNumber();
    }, []);

    const getUserManagementTableData = async (tableSearchFilter) => {
        try {
            const response = await api.get.userManagementTableSearch(tableSearchFilter);
            const result = response.data.result;
            setTableData(result);
            setAllId(result.ids);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            appToast(sanitizedError, false);
        }
    };

    useEffect(() => {
        if (!isMount && tableSearchFilter.searchValue) {
            getUserManagementTableData(tableSearchFilter);
        }
    }, [tableSearchFilter, isMount]);

    const breadcrumb = [
        {
            label: "User Management",
        },
    ];

    const selectRowFunction = (rowData) => {
        if (idRowSelected.includes(rowData.id)) {
            setIdRowSelected([...idRowSelected.filter((ele) => ele !== rowData.id)]);
        } else {
            const sortedIdRowSelected = [...idRowSelected, rowData.id];
            setIdRowSelected(sortedIdRowSelected.sort());
        }
    };

    const selectAllFunction = (allId) => {
        if (_.isEqual(idRowSelected, allId)) {
            setIdRowSelected([]);
        } else {
            setIdRowSelected(allId);
        }
    };

    const headerFilterClick = (header) => {
        let column = header.column;
        if (tableSearchFilter.searchValue) {
            if (prevValueSortHeader.columnId === column.searchFilterValue) {
                if (prevValueSortHeader.sortDir === "asc") {
                    setTableSearchFilter({
                        ...tableSearchFilter,
                        sortBy: column.searchFilterValue,
                        sortDir: "desc",
                        pageNo: 0,
                    });
                } else {
                    setTableSearchFilter({
                        ...tableSearchFilter,
                        sortBy: column.searchFilterValue,
                        sortDir: "asc",
                        pageNo: 0,
                    });
                }
            } else {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "asc",
                    pageNo: 0,
                });
            }
        } else {
            if (prevEmptyValueSortHeader.columnId === column.id) {
                if (prevEmptyValueSortHeader.sortDir === "asc") {
                    setTableFilter({
                        ...tableFilter,
                        sortBy: column.id,
                        sortDir: "desc",
                        pageNo: 0,
                    });
                } else {
                    setTableFilter({
                        ...tableFilter,
                        sortBy: column.id,
                        sortDir: "asc",
                        pageNo: 0,
                    });
                }
            } else {
                setTableFilter({
                    ...tableFilter,
                    sortBy: column.id,
                    sortDir: "asc",
                    pageNo: 0,
                });
            }
        }
    };

    const headerArrowRotate = (searchValueActive, searchValueInactive) => {
        const classNames = ["react-table__arrow-icon"];
        if (searchValueActive && searchValue && tableSearchFilter.sortDir === "desc") classNames.push("react-table__arrow-icon-down");
        if (searchValueActive && searchValue) classNames.push("react-table__arrow-icon--active");

        if (searchValueInactive && !searchValue && tableFilter.sortDir === "desc") classNames.push("react-table__arrow-icon-down");
        if (searchValueInactive && !searchValue) classNames.push("react-table__arrow-icon--active");

        return classNames.join(" ");
    };

    const tableColumn = [
        {
            id: "Id",
            Header: (header) => {
                if (header.data.length) {
                    return (
                        <div className="react-table__checkbox-wrapper">
                            <AppCheckbox type="selectAll" onChange={() => selectAllFunction(allId)} checked={_.isEqual(idRowSelected, allId)} />
                        </div>
                    );
                } else {
                    return null;
                }
            },
            Cell: (row) => {
                return (
                    <div className="react-table__checkbox-wrapper" onClick={(e) => e.stopPropagation()}>
                        <AppCheckbox onChange={() => selectRowFunction(row.row.original)} checked={idRowSelected.includes(row.row.original.id)} />
                    </div>
                );
            },
            maxWidth: 50,
        },
        {
            id: "staffUserName",
            searchFilterValue: "user_name",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Username
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue, tableFilter.sortBy === header.column.id)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            accessor: "staffUserName",
        },
        {
            id: "staffID",
            searchFilterValue: "staff_id",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Employee ID
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue, tableFilter.sortBy === header.column.id)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            accessor: "staffID",
        },
        {
            id: "staffPhoneNumber",
            searchFilterValue: "phone_number",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Contact No
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue, tableFilter.sortBy === header.column.id)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            accessor: "staffPhoneNumber",
        },
        {
            id: "staffEmail",
            searchFilterValue: "email",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Email
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue, tableFilter.sortBy === header.column.id)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            rowClassName: "row__ellipsis",
            accessor: "staffEmail",
            Cell: (row) => {
                return <div className="react-table__email">{row.row.original.staffEmail}</div>;
            },
        },
        {
            id: "staffStatus",
            searchFilterValue: "staffStatus",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Status
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue, tableFilter.sortBy === header.column.id)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            accessor: "staffStatus",
            Cell: (row) => {
                return <AppStatus label={row.row.original.staffStatus} column={true} />;
            },
        },
        {
            id: "button",
            Header: (header) => {
                return <span onClick={() => headerFilterClick(header)} className="react-table__header"></span>;
            },
            Cell: () => {
                return <img className="react-table__edit-icon" src={iconEdit} alt="" />;
            },
            maxWidth: 50,
        },
    ];

    const selectedRow = (selectedRow) => {
        history.push(pathnames.pageEditEmployee.replace(":username", `value_${selectedRow.staffID}`));
    };

    const newEmployeeButton = () => {
        history.push(pathnames.pageNewEmployee);
    };

    const onPageChange = (event) => {
        if (tableSearchFilter.searchValue) {
            setTableSearchFilter({
                ...tableSearchFilter,
                pageNo: event.selected,
            });
        } else {
            setTableFilter({
                ...tableFilter,
                pageNo: event.selected,
            });
        }
    };

    const searchBarOnChange = (e) => {
        setIdRowSelected([]);
        setSearchValue(e.target.value);
        if (e.target.value) {
            setTableSearchFilter({
                pageSize: 5,
                pageNo: 0,
                sortBy: "id",
                sortDir: "asc",
                searchValue: e.target.value,
            });
        } else {
            setTableFilter({
                pageSize: 5,
                pageNo: 0,
                sortBy: "id",
                sortDir: "asc",
            });
        }
    };

    const handleKeypress = (e) => {
        if (e.code === "Enter") {
            if (e.target.value) {
                setTableSearchFilter({
                    pageSize: 5,
                    pageNo: 0,
                    sortBy: "id",
                    sortDir: "asc",
                    searchValue: e.target.value,
                });
            } else {
                setTableFilter({
                    pageSize: 5,
                    pageNo: 0,
                    sortBy: "id",
                    sortDir: "asc",
                });
            }
        }
    };

    const onDrop = (e) => {
        setBulkUploadErrorMessage("");
        if (e.length > 1) {
            setDropFileErrorMessage("Only one file is allowed.");
        } else if (e.length && e[0].type && (e[0].type === "application/vnd.ms-excel" || e[0].type === "text/csv")) {
            setDropFileErrorMessage("");
            setCsvFiles([e[0]]);
            setModalIsOpenSequence([...modalIsOpenSequence, "MODAL_UPLOAD_FILES"]);
        } else {
            setDropFileErrorMessage("Failed to upload file; Please upload a csv file.");
        }
    };

    const removeFilesToUpload = (file) => {
        if (csvFiles.length === 1) {
            setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
            setCsvFiles([]);
        } else {
            let removedFiles = csvFiles.filter((ele, index) => ele.name !== file.name);
            setCsvFiles(removedFiles);
        }
    };

    const confirmBulkUpload = async () => {
        setBulkUploadErrorMessage("");
        setBulkUploadConfirmButton({
            label: "Uploading",
            disabled: true,
        });
        try {
            const formData = new FormData();
            formData.append("file", csvFiles[0]);

            const response = await api.post.employeeBulkUpload(formData);
            setCsvFiles([]);
            setModalIsOpenSequence([]);
            appToast(response.data.message, "true");
            setBulkUploadConfirmButton({
                label: "Confirm",
                disabled: false,
            });
            getUserManagementTableDataNoSearchValue(tableFilter);
        } catch (error) {
            let sanitizedError = sanitizeError(error);
            setBulkUploadErrorMessage(sanitizedError);
            setBulkUploadConfirmButton({
                label: "Confirm",
                disabled: false,
            });
        }
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: ".csv" });

    const getBulkUploadDragDropClassNames = (error) => {
        const classNames = ["bulk-upload__upload-container"];
        if (error) classNames.push("bulk-upload__upload-container--error");
        return classNames.join(" ");
    };

    const exportHandler = async () => {
        if (idRowSelected.length) {
            try {
                let payload = { id: idRowSelected };
                const response = await api.post.employeeExport(payload);
                const data = response.data;

                let hiddenElement = document.createElement("a");
                const BOM = '\uFEFF';
                const blob = new Blob([BOM + data], { type: 'text/csv;charset=utf-8' });
                const url = window.URL.createObjectURL(blob);
                hiddenElement.href = url;
                hiddenElement.target = "_blank";
                hiddenElement.download = "ATF_user.csv";
                hiddenElement.click();
            } catch (error) {
                const sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        }
    };

    const downloadUsermanagementTemplate = async (version) => {
        try {
            const response = await api.get.userManagementDownloadTemplateVersion(version);
            let blob = new Blob([response.data], { type: "text/csv" });
            var URL = window.URL;
            var downloadUrl = URL.createObjectURL(blob);
            var downloadLink = document.createElement("a");
            downloadLink.target = "_blank";
            downloadLink.download = `user_upload_template_${version}.csv`;
            downloadLink.href = downloadUrl;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            appToast(sanitizeError(error), false);
        }
    };

    return (
        <AppPagesLayout>
            <div className="page-user-management">
                <AppModal
                    isOpenModal={modalIsOpenSequence.slice(-1)[0] === "MODAL_SELECT_FILES"}
                    onRequestClose={() => {
                        setDropFileErrorMessage("");
                        setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
                    }}
                    title="Bulk Upload"
                    isBodyCenter={true}>
                    <div className="page-user-management__bulk-upload">
                        <div className="bulk-upload__upload-contents">
                            <input className="bulk-upload__input" {...getInputProps()} />
                            <div {...getRootProps({ className: "dropzone" })}>
                                <div className={getBulkUploadDragDropClassNames(dropFileErrorMessage)}>
                                    <BsUpload size={32} />
                                    <span className="bulk-upload__upload-title">Drag and Drop file</span>
                                    <span className="bulk-upload__upload-subtitle">or</span>
                                    <div className="bulk-upload__button-wrapper">
                                        <AppButton size="s" buttonType="outline" label="Browse File" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bulk-upload__error-message">
                            <span>{dropFileErrorMessage}</span>
                        </div>
                        <div className="bulk-upload__template-contents">
                            <span className="bulk-upload__template-detail">Don't have the template?</span>
                            <button className="bulk-upload__template-link" onClick={() => downloadUsermanagementTemplate(templateVersionNumber)}>
                                Click here to download {templateVersionNumber}
                            </button>
                        </div>
                    </div>
                </AppModal>
                <AppModal
                    title="Bulk Upload"
                    isOpenModal={modalIsOpenSequence.slice(-1)[0] === "MODAL_UPLOAD_FILES"}
                    onRequestClose={() => {
                        setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
                        setCsvFiles([]);
                    }}>
                    <div className="page-user-management__bulk-uploading">
                        <div className="bulk-uploading__subtitle">Uploaded file</div>
                        {csvFiles.length &&
                            csvFiles.map((ele, index) => (
                                <div className="bulk-uploading__body" key={index}>
                                    <img src={iconFileCsv} alt="" />
                                    <div className="bulk-uploading__contents">
                                        <div className="bulk-uploading__file-details">
                                            <span className="bulk-uploading__file-title">{ele.name}</span>
                                            <span className="bulk-uploading__file-size">
                                                {(parseInt(ele.size) / 1000).toFixed(2)}
                                                {` `}MB
                                            </span>
                                        </div>
                                        <div className="bulk-uploading__icon-button">
                                            {bulkUploadConfirmButton.disabled ? (
                                                <div>
                                                    <ReactLoading type="spin" color="blue" height="16px" width="16px" />
                                                </div>
                                            ) : null}
                                            {bulkUploadErrorMessage ? (
                                                <div id="bulkUploadError">
                                                    <ReactTooltip id="bulkUploadError">
                                                        <span className="bulk-uploading__error-message">{bulkUploadErrorMessage}</span>
                                                    </ReactTooltip>
                                                    <BsExclamationCircle data-tip data-for="bulkUploadError" color="#dc4c4c" size={16} />
                                                </div>
                                            ) : null}
                                            <div className="bulk-uploading__icon-close" onClick={() => removeFilesToUpload(ele)}>
                                                <CgCloseO size={16} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        <div className="bulk-uploading__button-row">
                            <div className="bulk-uploading__button-wrapper">
                                <AppButton
                                    label="Cancel"
                                    buttonType="outline"
                                    size="s"
                                    onClick={() => {
                                        setBulkUploadErrorMessage("");
                                        setModalIsOpenSequence(modalIsOpenSequence.slice(0, -1));
                                        setCsvFiles([]);
                                    }}
                                />
                            </div>
                            <div className="bulk-uploading__button-wrapper">
                                <AppButton label={bulkUploadConfirmButton.label} size="s" onClick={confirmBulkUpload} disabled={bulkUploadConfirmButton.disabled} />
                            </div>
                        </div>
                    </div>
                </AppModal>
                <div className="page-user-management__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>
                <div className="page-user-management__action-row">
                    <div className="page-user-management__searchBar-wrapper">
                        <AppInput leftSrc={iconSearch} placeholder="Search keyword" onChange={_.debounce((e) => searchBarOnChange(e), 1000)} onKeyPress={handleKeypress} />
                    </div>
                    <div className="page-user-management__buttons-wrapper">
                        <div className="page-user-management__export-wrapper">
                            <AppButton size="s" buttonType="outline" label="Export" buttonIcon={<BsShare />} disabled={!idRowSelected.length} onClick={exportHandler} />
                        </div>
                        <div className="page-user-management__new-employee-wrapper">
                            <AppButton size="s" label="New Employee" buttonIcon={<BsPlusLg size={12} />} onClick={newEmployeeButton} />
                        </div>
                        <div className="page-user-management__bulk-upload-wrapper">
                            <AppButton size="s" label="Bulk Upload" buttonIcon={<BsUpload />} onClick={() => setModalIsOpenSequence(["MODAL_SELECT_FILES"])} />
                        </div>
                    </div>
                </div>
                {tableData.content && tableData.content.length ? (
                    <div className="page-user-management">
                        <AppReactTable columns={tableColumn} data={tableData.content} onClickRow={selectedRow} checkedIdArray={idRowSelected} idSelectAll={idRowSelected.length} totalItems={allId.length} />
                    </div>
                ) : (
                    <div className="page-user-management__react-table">
                        <AppReactTable columns={tableColumn} data={[]} />
                    </div>
                )}
                <div className="page-user-management__paginate-wrapper">
                    <AppPaginate onPageChange={onPageChange} pageCount={tableData.totalPages} forcePage={tableData.pageNo} />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageUserManagement;
