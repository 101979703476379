// libraries
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
// components
import AppPagesLayout from "components/app-pages-layout";
import AppBreadcrumb from "components/app-breadcrumb";
import AppTabLink from "components/app-tab-link";
import AppInput from "components/app-input";
import AppReactTable from "components/app-react-table";
import AppPaginate from "components/app-paginate";
import appToast from "components/app-toast";
// route
import pathnames from "routes/pathnames";
// services
import api from "services/api";
// common
import { sanitizeError } from "common/utilities";
// assets
import iconSearch from "assets/images/components/app-input/icon-search.svg";
import iconTailedArrowUp from "assets/images/icon-tailed-arrow-up.svg";
// hooks
import usePrevious from "hooks/use-previous";

const breadcrumb = [
    {
        label: "Claim Management",
        onClick: null,
    },
    {
        label: "Claim Directory",
    },
];

const appTabLink = [
    {
        label: "Claim Directory",
        pathname: pathnames.pageClaimDirectory,
    },
    {
        label: "Pending Approval",
        pathname: pathnames.pageClaimPendingApproval,
    },
    {
        label: "Pending Payment",
        pathname: pathnames.pageClaimPendingPayment,
    },
];

const PageClaimDirectory = () => {
    const history = useHistory();

    const [tableSearchFilter, setTableSearchFilter] = useState({
        pageNo: 0,
        pageSize: 5,
        searchValue: "",
        sortBy: "",
        sortDir: "asc",
    });
    const [tableData, setTableData] = useState({
        content: [],
        last: null,
        pageNo: 0,
        pageSize: 5,
        totalElements: null,
        totalPages: 1,
    });
    const prevValueSortHeader = usePrevious({
        sortBy: tableSearchFilter.sortBy,
        sortDir: tableSearchFilter.sortDir,
    });

    useEffect(() => {
        const getTableData = async () => {
            try {
                const response = await api.get.claimManagementClaimDirectory(tableSearchFilter);
                setTableData(response.data.result);
            } catch (error) {
                let sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        };
        getTableData();
    }, [tableSearchFilter]);

    const headerFilterClick = (header) => {
        let column = header.column;
        if (prevValueSortHeader && prevValueSortHeader.sortBy === column.searchFilterValue) {
            if (prevValueSortHeader.sortDir === "asc") {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "desc",
                    pageNo: 0,
                });
            } else {
                setTableSearchFilter({
                    ...tableSearchFilter,
                    sortBy: column.searchFilterValue,
                    sortDir: "asc",
                    pageNo: 0,
                });
            }
        } else {
            setTableSearchFilter({
                ...tableSearchFilter,
                sortBy: column.searchFilterValue,
                sortDir: "asc",
                pageNo: 0,
            });
        }
    };

    const headerArrowRotate = (active) => {
        const classNames = ["react-table__arrow-icon"];
        if (active && tableSearchFilter.sortDir === "desc") classNames.push("react-table__arrow-icon-down");
        if (active) classNames.push("react-table__arrow-icon--active");

        return classNames.join(" ");
    };

    const tableColumn = [
        {
            id: "staffID",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__employee-id-header  react-table__header">
                        Employee ID
                        <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />
                    </span>
                );
            },
            Cell: (row) => {
                return <span className="react-table__employee-id-data">{row.row.original.staffID}</span>;
            },
            searchFilterValue: "staff_id",
        },
        {
            id: "staffUserName",
            Header: (header) => {
                return (
                    <span onClick={() => headerFilterClick(header)} className="react-table__header">
                        Username <img className={headerArrowRotate(tableSearchFilter.sortBy === header.column.searchFilterValue)} src={iconTailedArrowUp} alt="" />{" "}
                    </span>
                );
            },
            searchFilterValue: "user_name",
            accessor: "staffUserName",
        },
    ];

    const searchKeywordTyped = (value) => {
        setTableSearchFilter({
            pageNo: 0,
            pageSize: 5,
            searchValue: value,
            sortBy: "",
            sortDir: "asc",
        });
    };

    const searchBarOnChange = (e) => {
        searchKeywordTyped(e.target.value);
    };

    const handleKeypress = (e) => {
        if (e.code === "Enter") {
            searchKeywordTyped(e.target.value);
        }
    };

    const selectedRow = (selectedRow) => {
        history.push(pathnames.pageClaimSummary.replace(":pathStaffId", "value_" + ("000" + selectedRow.staffID).slice(-3)));
    };

    const onPageChange = (e) => {
        setTableSearchFilter({
            ...tableSearchFilter,
            pageNo: e.selected,
        });
    };

    return (
        <AppPagesLayout>
            <div className="page-claim-directory">
                <div className="page-claim-directory__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>
                <div className="page-claim-directory__tab-wrapper">
                    <AppTabLink tabList={appTabLink} />
                </div>

                <div className="page-claim-directory__searchBar-wrapper">
                    <AppInput leftSrc={iconSearch} placeholder="Search keyword" onChange={_.debounce((e) => searchBarOnChange(e), 1000)} onKeyPress={handleKeypress} />
                </div>

                <div className="page-claim-directory__table-wrapper">
                    <AppReactTable onClickRow={selectedRow} columns={tableColumn} data={tableData.content} searchKeyword={tableSearchFilter.searchValue} />
                </div>

                <div className="page-claim-directory__pagination">
                    <AppPaginate onPageChange={onPageChange} pageCount={tableData.totalPages} forcePage={tableSearchFilter.pageNo} />
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageClaimDirectory;
