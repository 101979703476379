const employeePathnames = {
    pageEmployeeDashboard: "/employee/dashboard",
    pageEmployeeDashboardProfileAndSettings: "/employee/dashboard/profile-settings",

    pageEmployeeUserManagement: "/employee/user-management",
    pageEmployeeDetails: "/employee/user-management/:staffId",

    pageEmployeeLeaveCalendar: "/employee/leave-management/leave-calendar",
    pageEmployeeLeaveSummary: "/employee/leave-management/leave-summary",
    pageEmployeeLeaveHistory: "/employee/leave-management/leave-history",
    pageEmployeePendingLeave: "/employee/leave-management/pending-leave",

    pageEmployeeClaimSummary: "/employee/claim-management/claim-summary",
    pageEmployeeClaimHistory: "/employee/claim-management/claim-history",
    pageEmployeePendingClaim: "/employee/claim-management/pending-claim",

    pageEmployeePayslip: "/employee/payslip",

    pageEmployeeUpcomingSchedule: "/employee/coaching-session/upcoming-schedule",
    pageEmployeeCoachingHistory: "/employee/coaching-session/coaching-history",
};

export default employeePathnames;