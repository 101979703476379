// libraries
import React, { useState, useEffect, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import moment from "moment";
import * as yup from "yup";

import api from "services/api";
import pathnames from "routes/pathnames";

import ERRORS from "common/errors";
import CONSTANT from "common/constant";
import { sanitizeError, formatNRIC } from "common/utilities";

import { updateProfile } from "redux/slices/auth-slice";

import appToast from "components/app-toast";
import AppStatus from "components/app-status";
import AppButton from "components/app-button";
import AppDropdown from "components/app-dropdown";
import AppCheckbox from "components/app-checkbox";
import AppTextArea from "components/app-text-area";
import AppInputDate from "components/app-input-date";
import AppBreadcrumb from "components/app-breadcrumb";
import AppPagesLayout from "components/app-pages-layout";
import AppImageUpload from "components/app-image-upload";
import AppInputWithLabel from "components/app-input-with-label";
import AppModalConfirmation from "components/app-modal-confirmation";

const currentDate = moment();
const currentDateAfterFormat = moment(new Date()).format("DD/MM/YYYY");

const typeOfIdDropdownOption = [
    { label: "IC No.", value: "ic no." },
    { label: "Passport No.", value: "passport" },
];

const employmentEndStatusOptions = [
    { label: "Resigned", value: 1 },
    { label: "Dismissal", value: 2 },
    { label: "Termination", value: 3 },
];

const PageEmployee = () => {
    const [pageDataValues, setPageDataValues] = useState({});
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [dropdownOptionsBE, setDropdownOptionsBE] = useState({
        position: [],
        reportingManager: [],
    });
    const [staffPositionList, setStaffPositionList] = useState([]);
    const history = useHistory();
    const location = useLocation();
    const { username } = useParams();
    const [dateErrors, setDateErrors] = useState({
        joinedDate: "",
        probationEnd: "",
        promotionDate: "",
        lastEmpDate: "",
    });
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.auth);
    const staffId = profile.staffID;
    const [employeeInfo, setEmployeeInfo] = useState({});
    const [profileImgUrl, setProfileImgUrl] = useState(null);
    const [positionLevel, setPositionLevelList] = useState([]);
    const [contractTypeList, setContractTypeList] = useState([]);

    const employeeInfoFields = useMemo(() => {
        const newFields = [
            { name: "joinedDate", placeholder: "Joined Date", type: "date" },
            { name: "probationEnd", placeholder: "Probation End Date", type: "date" },
            { name: "positionLevelId", placeholder: "Level", type: "dropdown" },
            { name: "position", placeholder: "Position", type: "dropdown" },
            { name: "contractType", placeholder: "Contract Type", type: "dropdown" },
            { name: "reportingManager", placeholder: "Reporting Manager", type: "dropdown" },
        ];
        const editFields = [
            { name: "joinedDate", placeholder: "Joined Date", type: "date" },
            { name: "probationEnd", placeholder: "Probation End Date", type: "date" },
            { name: "promotionDate", placeholder: "Latest Promotion Date", type: "date" },
            { name: "lastEmpDate", placeholder: "Last Employment", type: "date" },
            { name: "exitStatus", placeholder: "Employment End Status", type: "dropdown" },
            { name: "positionLevelId", placeholder: "Level", type: "dropdown" },
            { name: "position", placeholder: "Position", type: "dropdown" },
            { name: "contractType", placeholder: "Contract Type", type: "dropdown" },
            { name: "reportingManager", placeholder: "Reporting Manager", type: "dropdown" },
        ];

        if (employeeInfo.id) {
            return editFields;
        } else {
            return newFields;
        }
    }, [employeeInfo.id]);

    const employeePersonalInfoFields = useMemo(() => {
        const newFields = [
            { name: "staffId", placeholder: "Employee ID", type: "staffId", disabled: true },
            { name: "name", placeholder: "Full Name" },
            { name: "userName", placeholder: "Username" },
            { name: "typeOfId", placeholder: "Identification Type", type: "dropdown" },
            { name: "nric", placeholder: "IC No.", type: "ic" },
            { name: "passport", placeholder: "Passport No." },
            { name: "dob", placeholder: "Date of Birth", type: "date" },
            { name: "phoneNumber", placeholder: "Mobile Number", type: "mobile" },
            { name: "email", placeholder: "Work Email" },
            { name: "personalEmail", placeholder: "Personal Email" },
            { name: "address", placeholder: "Address", type: "textarea" },
        ];
        const editFields = [
            { name: "staffID", placeholder: "Employee ID", disabled: true },
            { name: "name", placeholder: "Full Name" },
            { name: "userName", placeholder: "Username" },
            { name: "typeOfId", placeholder: "Identification Type", type: "dropdown" },
            { name: "nric", placeholder: "IC No.", type: "ic" },
            { name: "passport", placeholder: "Passport No." },
            { name: "dob", placeholder: "Date of Birth", type: "date" },
            { name: "phoneNumber", placeholder: "Mobile Number", type: "mobile" },
            { name: "email", placeholder: "Work Email" },
            { name: "personalEmail", placeholder: "Personal Email" },
            { name: "address", placeholder: "Address", type: "textarea" },
        ];

        if (employeeInfo.id) {
            return editFields;
        } else {
            return newFields;
        }
    }, [employeeInfo.id]);

    const validationSchema = useMemo(() => {
        const newValidationSchema = yup.object().shape({
            joinedDate: yup.string().required(ERRORS.REQUIRED).nullable(true),
            probationEnd: yup.string().required(ERRORS.REQUIRED).nullable(true),
            position: yup.string().required(ERRORS.REQUIRED),
            positionLevelId: yup.string().required(ERRORS.REQUIRED),
            contractType: yup.string().required(ERRORS.REQUIRED),
            role: yup.string().required(ERRORS.REQUIRED),
            name: yup.string().required(ERRORS.REQUIRED),
            userName: yup.string().required(ERRORS.REQUIRED),
            typeOfId: yup.string(),
            nric: yup.string().when(["typeOfId"], (typeOfId, schema) => {
                if (typeOfId && typeOfId?.toLowerCase() === "ic no.") {
                    return yup
                        .string()
                        .required(ERRORS.REQUIRED)
                        .test("", ERRORS.IC, (value) => {
                            let valueWithoutDash = value && value.split("-").join("");

                            if (value && valueWithoutDash.length === 12) {
                                if (value && valueWithoutDash.length >= 8 && valueWithoutDash.length <= 12) {
                                    let date = value.substring(0, 6);
                                    let dateChecking = moment(date, "YYMMDD");
                                    let location = valueWithoutDash.substring(6, 8);
                                    let locationChecking = CONSTANT.STATE_NUMBER.includes(location);

                                    if (!dateChecking._isValid || !locationChecking) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                } else {
                                    return true;
                                }
                            } else {
                                return false;
                            }
                        });
                }
            }),
            passport: yup.string().when("typeOfId", {
                is: "passport",
                then: () =>
                    yup
                        .string()
                        .required(ERRORS.REQUIRED)
                        .test("passport", ERRORS.ONLY9CHAR, (value) => value && value.toString().length === 9)
                        .matches(/^[a-z0-9]+$/i, ERRORS.PASSPORT),
            }),
            dob: yup
                .string()
                .required("Date of birth is required")
                .test("dob", "Invalid date", (value) => {
                    if (value) {
                        let valueWithoutSpaces = value.replace(/\s/g, "");
                        return "Invaliddate" !== valueWithoutSpaces;
                    }
                }),
            email: yup.string().email(ERRORS.WORK_EMAIL).required(ERRORS.REQUIRED),
            phoneNumber: yup
                .string()
                .matches(/^[\d-]+$/, ERRORS.MOBILE)
                .required(ERRORS.REQUIRED),
            personalEmail: yup.string().email(ERRORS.PERSONAL_EMAIL),
        });

        const editValidationSchema = yup.object().shape({
            exitStatus: yup
                .object()
                .when(["lastEmpDate"], (lastEmpDate) => {
                    if (lastEmpDate) {
                        return yup.string().required(ERRORS.REQUIRED).nullable(true);
                    }
                })
                .nullable(),
            position: yup.string().required(ERRORS.REQUIRED),
            positionLevelId: yup.string().required(ERRORS.REQUIRED),
            contractType: yup.string().required(ERRORS.REQUIRED),
            role: yup.string().required(ERRORS.REQUIRED),
            name: yup.string().required(ERRORS.REQUIRED),
            userName: yup.string().required(ERRORS.REQUIRED),
            typeOfId: yup.string(),
            nric: yup.string().when(["typeOfId"], (typeOfId) => {
                if (typeOfId && typeOfId.toLowerCase() === "ic no.") {
                    return yup
                        .string()
                        .required(ERRORS.REQUIRED)
                        .test("", ERRORS.IC, (value) => {
                            let valueWithoutDash = value && value.split("-").join("");

                            if (value && valueWithoutDash.length >= 8 && valueWithoutDash.length <= 12) {
                                let date = value.substring(0, 6);
                                let dateChecking = moment(date, "YYMMDD");
                                let location = valueWithoutDash.substring(6, 8);
                                let locationChecking = CONSTANT.STATE_NUMBER.includes(location);

                                if (!dateChecking._isValid || !locationChecking) {
                                    return false;
                                } else {
                                    return true;
                                }
                            } else {
                                return true;
                            }
                        });
                }
            }),
            passport: yup.string().when("typeOfId", {
                is: "passport",
                then: () =>
                    yup
                        .string()
                        .required(ERRORS.REQUIRED)
                        .test("passport", ERRORS.ONLY9CHAR, (value) => value && value.toString().length === 9)
                        .matches(/^[a-z0-9]+$/i, ERRORS.PASSPORT),
            }),
            dob: yup
                .string()
                .required(ERRORS.REQUIRED)
                .test("", ERRORS.DATE, (value) => {
                    if (value) {
                        let valueWithoutSpaces = value.replace(/\s/g, "");
                        return "Invaliddate" !== valueWithoutSpaces;
                    }
                })
                .nullable(true),
            email: yup.string().email(ERRORS.WORK_EMAIL).required(ERRORS.REQUIRED),
            phoneNumber: yup
                .string()
                .matches(/^[\d-]+$/, ERRORS.MOBILE)
                .required(ERRORS.REQUIRED),
            personalEmail: yup.string().email(ERRORS.PERSONAL_EMAIL),
        });

        if (employeeInfo.id) {
            return editValidationSchema;
        } else {
            return newValidationSchema;
        }
    }, [employeeInfo.id]);

    const initialValues = useMemo(() => {
        let value = {
            image: "", // in multipart format
            joinedDate: "",
            probationEnd: "",
            position: "",
            staffPosition: "",
            positionLevelId: "",
            contractType: "",
            reportingManager: "",
            role: "",
            staffId: "",
            name: "",
            userName: "",
            typeOfId: "",
            nric: "",
            passport: "",
            dob: "",
            phoneNumber: "",
            email: "",
            personalEmail: "",
            address: "",
        };

        if (employeeInfo) {
            value = {
                image: employeeInfo.image, // in multipart format
                joinedDate: employeeInfo.joinedDate,
                probationEnd: employeeInfo.probationEnd,
                promotionDate: employeeInfo.promotionDate,
                lastEmpDate: employeeInfo.lastEmpDate,
                exitStatus: employeeInfo.exitStatus,
                position: employeeInfo.staffPosition ? staffPositionList.find((o) => o.label === employeeInfo.staffPosition)?.value : "",
                staffPosition: employeeInfo.staffPosition,
                positionLevelId: employeeInfo.staffPositionLevelId,
                contractType: employeeInfo.contractType,
                reportingManager: employeeInfo.reportingManager,
                staffID: employeeInfo.staffID,
                name: employeeInfo.staffName,
                userName: employeeInfo.staffUserName,
                nric: employeeInfo.staffNRIC,
                dob: employeeInfo.staffDOB,
                phoneNumber: employeeInfo.staffPhoneNumber,
                email: employeeInfo.staffEmail,
                personalEmail: employeeInfo.staffPersonalEmail,
                address: employeeInfo.staffAddress,
                status: employeeInfo.status,
                passport: employeeInfo.staffNRIC,
                typeOfId: employeeInfo.typeOfId?.toLowerCase(),
                role: employeeInfo.staffRole?.toLowerCase(),
            };
        }
        return value;
    }, [employeeInfo, staffPositionList]);

    const onHandleChange = (name, value) => {
        formik.setFieldTouched(name);
        formik.setFieldValue(name, value);
    };

    const updateConfirmed = async (location, pathnames, pageDataValues) => {
        const { status, image, staffPosition, ...dataForRequest } = pageDataValues;
        let inputData;
        let { passport, nric, ...dataExcludeIdentification } = dataForRequest;

        if (dataForRequest.typeOfId && dataForRequest.typeOfId?.toLowerCase() === "ic no.") {
            inputData = {
                ...dataExcludeIdentification,
                nricOrPassport: nric,
            };
        } else if (dataForRequest.typeOfId === "passport") {
            inputData = {
                ...dataExcludeIdentification,
                nricOrPassport: passport,
            };
        }

        if (location.pathname === pathnames.pageNewEmployee) {
            try {
                const formData = new FormData();
                const dataBlob = new Blob([JSON.stringify(inputData)], { type: "application/json" });
                let newImage = image;

                if (newImage === "") {
                    newImage = new Blob([""]);
                }
                formData.append("data", dataBlob);
                formData.append("image", newImage);
                await api.post.createNewEmployee(formData);

                appToast("Employee has been created successfully.", true, 300);
                history.push(pathnames.pageUserManagement);
            } catch (error) {
                const sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        } else {
            try {
                inputData = {
                    ...inputData,
                    adminId: employeeInfo.staffRole === "employee" ? staffId : null,
                };

                if (formik.values.position) {
                    inputData = {
                        ...inputData,
                        position: formik.values.position,
                    };
                } else {
                    inputData = {
                        ...inputData,
                        position: staffPositionList.filter((o) => o.label === employeeInfo.staffPosition)[0].value,
                    };
                }

                const formData = new FormData();
                const dataBlob = new Blob([JSON.stringify(inputData)], { type: "application/json" });
                // let imageBlob;
                let newImage;

                if (typeof image === "object") {
                    // upload new image
                    // imageBlob = new Blob([image]);
                    newImage = image;
                } else {
                    // remain old image
                    newImage = new Blob([""]);
                }

                if (newImage === null) {
                    newImage = new Blob([""]);
                }

                formData.append("data", dataBlob);
                formData.append("image", newImage);
                const response = await api.post.updateStaffDetails(formData);
                const result = response.data.result;

                if (result.staffID === profile.staffID) {
                    dispatch(
                        updateProfile({
                            ...profile,
                            staffName: result.staffName,
                            staffUserName: result.staffUserName,
                        })
                    );
                }

                appToast("Employee details has been updated successfully.", true, 300);
                history.push(pathnames.pageUserManagement);
            } catch (error) {
                const sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        }
    };

    const dateValidation = (name, values, value) => {
        if (value) {
            if (value === "Invalid date") {
                setDateErrors({
                    ...dateErrors,
                    [name]: "Invalid date",
                });
            } else {
                if (name === "joinedDate") {
                    const joinedDateMoment = moment(value, "DD/MM/YYYY");
                    const probationDateMoment = moment(values.probationEnd, "DD/MM/YYYY");
                    const lastEmpDateMoment = moment(values.lastEmpDate, "DD/MM/YYYY");

                    if (moment(joinedDateMoment).isAfter(lastEmpDateMoment)) {
                        setDateErrors({
                            ...dateErrors,
                            joinedDate: "Date is required to be before last employment date",
                        });
                        return;
                    } else if (moment(joinedDateMoment).isAfter(probationDateMoment)) {
                        setDateErrors({
                            ...dateErrors,
                            joinedDate: "Date is required to be before probation date",
                        });
                        return;
                    } else {
                        setDateErrors({
                            ...dateErrors,
                            joinedDate: "",
                        });
                        return;
                    }
                }

                if (name === "probationEnd") {
                    const probationDateMoment = moment(value, "DD/MM/YYYY");
                    const joinedDateMoment = moment(values.joinedDate, "DD/MM/YYYY");

                    if (moment(probationDateMoment).isBefore(joinedDateMoment)) {
                        setDateErrors({
                            ...dateErrors,
                            probationEnd: "Date is required to be after joined date",
                        });
                    } else {
                        setDateErrors({
                            ...dateErrors,
                            probationEnd: "",
                        });
                    }
                }
            }
        } else {
            setDateErrors({
                ...dateErrors,
                [name]: "Date is required",
            });
        }

        if (value === "Invalid date") {
            setDateErrors({
                ...dateErrors,
                [name]: "Invalid date",
            });
        } else {
            const joinedDateMoment = moment(values.joinedDate, "DD/MM/YYYY");
            const lastEmpDateMoment = moment(values.lastEmpDate, "DD/MM/YYYY");

            switch (name) {
                case "promotionDate":
                    const promotionDateMoment = moment(value, "DD/MM/YYYY");

                    if (moment(promotionDateMoment).isBefore(joinedDateMoment)) {
                        setDateErrors({
                            ...dateErrors,
                            promotionDate: "Date is required to be after joined date",
                        });
                        return;
                    } else if (moment(promotionDateMoment).isAfter(lastEmpDateMoment)) {
                        setDateErrors({
                            ...dateErrors,
                            promotionDate: "Date is required to be before last employment date",
                        });
                        return;
                    } else {
                        setDateErrors({
                            ...dateErrors,
                            promotionDate: "",
                        });
                        return;
                    }

                case "lastEmpDate":
                    if (moment(lastEmpDateMoment).isBefore(joinedDateMoment)) {
                        setDateErrors({
                            ...dateErrors,
                            lastEmpDate: "Date is required to be after joined date",
                        });
                    } else {
                        setDateErrors({
                            ...dateErrors,
                            lastEmpDate: "",
                        });
                        return;
                    }
                    break;
                default:
                    break;
            }
        }
    };

    const setEmployeeStatus = (setFieldValue, values, lastEmpDate, probationEnd, joinedDate, status) => {
        const lastEmpDateMoment = moment(lastEmpDate, "DD/MM/YYYY");

        if (status === "Permanent" || status === "permanent") {
            if (moment(lastEmpDateMoment).isBefore(currentDate)) {
                setFieldValue("status", "Resigned");
                setFieldValue("contractType", "Resigned");
                return;
            }
        } else {
            if (moment(lastEmpDateMoment).isBefore(currentDate)) {
                setFieldValue("status", "Resigned");
                setFieldValue("contractType", "Resigned");
                return;
            } else {
                if (moment(lastEmpDateMoment).isAfter(currentDate)) {
                    if (values.contractType === "Resigned") {
                        setFieldValue("contractType", "");
                    }
                }
                return;
            }
        }
    };

    const adminCheckbox = (setFieldTouched, setFieldValue, values) => {
        setFieldTouched("role");

        switch (values.role) {
            case "employee":
                setFieldValue("role", "admin owner");
                break;
            case "admin":
                setFieldValue("role", "");
                break;
            case "admin owner":
                setFieldValue("role", "employee");
                break;
            default:
                setFieldValue("role", "admin");
                break;
        }
    };

    const employeeCheckbox = (setFieldTouched, setFieldValue, values) => {
        setFieldTouched("role");

        switch (values.role) {
            case "admin":
                setFieldValue("role", "admin owner");
                break;
            case "employee":
                setFieldValue("role", "");
                break;
            case "admin owner":
                setFieldValue("role", "admin");
                break;
            default:
                setFieldValue("role", "employee");
                break;
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            setConfirmModalOpen(true);
            setPageDataValues(values);
        },
    });

    const breadcrumb = [
        {
            label: "User Management",
            pathname: pathnames.pageUserManagement,
        },
        {
            label: employeeInfo.id ? "Edit Employee" : "New Employee",
        },
    ];

    const onHandleSelectedPosition = (name, selected) => {
        onHandleChange(name, selected.label);
        formik.setFieldValue("position", selected.value?.toString());
    };

    const onHandleSelectedPositionLevel = (name, selected) => {
        onHandleChange(name, selected.label);
        formik.setFieldValue("positionLevelId", selected.value?.toString());
    };

    const onHandleCalendarDate = (name, value) => {
        const values = formik.values;
        const lastEmployeeDateField = name === "lastEmpDate";

        formik.setFieldTouched(name);

        dateValidation(name, value, value);

        if (value === "") {
            if (lastEmployeeDateField) {
                formik.setFieldValue("exitStatus", "");
            }

            formik.setFieldValue(name, null);

            if (values.contractType === "Resigned") {
                formik.setFieldValue("contractType", "");
            }
        } else {
            formik.setFieldValue(name, value, true);

            switch (name) {
                case "probationEnd":
                    setEmployeeStatus(formik.setFieldValue, values, values.lastEmpDate, value, values.joinedDate, values.status);
                    break;
                case "lastEmpDate":
                    setEmployeeStatus(formik.setFieldValue, values, value, values.probationEnd, values.joinedDate, values.status);
                    break;
                case "joinedDate":
                    setEmployeeStatus(formik.setFieldValue, values, value.lastEmpDate, values.probationEnd, value, values.status);
                    break;
                default:
                    break;
            }
        }
    };

    const onHandleLastEmpDate = (name, selected) => {
        onHandleChange(name, selected.label);

        if (selected.label === "Resigned") {
            formik.setFieldValue("lastEmpDate", currentDateAfterFormat);
        }
    };

    const onHandleIdentificationType = (name, selected) => {
        onHandleChange(name, selected.value);
        formik.setFieldValue("nric", "");
        formik.setFieldValue("passport", "");
        formik.setFieldValue("dob", "");
    };

    const onHandleIcBlur = (name, values) => {
        let removedDashValue = values.nric.split("-").join("");

        if (removedDashValue.length >= 12) {
            let withDashesValue = removedDashValue.substring(0, 6) + "-" + removedDashValue.substring(6, 8) + "-" + removedDashValue.substring(8);
            formik.setFieldValue(name, withDashesValue);
            let year;
            let currentYear = currentDate.format("YY");

            if (parseInt(withDashesValue.substring(0, 2)) < parseInt(currentYear)) {
                year = `20${withDashesValue.substring(0, 2)}`;
            } else {
                year = `19${withDashesValue.substring(0, 2)}`;
            }

            let date = `${withDashesValue.substring(4, 6)}/${withDashesValue.substring(2, 4)}/${year}`;
            formik.setFieldValue("dob", date);
        } else {
            formik.setFieldValue("dob", "");
        }
    };

    const onHandleMobileBlur = (name, values) => {
        let removedDashValue = values.phoneNumber.split("-").join("");

        if (removedDashValue >= 10) {
            let withDashValue = removedDashValue.substring(0, 3) + "-" + removedDashValue.substring(3);
            formik.setFieldValue(name, withDashValue);
        }
    };

    useEffect(() => {
        const populateFields = () => {
            if (username) {
                const onHandleUsername = async () => {
                    try {
                        let pathUsernameValue = username.replace("value_", "");
                        let payload = {
                            staffId: pathUsernameValue,
                        };
                        const response = await api.get.getUsernameDetails(payload);
                        const result = response.data.result[0];
                        setEmployeeInfo(result);
                        setProfileImgUrl(result?.image);
                    } catch (error) {
                        const errorMessage = sanitizeError(error);
                        appToast(errorMessage, false, 300);
                        history.push(pathnames.pageUserManagement);
                    }
                };
                onHandleUsername();
            }
        };

        const onHandleStaffNamePosition = async () => {
            try {
                const response = await api.get.getStaffNamePosition();
                const labelUsername = response.data.result.staffNameList.map((o) => {
                    return {
                        ...o,
                        label: o.userName,
                    };
                });

                const position = [...response.data.result.positionList];
                setStaffPositionList(response.data.result.positionList);
                setDropdownOptionsBE({
                    position,
                    reportingManager: [{ id: 0, value: "", value2: "", userName: "none", label: "none" }, ...labelUsername],
                });
                populateFields();
            } catch (error) {
                const sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        };
        onHandleStaffNamePosition();

        const onHandleListingPositionLevel = async () => {
            try {
                const response = await api.get.getPositionLevel();
                const labelPositionLevel = response.data.result.map((o) => {
                    return {
                        ...o,
                        label: o.level,
                        value: o.id,
                    };
                });
                setPositionLevelList([...labelPositionLevel]);
            } catch (error) {
                const sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        };
        onHandleListingPositionLevel();

        const onHandleGetStaffContractType = async () => {
            try {
                const response = await api.get.userManagementStaffContractType();
                let contractTypeList = response.data.result.map((o) => {
                    return {
                        ...o,
                        label: o.name,
                        value: o.id,
                    };
                });
                setContractTypeList([...contractTypeList]);
            } catch (error) {
                const sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        };
        onHandleGetStaffContractType();
    }, [location, history, username]);

    const onHandleRemoveImage = async () => {
        setProfileImgUrl(null);

        if (profileImgUrl) {
            try {
                let staffId = username.replace("value_", "");
                const response = await api.delete.userManagementDeleteProfileImage(staffId);
                if (response) appToast("Employee profile has been deleted successfully.", true, 300);
            } catch (error) {
                const sanitizedError = sanitizeError(error);
                appToast(sanitizedError, false);
            }
        }
    };

    return (
        <AppPagesLayout>
            <div className="page-employee">
                <AppModalConfirmation
                    isOpenModal={confirmModalOpen}
                    onRequestClose={() => {
                        setConfirmModalOpen(false);
                    }}
                    details={"Confirm to create?"}
                    onClick={() => updateConfirmed(location, pathnames, pageDataValues)}
                    buttonLabel={"Confirm"}
                />
                <div className="page-employee__breadcrumb">
                    <AppBreadcrumb list={breadcrumb} />
                </div>
                <div className="page-employee__content">
                    <div className="page-employee__left">
                        <div className="page-employee__left-content">
                            <div className="page-employee__image-upload-wrapper">
                                <AppImageUpload
                                    onChange={(value) => {
                                        onHandleChange("image", value[0]);
                                    }}
                                    value={profileImgUrl}
                                />
                            </div>

                            {profileImgUrl && (
                                <div className="page-employee__remove-image" onClick={onHandleRemoveImage}>
                                    Remove profile photo
                                </div>
                            )}

                            <div className="page-employee__title">Employment Information</div>

                            {employeeInfoFields.map((item, index) => {
                                const name = item.name;
                                const placeholder = item.placeholder;
                                const values = formik.values;
                                const error = formik.errors[name];
                                const value = formik.values[name];
                                const touched = formik.touched[name];
                                switch (item.type) {
                                    case "date":
                                        const errorMessage = (touched, validationSchemaErrors, manualErrorChecking) => {
                                            if (touched) {
                                                if (validationSchemaErrors) {
                                                    return validationSchemaErrors;
                                                } else {
                                                    if (manualErrorChecking) {
                                                        return manualErrorChecking;
                                                    } else {
                                                        return null;
                                                    }
                                                }
                                            } else {
                                                return null;
                                            }
                                        };
                                        return (
                                            <div className="page-employee__input-wrapper" key={index}>
                                                <AppInputDate
                                                    placeholder={placeholder}
                                                    onChange={(value) => {
                                                        onHandleCalendarDate(name, value);
                                                    }}
                                                    maxDate={name === "latestCoachSession" || name === "promotionDate" ? "today" : null}
                                                    error={errorMessage(touched, error, dateErrors[name])}
                                                    value={value}
                                                />
                                            </div>
                                        );
                                    case "dropdown":
                                        if (name === "position" && dropdownOptionsBE.position.length) {
                                            const optValue = dropdownOptionsBE.position.filter((opts) => opts.value === value)[0];
                                            return (
                                                <div className="page-employee__input-wrapper" key={index}>
                                                    <AppDropdown
                                                        placeholder={placeholder}
                                                        onChange={(selected) => {
                                                            onHandleSelectedPosition(name, selected);
                                                        }}
                                                        dropdownOptions={dropdownOptionsBE.position}
                                                        error={touched && error}
                                                        value={value}
                                                        currentInputValue={value ? optValue : null}
                                                    />
                                                </div>
                                            );
                                        } else if (name === "positionLevelId") {
                                            const optValue = positionLevel.filter((opts) => opts.id === value)[0];
                                            return (
                                                <div className="page-employee__input-wrapper" key={index}>
                                                    <AppDropdown
                                                        placeholder={placeholder}
                                                        onChange={(selected) => {
                                                            onHandleSelectedPositionLevel(name, selected);
                                                        }}
                                                        dropdownOptions={positionLevel}
                                                        error={touched && error}
                                                        value={value}
                                                        currentInputValue={value || value === 0 ? optValue : null}
                                                        dropdownSequence={true}
                                                    />
                                                </div>
                                            );
                                        } else if (name === "contractType" && !values.lastEmpDate) {
                                            let currentContractType;

                                            if (values.contractType) {
                                                currentContractType = contractTypeList.filter((ele) => ele.label === values.contractType)[0];
                                            } else {
                                                currentContractType = null;
                                            }

                                            return (
                                                <div className="page-employee__input-wrapper" key={index}>
                                                    <AppDropdown
                                                        placeholder={item.placeholder}
                                                        onChange={(selected) => {
                                                            onHandleLastEmpDate(name, selected);
                                                        }}
                                                        dropdownOptions={contractTypeList}
                                                        error={touched && error}
                                                        value={value}
                                                        currentInputValue={currentContractType}
                                                        isDisabled={moment(moment(values.lastEmpDate, "DD/MM/YYYY")).isBefore(currentDate) ? true : false}
                                                    />
                                                </div>
                                            );
                                        } else if (name === "reportingManager" && dropdownOptionsBE.reportingManager.length) {
                                            return (
                                                <div className="page-employee__input-wrapper" key={index}>
                                                    <AppDropdown
                                                        placeholder={placeholder}
                                                        onChange={(selected) => {
                                                            onHandleChange(name, selected && selected.userName ? selected.userName : "");
                                                        }}
                                                        dropdownOptions={dropdownOptionsBE.reportingManager}
                                                        error={touched && error}
                                                        value={value}
                                                        currentInputValue={dropdownOptionsBE.reportingManager.filter((ele) => ele.userName === value)[0]}
                                                        isClearable={true}
                                                    />
                                                </div>
                                            );
                                        } else if (name === "exitStatus" && values.lastEmpDate) {
                                            return (
                                                <div className="page-employee__input-wrapper" key={index}>
                                                    <AppDropdown
                                                        placeholder={placeholder}
                                                        onChange={(selected) => {
                                                            onHandleChange(name, selected.label);
                                                        }}
                                                        dropdownOptions={employmentEndStatusOptions}
                                                        currentInputValue={employmentEndStatusOptions.filter((ele) => ele.label === value)[0]}
                                                        error={touched && error}
                                                        value={employmentEndStatusOptions.filter((ele) => ele.label === value).length ? employmentEndStatusOptions.filter((ele) => ele.label === values[name])[0].label : ""}
                                                    />
                                                </div>
                                            );
                                        } else return null;
                                    default:
                                        return null;
                                }
                            })}

                            <div className="page-employee__access-type">
                                <div className="page-employee__access-type-title">Access Type</div>
                                <div className="page-employee__selection">
                                    <div className="page-employee__checkbox-wrapper">
                                        <AppCheckbox
                                            error={formik.touched.role && formik.errors.role}
                                            onChange={() => adminCheckbox(formik.setFieldTouched, formik.setFieldValue, formik.values)}
                                            checked={formik.values.role === "admin" || formik.values.role === "admin owner"}
                                        />
                                    </div>
                                    <span className="page-employee__selection-label" onClick={() => adminCheckbox(formik.setFieldTouched, formik.setFieldValue, formik.values)}>
                                        Admin
                                    </span>
                                </div>

                                <div className="page-employee__selection">
                                    <div className="page-employee__checkbox-wrapper">
                                        <AppCheckbox
                                            error={formik.touched.role && formik.errors.role}
                                            onChange={() => employeeCheckbox(formik.setFieldTouched, formik.setFieldValue, formik.values)}
                                            checked={formik.values.role === "employee" || formik.values.role === "admin owner"}
                                        />
                                    </div>
                                    <span className="page-employee__selection-label" onClick={() => employeeCheckbox(formik.setFieldTouched, formik.setFieldValue, formik.values)}>
                                        Employee
                                    </span>
                                </div>

                                {formik.touched.role && formik.errors.role ? <div className="page-employee__error-message">{formik.errors.role}</div> : null}
                            </div>
                        </div>
                    </div>
                    <div className="page-employee__right">
                        <div className="page-employee__right-content">
                            {employeeInfo.id ? (
                                <div className="page-employee__app-status-wrapper">
                                    <AppStatus label={formik.values.status} />
                                </div>
                            ) : null}
                            <div className="page-employee__title">Personal Information</div>

                            {employeePersonalInfoFields.map((item, index) => {
                                const name = item.name;
                                const disable = item.disabled;
                                const placeholder = item.placeholder;
                                const values = formik.values;
                                const error = formik.errors[name];
                                const value = formik.values[name];
                                const touched = formik.touched[name];
                                switch (item.type) {
                                    case "date":
                                        const dateDisabled = name === "dob" && values.typeOfId === "passport" ? false : true;
                                        return (
                                            <div key={index} className="page-employee__input-wrapper">
                                                <AppInputDate
                                                    placeholder="Date of Birth"
                                                    onChange={(value) => {
                                                        onHandleChange(name, value ? value : "");
                                                    }}
                                                    maxDate="yesterday"
                                                    error={touched && error}
                                                    value={value}
                                                    disabled={dateDisabled}
                                                />
                                            </div>
                                        );
                                    case "dropdown":
                                        return (
                                            <div key={index} className="page-employee__input-wrapper">
                                                <AppDropdown
                                                    placeholder={item.placeholder}
                                                    onChange={(selected) => {
                                                        onHandleIdentificationType(name, selected);
                                                    }}
                                                    dropdownOptions={typeOfIdDropdownOption}
                                                    error={touched && !value ? `${placeholder} is required` : ""}
                                                    value={value && typeOfIdDropdownOption.filter((ele) => ele.value === value?.toLowerCase())[0] ? typeOfIdDropdownOption.filter((ele) => ele.value === value?.toLowerCase())[0].label : null}
                                                    currentInputValue={value ? typeOfIdDropdownOption.filter((ele) => ele.value === value?.toLowerCase())[0] : null}
                                                />
                                            </div>
                                        );
                                    case "ic":
                                        if (values.typeOfId?.toLowerCase() === "ic no.") {
                                            return (
                                                <div key={index} className="page-employee__input-wrapper">
                                                    <AppInputWithLabel
                                                        placeholder={placeholder}
                                                        onChange={(e) => {
                                                            onHandleChange(name, formatNRIC(e.target.value));
                                                        }}
                                                        error={touched && error}
                                                        value={value ? value : ""}
                                                        onBlur={() => onHandleIcBlur(name, values)}
                                                    />
                                                </div>
                                            );
                                        } else return null;
                                    case "mobile":
                                        return (
                                            <div key={index} className="page-employee__input-wrapper">
                                                <AppInputWithLabel
                                                    placeholder={placeholder}
                                                    onChange={(e) => {
                                                        onHandleChange(name, e.target.value);
                                                    }}
                                                    error={touched && error}
                                                    value={value}
                                                    onBlur={() => onHandleMobileBlur(name, values)}
                                                />
                                            </div>
                                        );
                                    case "textarea":
                                        return (
                                            <div key={index} className="page-employee__input-wrapper">
                                                <AppTextArea
                                                    placeholder={placeholder}
                                                    onChange={(e) => {
                                                        onHandleChange(name, e.target.value);
                                                    }}
                                                    error={touched && error}
                                                    value={value}
                                                />
                                            </div>
                                        );
                                    case "staffId":
                                        return (
                                            <div key={index} className="page-employee__input-wrapper">
                                                <AppInputWithLabel
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key) || value.length >= 3) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    placeholder={placeholder}
                                                    onChange={(e) => {
                                                        onHandleChange(name, e.target.value);
                                                    }}
                                                    error={touched && error}
                                                    value={value}
                                                    disabled={disable}
                                                />
                                            </div>
                                        );
                                    case "userName":
                                        return (
                                            <div key={index} className="page-employee__input-wrapper">
                                                <AppInputWithLabel
                                                    placeholder={placeholder}
                                                    onChange={(e) => {
                                                        onHandleChange(
                                                            name,
                                                            e.target.value
                                                                .toLowerCase()
                                                                .split(" ")
                                                                .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
                                                                .join(" ")
                                                        );
                                                    }}
                                                    error={touched && error}
                                                    value={value}
                                                    disabled={disable}
                                                />
                                            </div>
                                        );
                                    default:
                                        if (name === "passport" && values.typeOfId !== "passport") {
                                            return null;
                                        } else {
                                            return (
                                                <div key={index} className="page-employee__input-wrapper">
                                                    <AppInputWithLabel
                                                        placeholder={placeholder}
                                                        onChange={(e) => {
                                                            onHandleChange(name, e.target.value);
                                                        }}
                                                        error={touched && error}
                                                        value={value}
                                                        disabled={disable}
                                                    />
                                                </div>
                                            );
                                        }
                                }
                            })}

                            <div className="page-employee__button-row">
                                <div className="page-employee__button-wrapper">
                                    <AppButton label="Cancel" buttonType="outline" size="l" onClick={() => history.push(pathnames.pageUserManagement)} />
                                </div>
                                <div className="page-employee__button-wrapper">
                                    <AppButton disabled={employeeInfo.id ? !formik.dirty : false} onClick={formik.handleSubmit} label={"Confirm"} size="l" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppPagesLayout>
    );
};

export default PageEmployee;
